/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  state = { result: "", error: "", csrf: "", type: "" },
  action
) => {
  let newState = state;
  switch (action.type) {
    case "LOGOUT-SUCCESS":
      newState = { result: "", error: "", csrf: "", type: "" };
      break;
    case "RESET-RESULT":
      newState = Object.assign(newState, { result: "0", error: "" });
      break;
    default:
      if (
        action.type !== "DATA-LOADING-BEGIN" &&
        action.type !== "DATA-LOADING-END"
      ) {
        const actionResult =
          action.payload && action.payload.result ? action.payload.result : "0";
        newState = {
          type: action.type,
          csrf: state.csrf,
          result: actionResult,
        };
        newState =
          action.payload && action.payload.csrf
            ? Object.assign(newState, { csrf: action.payload.csrf })
            : newState;
        newState =
          action.payload && action.payload.error
            ? Object.assign(newState, { error: action.payload.error })
            : newState;
        if (newState.error && newState.result === "0") {
          newState = Object.assign({}, newState, { result: "EC9999" });
        }
      } else {
        newState = state;
      }
      break;
  }
  return newState;
};
