import React from "react";
//import Highlighter from 'react-highlight-words';
import moment from "moment";
import { httpGet } from "../../../framework";
import { connect } from "react-redux";
import { Icon } from "../Icon";
import * as ewcms from "../../screen/EWCMS/styleMaster";
import {
  //Layout,
  Tag,
  Row,
  Col,
  DatePicker,
  Space,
  Table,
  Checkbox,
  //Typography,
  Tooltip,
  Popconfirm,
  Input,
  Button,
  PageHeader,
  Descriptions,
  ConfigProvider,
  Empty,
} from "antd";
import {
  entityListAction,
  entityCreateAction,
  entityUpdateAction,
  entityDeleteAction,
  navigateAction,
  setFilteredInfoAction,
  entityFilterAction,
} from "../../../redux/actions";
import { logger, label, md5 } from "../../../framework";
import EntityDataForm from "./EntityDataForm";
import ReactExport from "react-export-excel";
import OtpModal from "../../screen/OTP/OtpModal";
import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import formatAmount from "../../../utility/formatAmount";

const CheckboxGroup = Checkbox.Group;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const defaultBtnStyle = ewcms.defaultBtnStyle;
const tagColor = ewcms.tagOrange;
const polyuColor = ewcms.polyuRed;
const lightGrey = ewcms.lightGrey;

const iconSize = "20px";
//const { Title } = Typography;
//const { Content } = Layout;

const searchTypeMatch = "match";
const searchTypeRange = "range";
const searchTypeSelection = "selection";
const datatypeInt = "integer";
const datatypeDecimal = "decimal";
const datatypeBoolean = "boolean";
const datatypeDate = "date";
const datatypeDatetime = "datetime";
const datatypeCodetable = "codetable";
const separator = "|";
const pad2 = (n) => {
  return n < 10 ? "0" + n : n;
};

class EntityPaginationTable extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.sortRef = React.createRef();
    this.state = {
      searchText: "",
      searchMinVal: [],
      searchMaxVal: [],
      searchOptions: [],
      //searchedColumn: '',
      formVisible: false,
      tableName: "",
      recOffset: 0,
      recSize: process.env.REACT_APP_PAGINATION_TABLE_SIZE,
      wrappedData: null,
      showModal: this.props.otpInfo.otpLogin
        ? !this.props.otpInfo.otpLogin
        : true,
      filter_status: true,
    };
  }
  setshowModal = (showModal) => {
    this.setState({ showModal: showModal });
  };
  //Show OTP Modal directly related to current email login status.

  //at other funct, call this
  static getDerivedStateFromProps(props, state) {
    if (
      props.action === "DATA-CREATE-SUCCESS" ||
      props.action === "DATA-UPDATE-SUCCESS"
    ) {
      return { ...state, formVisible: false };
    }
    return state;
  }

  genOTPParams(filters) {
    const { currentFunction, otpInfo } = this.props;
    let otpparams = this.genParams(filters);
    if (
      currentFunction.function_action.includes("FILTER_UNPAID") &&
      this.state.filter_status === true
    ) {
      const params = this.genParams({
        status: [
          {
            type: "selection",
            options: [["PAID"]],
          },
        ],
      });
      otpparams = _.merge(params, otpparams);
    }
    // else if (
    //   currentFunction.function_action.includes("FILTER_UNPAID") &&
    //   this.state.filter_status === false
    // ) {
    //   const arr = removeItemOnce(metadata.status.search.options, "PAID");
    //   const params = this.genParams({
    //     status: [
    //       {
    //         type: "selection",
    //         options: arr,
    //       },
    //     ],
    //   });
    //   otpparams = _.merge(params, otpparams);
    // }
    if (
      currentFunction.function_action.includes("OTP_LOGIN") &&
      otpInfo.otpLogin
    ) {
      const params = this.genParams({
        payer_email: [
          {
            type: "selection",
            options: [[otpInfo.payer_email]],
          },
        ],
        created_by: [
          {
            type: "selection",
            options: [[otpInfo.created_by]],
          },
        ],
      });
      otpparams = _.merge(params, otpparams);
    }
    const sorter = this.sortRef.current;
    if (sorter && sorter.hasOwnProperty("column")) {
      otpparams.order = {
        field: sorter.field,
        dir: sorter.order,
      };
    }

    return otpparams;
  }
  genParams(filters) {
    const { metadata } = this.props;
    let params = {};
    //add sorted info
    if (filters) {
      Object.keys(filters).forEach(function (key) {
        if (!params.filter) {
          params.filter = {};
        }
        if (filters[key]) {
          if (filters[key][0].type === "match") {
            params["filter"][key] = {
              value: filters[key][0].val,
              type: filters[key][0].type,
              datatype: metadata[key].datatype,
            };
          } else if (filters[key][0].type === "selection") {
            params["filter"][key] = {
              options: filters[key][0].options,
              type: filters[key][0].type,
              datatype: metadata[key].datatype,
            };
          } else if (filters[key][0].type === "range") {
            //other range type
            params["filter"][key] = {
              from: filters[key][0].from,
              to: filters[key][0].to,
              type: filters[key][0].type,
              datatype: metadata[key].datatype,
            };
          }
        }
      });
    }
    return params;
  }

  componentDidMount() {
    const { filterRecord, entityName, csrf, otpInfo } = this.props;
    if (otpInfo.otpLogin) {
      //If otpLogin is true, fetch data without any filter options
      filterRecord(
        entityName,
        csrf,
        this.state.recOffset,
        this.state.recSize,
        this.genOTPParams()
      );
    }
  }

  componentDidUpdate(prevProps, prevStatus) {
    const { filterRecord, entityName, csrf, otpInfo } = this.props;
    //During every login/logout state change, call filter record
    if (
      (prevProps.otpInfo.otpLogin !== otpInfo.otpLogin && otpInfo.otpLogin) ||
      prevStatus.filter_status !== this.state.filter_status
    ) {
      //Call OTP here
      filterRecord(
        entityName,
        csrf,
        this.state.recOffset,
        this.state.recSize,
        this.genOTPParams()
      );
    }
  }

  getCompareResult(a, b) {
    const aVal = isNaN(a)
      ? a ?? ""
      : a === undefined || a === null
      ? ""
      : a.toString();
    const bVal = isNaN(b)
      ? b ?? ""
      : b === undefined || b === null
      ? ""
      : b.toString();
    // return number / string comparsion
    return !isNaN(a) && !isNaN(b)
      ? a - b
      : Array.isArray(a) && Array.isArray(b)
      ? a.sort().join().localeCompare(b.sort().join())
      : aVal.localeCompare(bVal);
  }

  // TODO: to extend to more data type / scenario
  genColumnSorter(dataType, key) {
    if (dataType === datatypeDate || dataType === datatypeDatetime) {
      return (a, b) => {
        return Date.parse(a[key] || 0) - Date.parse(b[key] || 0);
      };
    } else {
      return (a, b) => {
        return this.getCompareResult(a[key], b[key]);
      };
    }
  }

  genColumnSelectionSearchProps(dataIndex, dataType, colLabel, options) {
    const { codetable, metadata } = this.props;
    let { filteredInfo } = this.props;
    let { searchOptions } = this.state;
    filteredInfo = filteredInfo || {};
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Row>
            <CheckboxGroup //options={options}
              value={
                searchOptions[dataIndex] === undefined ||
                searchOptions[dataIndex] === null
                  ? null
                  : searchOptions[dataIndex][0] === undefined ||
                    searchOptions[dataIndex][0] === null
                  ? null
                  : searchOptions[dataIndex][0]
              }
              onChange={(checkedValue) => {
                searchOptions[dataIndex] = checkedValue ? [checkedValue] : [];
                this.setState({ searchOptions: searchOptions });
                const selectedKeyVal = [
                  {
                    type: searchTypeSelection,
                    options: searchOptions[dataIndex],
                  },
                ];
                setSelectedKeys(selectedKeyVal);
              }}
              style={{
                maxWidth: 280,
                maxHeight: 320,
                marginRight: 8,
                marginBottom: 8,
                display: "inline-block !important",
                overflow: "auto",
              }}
            >
              {options.map((opt) => (
                <Row
                  key={"selection_cbb_" + opt.value}
                  style={{ paddingBottom: 5 }}
                >
                  <Col span={24}>
                    <Checkbox
                      value={opt.value}
                      key={"selection_cbb_" + opt.value}
                      checked={
                        searchOptions[dataIndex] === undefined ||
                        searchOptions[dataIndex] === null
                          ? false
                          : searchOptions[dataIndex][opt.value] === undefined
                          ? false
                          : true
                      }
                    >
                      {opt.label}
                    </Checkbox>
                  </Col>
                </Row>
              ))}
            </CheckboxGroup>
          </Row>
          <Row>
            <Button
              disabled={
                searchOptions[dataIndex] === undefined ||
                searchOptions[dataIndex] === null ||
                searchOptions[dataIndex][0] === undefined ||
                searchOptions[dataIndex][0] === null
              }
              type="primary"
              className={defaultBtnStyle + " cust-button-height"}
              onClick={() =>
                this.handleSelectionSearch(selectedKeys, confirm, dataIndex)
              }
              icon={<Icon type="FilterOutlined" />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              {label("LB0002")}
            </Button>
            <Button
              className={defaultBtnStyle + " cust-button-height"}
              onClick={() =>
                this.cancelSelectionSearch(clearFilters, dataIndex)
              }
              size="small"
              style={{ width: 90 }}
            >
              {label("LB0003")}
            </Button>
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="FilterOutlined"
          style={{ color: filtered ? polyuColor : undefined }}
        />
      ),
      filteredValue: filteredInfo[dataIndex] || null,
      onFilter: (value, record) => {
        //Selection
        var recordValue =
          record[dataIndex] === undefined
            ? ""
            : record[dataIndex] === null
            ? ""
            : record[dataIndex].toString();
        var filterValue = value;

        if (filterValue.options === undefined) {
          return true;
        }
        if (dataType === "boolean") {
          recordValue =
            record[dataIndex] === 1
              ? "Y"
              : record[dataIndex] === 0
              ? "N"
              : record[dataIndex];
        }
        if (dataType === "date") {
          if (record[dataIndex] === null) {
            recordValue = "";
          } else {
            const dateString = new Date(Date.parse(record[dataIndex]));
            recordValue =
              pad2(dateString.getDate()) +
              "-" +
              pad2(dateString.getMonth() + 1) +
              "-" +
              dateString.getFullYear().toString();
          }
        }
        if (dataType === "datetime") {
          if (record[dataIndex] === null) {
            recordValue = "";
          } else {
            const dateString = new Date(Date.parse(record[dataIndex]));
            recordValue =
              pad2(dateString.getDate()) +
              "-" +
              pad2(dateString.getMonth() + 1) +
              "-" +
              dateString.getFullYear().toString() +
              " " +
              pad2(dateString.getHours()) +
              ":" +
              pad2(dateString.getMinutes()) +
              ":" +
              pad2(dateString.getSeconds());
          }
        }
        if (
          dataType === datatypeCodetable &&
          metadata[dataIndex].mode === "single" &&
          codetable[metadata[dataIndex].codetable]
        ) {
          recordValue = codetable[metadata[dataIndex].codetable].find(
            (ref) =>
              ref.key === (parseInt(record[dataIndex]) || record[dataIndex])
          ).name;
        }
        if (
          dataType === datatypeCodetable &&
          metadata[dataIndex].mode === "multiple" &&
          codetable[metadata[dataIndex].codetable]
        ) {
          recordValue = record[dataIndex].reduce(
            (acc, codetableIndex) =>
              acc +
              codetable[metadata[dataIndex].codetable].find(
                (ref) =>
                  ref.key === (parseInt(codetableIndex) || codetableIndex)
              ).name +
              separator,
            ""
          );
          const recordValueArr = recordValue.split(separator);
          let result = false;

          recordValueArr.forEach((ele) => {
            result = result || this.checkOptionsMatch(filterValue.options, ele);
          });
          return result;
        }

        return this.checkOptionsMatch(filterValue.options, recordValue);
      },
    };
  }

  genColumnRangeSearchProps(dataIndex, dataType, colLabel) {
    let { filteredInfo } = this.props;
    let { searchMinVal, searchMaxVal } = this.state;
    filteredInfo = filteredInfo || {};
    const dateFormat = "YYYY-MM-DD";

    let fromLabel = label("LR0116").replace("{%1}", label(colLabel));
    let toLabel = label("LR0216").replace("{%1}", label(colLabel));
    let isNumber = true;

    if (dataType === datatypeDate || dataType === datatypeDatetime) {
      fromLabel = label("LR0126").replace("{%1}", label(colLabel));
      toLabel = label("LR0226").replace("{%1}", label(colLabel));
      isNumber = false;
    }

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          {isNumber ? (
            <>
              <Input
                ref={(node) => {
                  this.searchInput = node;
                }}
                placeholder={fromLabel}
                value={this.state.searchMinVal[dataIndex]}
                onChange={(e) => {
                  searchMinVal[dataIndex] = e.target.value
                    ? [e.target.value]
                    : [];
                  this.setState({ searchMinVal: searchMinVal });
                  const selectedKeyVal = [
                    {
                      type: searchTypeRange,
                      from: searchMinVal[dataIndex],
                      to: searchMaxVal[dataIndex],
                    },
                  ];
                  setSelectedKeys(selectedKeyVal);
                }}
                onPressEnter={() =>
                  this.handleRangeSearch(selectedKeys, confirm, dataIndex)
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
              <Input
                ref={(node) => {
                  this.searchInput = node;
                }}
                placeholder={toLabel}
                value={this.state.searchMaxVal[dataIndex]}
                onChange={(e) => {
                  searchMaxVal[dataIndex] = e.target.value
                    ? [e.target.value]
                    : [];
                  this.setState({ searchMaxVal: searchMaxVal });
                  const selectedKeyVal = [
                    {
                      type: searchTypeRange,
                      from: searchMinVal[dataIndex],
                      to: searchMaxVal[dataIndex],
                    },
                  ];
                  setSelectedKeys(selectedKeyVal);
                }}
                onPressEnter={() =>
                  this.handleRangeSearch(selectedKeys, confirm, dataIndex)
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </>
          ) : (
            <DatePicker.RangePicker
              format={dateFormat} //className="form-control"
              //ref={node => { this.searchInput = node }}
              placeholder={["from", "to"]}
              value={
                searchMinVal[dataIndex] === undefined ||
                searchMinVal[dataIndex] === null
                  ? null
                  : [
                      moment(searchMinVal[dataIndex], dateFormat),
                      moment(searchMaxVal[dataIndex], dateFormat),
                    ]
              }
              allowClear={false}
              size="default"
              picker="date"
              onChange={(value) => {
                searchMinVal[dataIndex] = value
                  ? [moment(value[0]).format(dateFormat)]
                  : [];
                searchMaxVal[dataIndex] = value
                  ? [moment(value[1]).format(dateFormat)]
                  : [];
                this.setState({
                  searchMinVal: searchMinVal,
                  searchMaxVal: searchMaxVal,
                });
                const selectedKeyVal = [
                  {
                    type: searchTypeRange,
                    from: searchMinVal[dataIndex],
                    to: searchMaxVal[dataIndex],
                  },
                ];
                setSelectedKeys(selectedKeyVal);
              }}
              onPressEnter={() =>
                this.handleRangeSearch(selectedKeys, confirm, dataIndex)
              }
              onOk={() =>
                this.handleRangeSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 288, marginBottom: 8 }}
            />
          )}
          <Row>
            <Button
              disabled={
                (searchMinVal[dataIndex] === undefined ||
                  searchMinVal[dataIndex] === null ||
                  searchMinVal[dataIndex] === "" ||
                  searchMinVal[dataIndex].length === 0) &&
                (searchMaxVal[dataIndex] === undefined ||
                  searchMaxVal[dataIndex] === null ||
                  searchMaxVal[dataIndex] === "" ||
                  searchMaxVal[dataIndex].length === 0)
              }
              type="primary"
              className={defaultBtnStyle + " cust-button-height"}
              onClick={() =>
                this.handleRangeSearch(selectedKeys, confirm, dataIndex)
              }
              icon={<Icon type="SelectOutlined" />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              {label("LB0002")}
            </Button>
            <Button
              className={defaultBtnStyle + " cust-button-height"}
              onClick={() => this.cancelRangeSearch(clearFilters, dataIndex)}
              size="small"
              style={{ width: 90 }}
            >
              {label("LB0003")}
            </Button>
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="ColumnWidthOutlined"
          style={{ color: filtered ? polyuColor : undefined }}
        />
      ),
      filteredValue: filteredInfo[dataIndex] || null,
      onFilter: (value, record) => {
        // Range
        return record[dataIndex];
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (
          visible &&
          this.searchInput !== undefined &&
          this.searchInput !== null
        ) {
          setTimeout(() => this.searchInput.select());
        }
      },
    };
  }

  genColumnMatchSearchProps(dataIndex, dataType, colLabel) {
    const { codetable, metadata } = this.props;
    let { filteredInfo } = this.props;
    filteredInfo = filteredInfo || {};

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={label("LB0016").replace("{%1}", label(colLabel))}
            value={
              selectedKeys[0] === null || selectedKeys[0] === undefined
                ? ""
                : JSON.parse(JSON.stringify(selectedKeys[0])).val
            }
            onChange={(e) => {
              setSelectedKeys(
                e.target.value
                  ? [{ type: searchTypeMatch, val: e.target.value }]
                  : []
              );
            }}
            onPressEnter={() =>
              this.handleMatchSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            className={defaultBtnStyle + " cust-button-height"}
            onClick={() =>
              this.handleMatchSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<Icon type="SearchOutlined" />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {label("LB0002")}
          </Button>
          <Button
            className={defaultBtnStyle + " cust-button-height"}
            onClick={() => this.cancelMatchSearch(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            {label("LB0003")}
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="SearchOutlined"
          style={{ color: filtered ? polyuColor : undefined }}
        />
      ),
      filteredValue: filteredInfo[dataIndex] || null,
      onFilter: (value, record) => {
        //Match
        var recordValue =
          record[dataIndex] === null ? "" : record[dataIndex].toString();
        var filterValue = JSON.parse(JSON.stringify(value)).val;

        if (filterValue === undefined) {
          return true;
        }

        if (dataType === "boolean") {
          filterValue =
            filterValue.toLowerCase() === "y"
              ? "1"
              : filterValue.toLowerCase() === "n"
              ? "0"
              : filterValue;
        }
        if (dataType === "date") {
          if (record[dataIndex] === null) {
            recordValue = "";
          } else {
            const dateString = new Date(Date.parse(record[dataIndex]));
            recordValue =
              pad2(dateString.getDate()) +
              "-" +
              pad2(dateString.getMonth() + 1) +
              "-" +
              dateString.getFullYear().toString();
          }
        }
        if (dataType === "datetime") {
          if (record[dataIndex] === null) {
            recordValue = "";
          } else {
            const dateString = new Date(Date.parse(record[dataIndex]));
            recordValue =
              pad2(dateString.getDate()) +
              "-" +
              pad2(dateString.getMonth() + 1) +
              "-" +
              dateString.getFullYear().toString() +
              " " +
              pad2(dateString.getHours()) +
              ":" +
              pad2(dateString.getMinutes()) +
              ":" +
              pad2(dateString.getSeconds());
          }
        }
        if (
          dataType === datatypeCodetable &&
          metadata[dataIndex].mode === "single" &&
          codetable[metadata[dataIndex].codetable]
        ) {
          recordValue = codetable[metadata[dataIndex].codetable].find(
            (ref) =>
              ref.key === (parseInt(record[dataIndex]) || record[dataIndex])
          ).name;
        }
        if (
          dataType === datatypeCodetable &&
          metadata[dataIndex].mode === "multiple" &&
          codetable[metadata[dataIndex].codetable]
        ) {
          recordValue = record[dataIndex].reduce(
            (acc, codetableIndex) =>
              acc +
              codetable[metadata[dataIndex].codetable].find(
                (ref) =>
                  ref.key === (parseInt(codetableIndex) || codetableIndex)
              ).name,
            ""
          );
        }

        // Column value matched filtered value
        if (
          dataType === datatypeDecimal ||
          dataType === datatypeInt ||
          dataType === "date" ||
          dataType === "datetime"
        ) {
          return recordValue;
        } else {
          return recordValue.toLowerCase();
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (
          visible &&
          this.searchInput !== undefined &&
          this.searchInput !== null
        ) {
          setTimeout(() => this.searchInput.select());
        }
      },
      /*
      // All Fields
      render: text => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ),
      */
      /*
      // Search Fields
      render: text =>
       this.state.searchedColumn===dataIndex ? (
         <Highlighter
           highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
           searchWords={[this.state.searchText]}
           autoEscape
           textToHighlight={text ? text.toString() : ''}
         />
       ) : (
         text
       ),
       */
    };
  }

  handleDate = (item, type) => {
    const dateString = item && new Date(Date.parse(item));
    if (!dateString) {
      return "";
    }
    if (type === "date") {
      return (
        pad2(dateString.getDate()) +
        "-" +
        pad2(dateString.getMonth() + 1) +
        "-" +
        dateString.getFullYear().toString()
      );
    } else if (type === "datetime") {
      return (
        pad2(dateString.getDate()) +
        "-" +
        pad2(dateString.getMonth() + 1) +
        "-" +
        dateString.getFullYear().toString() +
        " " +
        pad2(dateString.getHours()) +
        ":" +
        pad2(dateString.getMinutes()) +
        ":" +
        pad2(dateString.getSeconds())
      );
    }
  };

  genTableDefinitions(ctrlSide) {
    let defs = [];
    const { metadata, codetable, currentFunction } = this.props;
    const detailCtrlEle = {
      key: "detail",
      title: "Detail",
      fixed: ctrlSide,
      width: 100,
      render: (data, record) => this.handleViewDetail(data, record),
    };
    const actionCtrlEle = {
      key: "action",
      title: label("LB0030"),
      fixed: ctrlSide,
      width: 100,
      render: this.genRecordActionRender(),
    };

    if (!metadata) {
      return;
    }

    if (ctrlSide === "left") {
      if (currentFunction.view_detail) {
        defs.push(detailCtrlEle);
      }
      if (
        currentFunction.function_action.includes("ALL") ||
        currentFunction.function_action.includes("UPDATE") ||
        currentFunction.function_action.includes("DELETE")
      ) {
        defs.push(actionCtrlEle);
      }
    }

    for (var key in metadata) {
      const col = metadata[key];
      let def = { key: col.key, title: label(col.label), dataIndex: col.key };
      if (col.sortable) {
        def.sorter = this.genColumnSorter(col.datatype, col.key);
        def.sortDirections = ["descend", "ascend"];
      }
      if (col.searchable) {
        if (col.search !== undefined) {
          // ***********************************
          // Options
          if (col.search.type === searchTypeSelection) {
            let options = [];
            let genSelectionProp = false;

            if (col.search.codetable !== undefined) {
              const optionsCodetable = codetable[col.search.codetable];
              optionsCodetable.forEach((codeTable, idx) => {
                options[idx] = {
                  text: codeTable.name,
                  value: codeTable.name,
                  label: codeTable.name,
                };
              });
              genSelectionProp = true;
            } else if (col.search.options !== undefined) {
              col.search.options.forEach((optVal, idx) => {
                options[idx] = { text: optVal, value: optVal, label: optVal };
              });
              genSelectionProp = true;
            }

            if (genSelectionProp) {
              def = Object.assign(def, {
                ...this.genColumnSelectionSearchProps(
                  col.key,
                  col.datatype,
                  col.label,
                  options.sort((a, b) => {
                    return this.getCompareResult(a.text, b.text);
                  })
                ),
              });
            }
            // ***********************************
            // Range Search
          } else if (
            col.search.type === searchTypeRange &&
            (col.datatype === datatypeDate ||
              col.datatype === datatypeDatetime ||
              col.datatype === datatypeInt ||
              col.datatype === datatypeDecimal)
          ) {
            def = Object.assign(def, {
              ...this.genColumnRangeSearchProps(
                col.key,
                col.datatype,
                col.label
              ),
            });
          }
        } else {
          // ***********************************
          // Match Search
          def = Object.assign(def, {
            ...this.genColumnMatchSearchProps(col.key, col.datatype, col.label),
          });
        }
      }
      if (
        col.datatype === datatypeCodetable &&
        col.mode === "multiple" &&
        codetable[col.codetable]
      ) {
        def.render = (items) => (
          <span style={{ width: 200 }}>
            {items &&
              items.map((item) => {
                const lookup = codetable[col.codetable].find(
                  (ref) => ref.key === (parseInt(item) || item)
                );
                if (lookup === undefined) {
                  return null;
                }
                return (
                  <Tag color={tagColor} key={item}>
                    {lookup.name}
                  </Tag>
                );
              })}
          </span>
        );
      }
      if (
        col.datatype === datatypeCodetable &&
        col.mode === "single" &&
        codetable[col.codetable]
      ) {
        def.render = (item) => {
          const lookup = codetable[col.codetable].find(
            (ref) => ref.key === (parseInt(item) || item)
          );
          if (lookup === undefined) {
            return null;
          }
          return (
            item && (
              <span style={{ width: 200 }}>
                <Tag color={tagColor} key={item}>
                  {lookup.name}
                </Tag>
              </span>
            )
          );
        };
      }
      if (col.datatype === "password") {
        def.render = (item) => {
          return item && <span>***************</span>;
        };
      }

      if (col.datatype === datatypeDate || col.datatype === datatypeDatetime) {
        def.render = (item) => {
          return this.handleDate(item, col.datatype);
        };
      }

      if (col.datatype === datatypeBoolean) {
        def.render = (item) => {
          //return item ? label("LBTrue") : label("LBFalse");
          return item ? (
            <Icon type="CheckCircleTwoTone" twoToneColor="#52c41a" />
          ) : (
            <Icon type="CloseCircleTwoTone" twoToneColor="#ff4d4f" />
          );
        };
        def = Object.assign(def, { align: "center" });
      }

      if (col.datatype === datatypeDecimal) {
        def.render = (item) => {
          return formatAmount(Number(item), false);
        };
      }

      // Right Align
      if (
        col.datatype === datatypeDate ||
        col.datatype === datatypeDatetime ||
        col.datatype === datatypeInt ||
        col.datatype === datatypeDecimal
      ) {
        def = Object.assign(def, { align: "right" });
      }
      /*
            if (col.datatype==='string') {
              def.render = (item) => {
                return (<div className='breakline' style={{maxWidth:230}}>{item}</div>);
              }
            }
      */

      if (col.datatype === "textarea" || col.datatype === "json") {
        def.render = (item) => {
          return (
            <div className="breakline" style={{ maxWidth: 300 }}>
              {item}
            </div>
          );
        };
      }
      if (!col.hide_column) {
        defs.push(def);
      }
    }
    if (ctrlSide === "right") {
      if (currentFunction.view_detail) {
        defs.push(detailCtrlEle);
      }
      if (
        currentFunction.function_action.includes("ALL") ||
        currentFunction.function_action.includes("UPDATE") ||
        currentFunction.function_action.includes("DELETE")
      ) {
        defs.push(actionCtrlEle);
      }
    }
    return defs;
  }

  handleViewDetail = (data, record) => {
    const { navigate, permissions, language, currentFunction } = this.props;
    let toFunction = permissions.find(
      (func) => func.component === currentFunction.view_detail
    );
    //Decode search param
    let path_param = [];
    let dao_field = JSON.parse(toFunction.function_search_dao_field);
    path_param.push({
      urlParamName: dao_field.params[0].paramName,
      urlParamVal: data.order_id,
    });

    toFunction = { ...toFunction, path_param: path_param };
    /* Details Icon */
    return (
      <Tooltip title={label("LC0004")}>
        <Icon
          type="EllipsisOutlined"
          style={{ color: polyuColor, fontSize: iconSize }}
          onClick={() => {
            navigate(toFunction, language);
          }}
        ></Icon>
      </Tooltip>
    );
  };

  genRecordActionRender() {
    const { currentFunction } = this.props;
    return (text, record) => {
      return (
        <span>
          <Space size="small">
            {currentFunction.function_action.includes("ALL") ||
            currentFunction.function_action.includes("UPDATE") ? (
              <Tooltip title={label("LB0004")}>
                <Icon
                  type="EditOutlined"
                  style={{ color: polyuColor, fontSize: iconSize }}
                  onClick={() => {
                    let values = {};
                    const { metadata } = this.props;
                    for (var colname in record) {
                      // TODO: to extend to more data type / scenario
                      if (
                        colname === "key" ||
                        colname === "version" ||
                        colname === "created_by" ||
                        colname === "created_time" ||
                        colname === "modified_by" ||
                        colname === "modified_time" ||
                        colname === "modified_by"
                      ) {
                        values[colname] = record[colname];
                      } else if (!metadata[colname]) {
                        continue;
                      } else if (!metadata[colname].editable) {
                        continue;
                      } else if (
                        metadata[colname].datatype === datatypeDate ||
                        metadata[colname].datatype === datatypeDatetime
                      ) {
                        values[colname] = moment(record[colname]);
                      } else if (metadata[colname].datatype === "password") {
                        values[colname] = "{md5}" + record[colname];
                      } else if (
                        metadata[colname].datatype === datatypeCodetable &&
                        metadata[colname].mode === "single"
                      ) {
                        values[colname] = record[colname]
                          ? record[colname].toString()
                          : "";
                      } else {
                        values[colname] = record[colname];
                      }
                    }

                    if (this.formRef.current) {
                      this.formRef.current.setFieldsValue(values);
                    }
                    this.setState({
                      formVisible: true,
                      formMode: "update",
                      initialValues: values,
                    });
                  }}
                >
                  {/*Edit Icon*/}
                </Icon>
              </Tooltip>
            ) : null}

            {currentFunction.function_action.includes("ALL") ||
            (currentFunction.function_action.includes("DELETE") &&
              currentFunction.function_action.includes("UPDATE"))
              ? " "
              : null}
            {currentFunction.function_action.includes("ALL") ||
            currentFunction.function_action.includes("DELETE") ? (
              <Popconfirm
                title={label("LB0007") + " '" + record.name + "'"}
                okText={label("LB0008")}
                cancelText={label("LB0006")}
                okButtonProps={{ className: defaultBtnStyle }}
                cancelButtonProps={{ className: defaultBtnStyle }}
                onConfirm={() => this.handleDelete(record.key)}
                icon={
                  <Icon type="QuestionCircleOutlined" className="polyu-color" />
                }
              >
                <Tooltip title={label("LB0005")}>
                  {/*Delete Icon*/}
                  <Icon
                    type="DeleteOutlined"
                    style={{ color: polyuColor, fontSize: iconSize }}
                  ></Icon>
                </Tooltip>
              </Popconfirm>
            ) : null}
          </Space>
        </span>
      );
    };
  }

  handleCreate = () => {
    this.formRef.current.validateFields().then((fieldsValue) => {
      const { createRecord, metadata, entityName, csrf } = this.props;
      const values = Object.assign({}, fieldsValue);
      for (var colname in values) {
        if (!metadata[colname]) {
          continue;
        } else if (metadata[colname].datatype === datatypeDate) {
          values[colname] = fieldsValue[colname].format("YYYY-MM-DD");
        } else if (metadata[colname].datatype === "password") {
          values[colname] = md5.encrypt(fieldsValue[colname]);
        }
      }
      createRecord(entityName, values, csrf);
    });
  };

  handleUpdate = () => {
    this.formRef.current.validateFields().then((fieldsValue) => {
      const { updateRecord, metadata, entityName, csrf } = this.props;
      const values = Object.assign({}, fieldsValue);
      for (var colname in values) {
        if (!metadata[colname]) {
          continue;
        } else if (metadata[colname].datatype === datatypeDate) {
          values[colname] = fieldsValue[colname].format("YYYY-MM-DD");
        } else if (metadata[colname].datatype === "password") {
          if (fieldsValue[colname].startsWith("{md5}")) {
            values[colname] = fieldsValue[colname].substring(5);
          } else {
            values[colname] = md5.encrypt(fieldsValue[colname]);
          }
        }
      }
      updateRecord(entityName, values.key, values, csrf);
    });
  };

  cancelCreateUpdate = () => {
    this.setState({ formVisible: false });
  };

  handleDelete = (recordKey) => {
    const { deleteRecord, entityName, csrf } = this.props;
    deleteRecord(entityName, recordKey, csrf);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { setFilteredInfo, filterRecord } = this.props;
    this.sortRef.current = sorter;
    setFilteredInfo(filters);
    const offset =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    const params = this.genOTPParams(filters);
    //add filter keyword
    logger.getLogger("EntityPaginationTable.filters").debug(filters);
    logger.getLogger("EntityPaginationTable.sorter").debug(sorter);
    logger.getLogger("EntityPaginationTable.params").debug(params);
    logger.getLogger("EntityPaginationTable.offset").debug(offset);
    logger.getLogger("EntityPaginationTable.limit").debug(limit);
    logger.debug(this.props.recSize);
    this.setState({ recSize: limit });
    this.setState(
      { recOffset: offset },
      filterRecord(
        this.props.entityName,
        this.props.csrf,
        offset,
        limit,
        params
      )
    );
  };

  handleRemoveFilter = (key) => {
    const { setFilteredInfo, filterRecord } = this.props;
    const cloneObj = this.props.filteredInfo;
    const cloneMin = this.state.searchMinVal;
    const cloneMax = this.state.searchMaxVal;
    const cloneOptions = this.state.searchOptions;

    if (key !== undefined && key !== null) {
      cloneObj[key] = null;
      cloneMin[key] = null;
      cloneMax[key] = null;
      cloneOptions[key] = null;

      setFilteredInfo(cloneObj);

      this.setState({
        searchMaxVal: cloneMax,
        searchMinVal: cloneMin,
        searchOptions: cloneOptions,
      });
    } else {
      setFilteredInfo(null);
      this.setState({
        searchMinVal: [],
        searchMaxVal: [],
        searchOptions: [],
      });
    }
    const params = this.genOTPParams(cloneObj);
    filterRecord(
      this.props.entityName,
      this.props.csrf,
      this.state.recOffset,
      this.state.recSize,
      params
    );
  };

  handleResetFilter = () => {
    if (this.props.functionLabel !== this.state.tableName) {
      this.setState({ tableName: this.props.functionLabel });
      this.handleRemoveFilter(null);
    }
  };

  handleMatchSearch = (selectedKeys, confirm, dataIndex) => {
    // logger.getLogger('handleMatchSearch.selectedKeys').debug(selectedKeys);
    confirm();
  };

  handleRangeSearch = (selectedKeys, confirm, dataIndex) => {
    // logger.getLogger('handleRangeSearch.selectedKeys').debug(selectedKeys);
    if (selectedKeys[0] !== undefined) {
      confirm();
      const cloneMin = this.state.searchMinVal;
      const cloneMax = this.state.searchMaxVal;
      cloneMin[dataIndex] = selectedKeys[0].from;
      cloneMax[dataIndex] = selectedKeys[0].to;
      this.setState({ searchMaxVal: cloneMax, searchMinVal: cloneMin });
    }
  };

  handleSelectionSearch = (selectedKeys, confirm, dataIndex) => {
    // logger.getLogger('handleSelectionSearch.selectedKeys').debug(selectedKeys);
    if (selectedKeys[0] !== undefined) {
      confirm();
      const cloneOptions = this.state.searchOptions;
      const options = selectedKeys[0].options;
      cloneOptions[dataIndex] = options;
      options.forEach((optStr) => {
        optStr.forEach((opt) => {
          cloneOptions[dataIndex][opt] = true;
        });
      });

      this.setState({ searchOptions: cloneOptions });
    }
  };

  cancelMatchSearch = (clearFilters, dataIndex) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  cancelRangeSearch = (clearFilters, dataIndex) => {
    clearFilters();
    const cloneMin = this.state.searchMinVal;
    const cloneMax = this.state.searchMaxVal;
    cloneMin[dataIndex] = null;
    cloneMax[dataIndex] = null;
    this.setState({ searchMaxVal: cloneMax, searchMinVal: cloneMin });
  };

  cancelSelectionSearch = (clearFilters, dataIndex) => {
    clearFilters();
    const cloneOptions = this.state.searchOptions;
    cloneOptions[dataIndex] = null;

    this.setState({ searchOptions: cloneOptions });
  };

  handleBack = () => {
    const { permissions, currentFunction, navigate } = this.props;
    let toFunctions = permissions.filter(
      (item) => item.key === currentFunction.parent_uid
    );
    navigate(toFunctions.length === 0 ? permissions : toFunctions[0].subfunc);
  };

  checkOptionsMatch(arr, val) {
    if (arr !== undefined) {
      return arr.some(function (arrVal) {
        return arrVal.includes(val);
        //return val===arrVal;
      });
    } else {
      return true;
    }
  }

  getTagVal = (jsonObj, searchType) => {
    if (searchType === searchTypeMatch) {
      return jsonObj.val;
    } else if (searchType === searchTypeRange) {
      const fromStr =
        jsonObj.from === undefined ||
        jsonObj.from === null ||
        jsonObj.from === ""
          ? "min."
          : jsonObj.from;
      const toStr =
        jsonObj.to === undefined || jsonObj.to === null || jsonObj.to === ""
          ? "max."
          : jsonObj.to;
      return fromStr + " to " + toStr;
    } else if (searchType === searchTypeSelection) {
      return jsonObj.options.join(); // Array.join() ==> Array to String
    }
  };

  getColumnLabelByKey = (metadata, input) => {
    for (var row in metadata) {
      const col = metadata[row];
      let val = { title: label(col.label), key: col.key };

      if (input === val.key) {
        return val.title;
      }
    }
  };

  genExtra = () => {
    const {
      functionLabel,
      currentFunction,
      entityName,
      metadata,
      filteredInfo,
      otpInfo,
      setOtpLogout,
    } = this.props;
    const isExportExcel = this.state.isExportExcel;
    const excelExportData = this.state.wrappedData;
    const extraArray = [];
    // Search Result Tag
    if (filteredInfo !== undefined && filteredInfo !== null) {
      for (var obj in filteredInfo) {
        const key = obj;
        const filteredJsons = JSON.parse(JSON.stringify(filteredInfo[obj]));

        for (var fJson in filteredJsons) {
          const filteredType = filteredJsons[fJson].type;
          const filteredVal = this.getTagVal(
            filteredJsons[fJson],
            filteredType
          ); //filteredJsons[fJson].val;
          if (filteredInfo[obj] !== null) {
            extraArray.push(
              <Tag
                style={{ minWidth: "50px", borderRadius: "8%" }}
                // eslint-disable-next-line no-loop-func
                onClose={() => {
                  this.handleRemoveFilter(key);
                }}
                closable
                color="volcano"
                key={key}
              >
                {
                  /* filteredType */
                  this.getColumnLabelByKey(metadata, key) + ": " + filteredVal
                }
              </Tag>
            );
          }
        }
      }
    }

    if (
      currentFunction.function_action.includes("ALL") ||
      currentFunction.function_action.includes("CREATE")
    ) {
      extraArray.push(
        //Create Button
        <Button
          key="EDT_CREATE_BTN"
          type="primary"
          className={defaultBtnStyle}
          onClick={() => {
            let values = {};
            const { metadata } = this.props;
            for (var colname in metadata) {
              // TODO: to extend to more data type / scenario
              if (!metadata[colname]) {
                continue;
              } else if (!metadata[colname].editable) {
                continue;
              } else if (metadata[colname].datatype === datatypeDate) {
                values[colname] = moment();
              } else if (
                metadata[colname].datatype === datatypeCodetable &&
                metadata[colname].mode === "multiple"
              ) {
                values[colname] = [];
              } else if (metadata[colname].datatype === datatypeBoolean) {
                values[colname] = false;
              } else {
                values[colname] = "";
              }
            }

            if (this.formRef.current) {
              this.formRef.current.setFieldsValue(values);
            }
            this.setState({
              formVisible: true,
              formMode: "create",
              initialValues: values,
            });
          }}
        >
          <Icon type="PlusOutlined" />
          {label("LB0009") + " " + label(functionLabel)}
        </Button>
      );
    }

    if (
      currentFunction.function_action.includes("ALL") ||
      currentFunction.function_action.includes("EXPORT_EXCEL")
    ) {
      const excelColumn = [];
      for (var key in metadata) {
        const col = metadata[key];
        if (!col.hide_column) {
          excelColumn.push(
            <ExcelColumn
              key={"EDT_EXPORT_EXCEL_COLUMN_" + col.key}
              label={label(col.label)}
              value={col.key}
            />
          );
        }
      }
      extraArray.push(
        <Button
          key="EDT_EXPORT_EXCEL_BTN"
          type="primary"
          className={defaultBtnStyle + " cust-button-height"}
          onClick={this.handleExcelExportSearch}
        >
          Export To Excel
        </Button>
      );

      if (isExportExcel) {
        extraArray.push(
          <ExcelFile key="EDT_EXPORT_EXCEL_FILE" hideElement={true}>
            <ExcelSheet
              key="EDT_EXPORT_EXCEL_SHEET"
              data={excelExportData}
              name={entityName}
            >
              {excelColumn}
            </ExcelSheet>
          </ExcelFile>
        );
        this.setState({ wrappedData: null, isExportExcel: false });
      }
    }

    if (currentFunction.function_action.includes("OTP_LOGIN")) {
      //Add button for enquiry login / logout
      extraArray.push(
        <Button
          key="1"
          className={defaultBtnStyle + " cust-button-height"}
          onClick={() => {
            //if is login --> set state to logout
            if (otpInfo.otpLogin) {
              setOtpLogout();
            }
            //if is logout --> set show modal to true
            else {
              this.setshowModal(true);
            }
          }}
        >
          {otpInfo.otpLogin ? "Logout" : "Login"}
        </Button>
      );
    }

    if (currentFunction.function_action.includes("FILTER_UNPAID")) {
      extraArray.push(
        <Button
          key="2"
          className={defaultBtnStyle + " cust-button-height"}
          onClick={() => {
            //if is login --> set state to logout
            this.setState({ filter_status: !this.state.filter_status });
          }}
        >
          {this.state.filter_status
            ? "Show Unsuccessful Payments"
            : "Hide Unsuccessful Payments"}
        </Button>
      );
    }

    return extraArray;
  };

  otpLoginInfo = () => {
    const { currentFunction, otpInfo } = this.props;
    return (
      currentFunction.function_action.includes("OTP_LOGIN") &&
      otpInfo.otpLogin && (
        <Content>
          <Descriptions size="small" column={2}>
            <Descriptions.Item label="Email">
              {otpInfo.payer_email}
            </Descriptions.Item>
          </Descriptions>
        </Content>
      )
    );
  };

  getDecodesValueByTypeKeyCode(decodes, type, key, code) {
    for (var decode in decodes) {
      if (
        decodes[decode].type === type &&
        decodes[decode].key_val === key &&
        decodes[decode].code === code
      ) {
        return decodes[decode].value;
      }
    }
  }

  validateInt(value, defaultInt) {
    try {
      value = Number(value);
      if (Number.isInteger(value)) {
        return value;
      }
    } catch (err) {
      logger.debug(err);
    }
    return defaultInt;
  }

  async getExcelData(entityName, params) {
    const queryParams = new URLSearchParams();
    if (params) {
      queryParams.append("params", JSON.stringify(params));
    }
    const { codetable } = this.props;
    var limit = await this.getDecodesValueByTypeKeyCode(
      codetable.decodesdao,
      "portal_config",
      "export_excel",
      "record_size"
    );
    limit = await this.validateInt(limit, 2000);
    logger.debug("limit: ", limit);
    queryParams.append("limit", JSON.stringify(limit));
    const response = await httpGet(
      `/api/protected/data/export/${entityName}?${queryParams.toString()}`
    );
    return response;
  }

  handleExcelExportSearch = async () => {
    const { metadata, codetable } = this.props;
    const cloneObj = this.props.filteredInfo;
    //logger.debug("cloneObj", cloneObj); // the filter column stored here
    const params = this.genOTPParams(cloneObj);
    var response = await this.getExcelData(this.props.entityName, params);
    var exportData = response?.value?.dataset;
    const wrappedData = exportData;
    if (!this.props.otpInfo.otpLogin) return;
    for (var key in metadata) {
      const col = metadata[key];
      console.log("Col:", col);
      if (col.hide_column) continue;
      for (var i in wrappedData) {
        if (col.datatype === datatypeCodetable) {
          if (col.mode === "single" && wrappedData[i][col.key]) {
            const field = wrappedData[i][col.key];
            const lookup = codetable[col.codetable].find(
              (ref) => ref.key === (parseInt(field) || field)
            );
            if (lookup !== undefined) {
              wrappedData[i][col.key] = lookup.name;
            }
          } else if (col.mode === "multiple") {
            if (wrappedData[i][col.key] && wrappedData[i][col.key].length > 0) {
              const codedData = wrappedData[i][col.key].map((x) => {
                const lookup = codetable[col.codetable].find(
                  (ref) => ref.key === (parseInt(x) || x)
                );
                if (lookup === undefined) {
                  return "";
                }
                return lookup.name;
              });
              wrappedData[i][col.key] = codedData.toString();
            } else {
              wrappedData[i][col.key] = "";
            }
          }
        }
        if (col.datatype === datatypeBoolean) {
          wrappedData[i][col.key] = wrappedData[i][col.key]
            ? label("LBTrue")
            : label("LBFalse");
        }

        if (col.datatype === datatypeDecimal) {
          wrappedData[i][col.key] = formatAmount(
            Number(wrappedData[i][col.key]),
            false
          );
        }
        if (
          col.datatype === datatypeDate ||
          col.datatype === datatypeDatetime
        ) {
          wrappedData[i][col.key] = this.handleDate(
            wrappedData[i][col.key],
            col.datatype
          );
        }
      }
    }
    return this.setState({ wrappedData: wrappedData, isExportExcel: true });
  };

  render() {
    const {
      functionLabel,
      functionIcon,
      data,
      /*currentFunction, entityName, metadata*/ recSize,
      setOtpInfo,
    } = this.props;

    //logger.getLogger('currentFunction').debug(currentFunction);
    const title = (
      <h4
        onClick={this.handleBack}
        style={{ alignItems: "center", display: "flex" }}
        level={4}
        className="selectCurosr"
        key="phh4"
      >
        <Icon type={functionIcon} />
        &nbsp;<strong>{label(functionLabel)}</strong>
      </h4>
    );

    return (
      <div key={"con_" + functionLabel}>
        <EntityDataForm
          key={functionLabel}
          formRef={this.formRef}
          initialValues={this.state.initialValues}
          mode={this.state.formMode}
          visible={this.state.formVisible}
          onCreate={this.handleCreate}
          onUpdate={this.handleUpdate}
          onCancel={this.cancelCreateUpdate}
        />

        {
          <PageHeader
            key={"ph_" + functionLabel}
            onBack={this.handleBack}
            style={{ alignItems: "center" }}
            backIcon={
              <Icon
                type="ArrowLeftOutlined"
                style={{ fontSize: "25px", color: lightGrey }}
              />
            }
            title={title}
            extra={this.genExtra()}
          >
            {this.otpLoginInfo()}
          </PageHeader>
        }
        {this.props.otpInfo.otpLogin && (
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                description={label("LB0082")}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          >
            <Table
              columns={this.genTableDefinitions("left")}
              dataSource={data}
              pagination={{ total: recSize }}
              scroll={{ x: "max-content" }}
              key={"tbl_" + functionLabel}
              onChange={this.handleTableChange}
            />
          </ConfigProvider>
        )}
        {/*<BackTop className='topStyle'>Top</BackTop>*/}
        <OtpModal
          requiredName={false}
          requiredEmail={true}
          title={"Order Enquiry Verification"}
          showModal={this.state.showModal}
          setshowModal={this.setshowModal}
          setOtpInfo={setOtpInfo}
          closable={true}
          checkEmail={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let entityName = state.dashboard.currentFunction.component_param;
  // Please refer server side: AbstractOracleDao::getFilterResult
  let res = state.data.data;
  let dataset = res != null ? res.dataset : null;
  let recSize = res != null ? res.count : 0;
  return {
    entityName: entityName,
    permissions: state.session.permissions,
    currentFunction: state.dashboard.currentFunction,
    filteredInfo: state.dashboard.filteredInfo,
    functionIcon: state.dashboard.currentFunction.icon,
    functionLabel: state.dashboard.currentFunction.label,
    metadata: state.data.metadata[entityName],
    lang: state.session.lang,
    codetable: state.codetable,
    data: dataset,
    recSize: recSize,
    action: state.action.type,
    result: state.action.result,
    csrf: state.action.csrf,
    otpInfo: state.session.otpInfo,
    language: state.session.lang,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFilteredInfo: (filters) => {
    dispatch(setFilteredInfoAction(filters));
  },
  navigate: (toFunction, lang) => {
    dispatch(navigateAction(toFunction, lang));
  },
  listRecord: (entityName, csrf) => {
    dispatch(entityListAction(entityName, csrf));
  },
  filterRecord: (entityName, csrf, offset, limit, params, filterType) => {
    dispatch(
      entityFilterAction(entityName, csrf, offset, limit, params, filterType)
    );
  },
  createRecord: (entityName, data, csrf) => {
    dispatch(entityCreateAction(entityName, data, csrf));
  },
  updateRecord: (entityName, key, data, csrf) => {
    dispatch(entityUpdateAction(entityName, key, data, csrf));
  },
  deleteRecord: (entityName, key, csrf) => {
    dispatch(entityDeleteAction(entityName, key, csrf));
  },
  setOtpInfo: (otpInfo) => {
    dispatch({ type: "OTP_LOGIN", payload: { otpInfo: otpInfo } });
  },
  setOtpLogout: () => {
    dispatch({ type: "OTP_LOGOUT" });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityPaginationTable);
