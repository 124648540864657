import React from "react";
//import { logger } from '../../../framework';

import {
  QuestionOutlined,
  DashboardOutlined,
  SmileOutlined,
  FormOutlined,
  TabletOutlined,
  ProfileOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  UserOutlined,
  HighlightOutlined,
  TableOutlined,
  CheckOutlined,
  EditOutlined,
  DatabaseOutlined,
  DotChartOutlined,
  ScheduleOutlined,
  MediumOutlined,
  LineChartOutlined,
  InteractionOutlined,
  ReconciliationOutlined,
  MonitorOutlined,
  SnippetsOutlined,
  TeamOutlined,
  AppstoreOutlined,
  GlobalOutlined,
  LayoutOutlined,
  PieChartOutlined,
  HomeOutlined,
  CaretDownOutlined,
  MenuOutlined,
  LeftOutlined,
  LogoutOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  ToTopOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  StopOutlined,
  SettingOutlined,
  CloseOutlined,
  ColumnWidthOutlined,
  FilterOutlined,
  SelectOutlined,
  SettingFilled,
  DollarOutlined,
  BugOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  WarningTwoTone,
  MinusCircleTwoTone,
  DeleteTwoTone,
  MinusCircleOutlined,
  PlusCircleOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  UnorderedListOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";

class Icon extends React.Component {
  constructor(props) {
    super(props);
    this.type = props.type;
  }

  render() {
    const { type } = this.props;

    const Icons = {
      QuestionOutlined: <QuestionOutlined {...this.props} />,
      DashboardOutlined: <DashboardOutlined {...this.props} />,
      SmileOutlined: <SmileOutlined {...this.props} />,
      FormOutlined: <FormOutlined {...this.props} />,
      TabletOutlined: <TabletOutlined {...this.props} />,
      ProfileOutlined: <ProfileOutlined {...this.props} />,
      CheckCircleOutlined: <CheckCircleOutlined {...this.props} />,
      WarningOutlined: <WarningOutlined {...this.props} />,
      UserOutlined: <UserOutlined {...this.props} />,
      HighlightOutlined: <HighlightOutlined {...this.props} />,
      TableOutlined: <TableOutlined {...this.props} />,
      CheckOutlined: <CheckOutlined {...this.props} />,
      EditOutlined: <EditOutlined {...this.props} />,
      DatabaseOutlined: <DatabaseOutlined {...this.props} />,
      DotChartOutlined: <DotChartOutlined {...this.props} />,
      ScheduleOutlined: <ScheduleOutlined {...this.props} />,
      MediumOutlined: <MediumOutlined {...this.props} />,
      LineChartOutlined: <LineChartOutlined {...this.props} />,
      InteractionOutlined: <InteractionOutlined {...this.props} />,
      ReconciliationOutlined: <ReconciliationOutlined {...this.props} />,
      MonitorOutlined: <MonitorOutlined {...this.props} />,
      SnippetsOutlined: <SnippetsOutlined {...this.props} />,
      TeamOutlined: <TeamOutlined {...this.props} />,
      AppstoreOutlined: <AppstoreOutlined {...this.props} />,
      GlobalOutlined: <GlobalOutlined {...this.props} />,
      LayoutOutlined: <LayoutOutlined {...this.props} />,
      PieChartOutlined: <PieChartOutlined {...this.props} />,
      HomeOutlined: <HomeOutlined {...this.props} />,
      MenuOutlined: <MenuOutlined {...this.props} />,
      LeftOutlined: <LeftOutlined {...this.props} />,
      CaretDownOutlined: <CaretDownOutlined {...this.props} />,
      LogoutOutlined: <LogoutOutlined {...this.props} />,
      ShoppingOutlined: <ShoppingOutlined {...this.props} />,
      ShoppingCartOutlined: <ShoppingCartOutlined {...this.props} />,
      ToTopOutlined: <ToTopOutlined {...this.props} />,
      LeftCircleOutlined: <LeftCircleOutlined {...this.props} />,
      RightCircleOutlined: <RightCircleOutlined {...this.props} />,
      DoubleLeftOutlined: <DoubleLeftOutlined {...this.props} />,
      DoubleRightOutlined: <DoubleRightOutlined {...this.props} />,
      ArrowLeftOutlined: <ArrowLeftOutlined {...this.props} />,
      DeleteOutlined: <DeleteOutlined {...this.props} />,
      ArrowRightOutlined: <ArrowRightOutlined {...this.props} />,
      SearchOutlined: <SearchOutlined {...this.props} />,
      PlusOutlined: <PlusOutlined {...this.props} />,
      EllipsisOutlined: <EllipsisOutlined {...this.props} />,
      LoadingOutlined: <LoadingOutlined {...this.props} />,
      QuestionCircleOutlined: <QuestionCircleOutlined {...this.props} />,
      StopOutlined: <StopOutlined {...this.props} />,
      SettingOutlined: <SettingOutlined {...this.props} />,
      SettingFilled: <SettingFilled {...this.props} />,
      DollarOutlined: <DollarOutlined {...this.props} />,
      BugOutlined: <BugOutlined {...this.props} />,
      CheckCircleTwoTone: <CheckCircleTwoTone {...this.props} />,
      CloseCircleTwoTone: <CloseCircleTwoTone {...this.props} />,
      WarningTwoTone: <WarningTwoTone {...this.props} />,
      MinusCircleTwoTone: <MinusCircleTwoTone {...this.props} />,
      MinusCircleOutlined: <MinusCircleOutlined {...this.props} />,
      PlusCircleOutlined: <PlusCircleOutlined {...this.props} />,
      ColumnWidthOutlined: <ColumnWidthOutlined {...this.props} />,
      FilterOutlined: <FilterOutlined {...this.props} />,
      SelectOutlined: <SelectOutlined {...this.props} />,
      CloseOutlined: <CloseOutlined {...this.props} />,
      MinusSquareOutlined: <MinusSquareOutlined {...this.props} />,
      PlusSquareOutlined: <PlusSquareOutlined {...this.props} />,
      UnorderedListOutlined: <UnorderedListOutlined {...this.props} />,
      CreditCardOutlined: <CreditCardOutlined {...this.props} />,
      DeleteTwoTone: <DeleteTwoTone {...this.props} />,
    };

    const getIcon = (type) => {
      // Default Icon when not found
      let icon = <QuestionOutlined />;

      let typeNew = type.toString();

      if (typeNew.indexOf("-") >= 0) {
        typeNew = typeNew.replace("-", "");
      }

      // Default is Outlined when no theme was appended (ex: 'smile')
      if (!typeNew.match(/.+(Outlined|Filled|TwoTone)$/i)) {
        typeNew += "Outlined";
      }

      // If found by key then return value which is component
      const found = Object.entries(Icons).find(
        ([k]) => k.toLowerCase() === typeNew.toLowerCase()
      );
      if (found) {
        [, icon] = found;
      }

      //logger.getLogger('icon component').debug(icon);
      return icon;
    };

    return getIcon(type);
  }
}
export default Icon;
