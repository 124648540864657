import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../shared/Icon";
import { Header, Footer, Banner } from "../EWCMS/Layout";

class PageNotFound extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Banner image="/assets/img/temp/small-banner-bg.png" name="404" />
        <main className="ignore-page-content" tabIndex="-1">
          <div>
            <div className="border-cards component-margin-top--t2 component-margin-bottom--t2">
              <div className="container">
                <div
                  className="row border-cards__row"
                  style={{ justifyContent: "center" }}
                >
                  <div className="col-24 col-sm-12 col-lg-18">
                    <Link to={`/`} className="border-card-blk color-set--red">
                      <p className="border-card-blk__title js-dot cust-vertical-center">
                        <Icon
                          type="StopOutlined"
                          style={{ fontSize: "180px" }}
                        />
                        &nbsp; PAGE NOT FOUND
                      </p>
                      <p className="border-card-blk__text">
                        {"<< Return To Home Page >>"}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}

export default PageNotFound;
