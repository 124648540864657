import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { authenticateAction } from "../redux/actions";

class ProtectedRoute extends React.Component {
  componentDidMount() {
    const { doAuthenticate } = this.props;
    doAuthenticate();
  }

  render() {
    const { isChecking, isAuthenticated, isAuthorized, doAuthFail } =
      this.props;

    const { component: Component, ...rest } = this.props;
    if (!isChecking && !isAuthenticated && !isAuthorized) {
      doAuthFail();
    }
    return isChecking && !isAuthenticated ? (
      ""
    ) : (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isChecking:
      state.action.type !== "AUTHENTICATE-SUCCESS" &&
      state.action.type !== "AUTHENTICATE-ERROR",
    isAuthenticated:
      state.session.email !== "" && state.session.permissions.length > 0,
    isAuthorized: state.session.permissions.length > 0,
  };
};

const mapDispatchToProps = (dispatch) => ({
  doAuthenticate: () => {
    dispatch(authenticateAction());
  },
  doAuthFail: () => {
    dispatch({
      type: "AUTHENTICATE-ERROR",
      payload: { result: "EC0011", error: "user not authorized to login" },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
