import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "../../shared/Icon";
import { Row, Divider, Breadcrumb, Button } from "antd";
import {
  label,
  //logger
} from "../../../framework";
import { navigateAction } from "../../../redux/actions";
import { EntityPaginationTable } from "../../shared/EntityDataTable";
import Store from "../Store/Store";
import OrderSummary from "../../screen/Store/OrderSummary";
import ApplicationMonitor from "../../shared/ApplicationMonitor";
import ShoppingCart from "../Store/ShoppingCart";
import * as ewcms from "../EWCMS/styleMaster";

const homeKey = "home";
const eventKey = "event";

const defaultBtnStyle = ewcms.defaultBtnStyle;

class MainContent extends React.Component {
  constructor(props) {
    super(props);
    this.currWidthOption = props.currWidthOption;
    this.currBannerOption = props.currBannerOption;
    this.bannerLabel = props.bannerLabel;
    this.state = {
      spinIcon: [],
    };
  }

  components = {
    EntityPaginationTable: EntityPaginationTable,
    Store: Store,
    ShoppingCart: ShoppingCart,
    OrderSummary: OrderSummary,
    ApplicationMonitor: ApplicationMonitor,
  };

  handleNavigate = (e) => {
    const { navigate, permissions, language } = this.props;
    let toFunction = permissions; // all
    if (e.key !== homeKey) {
      toFunction =
        e.item.subfunc && e.item.subfunc.length > 0 ? e.item.subfunc : e.item;
    }
    navigate(toFunction, language);
  };

  navigateEvent = (key) => {
    const { navigate, permissions, language } = this.props;
    const toFunction = permissions.find((x) => x.name === key);
    if (toFunction) {
      navigate(toFunction, language);
    }
  };

  renderCards = () => {
    const { permissions, currentFunction } = this.props;
    const { spinIcon } = this.state;
    const iconDefaultSize = "50px";
    const homeItem = (
      <div className="col-24 col-sm-12 col-lg-6" key="div_home">
        <Link
          to={"#"}
          className="border-card-blk color-set--red"
          onMouseOver={() => {
            spinIcon[homeKey] = true;
            this.setState({ spinIcon: spinIcon });
          }}
          onMouseLeave={() => {
            spinIcon[homeKey] = false;
            this.setState({ spinIcon: spinIcon });
          }}
          onClick={(e) => {
            spinIcon[homeKey] = false;
            this.setState({ spinIcon: spinIcon });
            this.handleNavigate({
              key: homeKey,
              domEvent: e,
              item: permissions,
            });
          }}
        >
          <p className="border-card-blk__title js-dot cust-vertical-center default-font-color">
            {"Back to " + label("FN0000")}
          </p>
          <p className="border-card-blk__text">
            <Icon
              type="home"
              style={{ fontSize: iconDefaultSize }}
              spin={spinIcon[homeKey]}
            />
          </p>
        </Link>
      </div>
    );
    const cardItems = currentFunction.map((item, key) => {
      if (item.is_detail) {
        return "";
      } else if (
        (permissions === currentFunction && !item.parent_uid) ||
        (permissions !== currentFunction && item.subfunc.length === 0)
      ) {
        return (
          <div className="col-24 col-sm-12 col-lg-6" key={"div_" + item.key}>
            <Link
              to={"#"}
              className="border-card-blk color-set-red"
              onMouseOver={() => {
                spinIcon[item.key] = true;
                this.setState({ spinIcon: spinIcon });
              }}
              onMouseLeave={() => {
                spinIcon[item.key] = false;
                this.setState({ spinIcon: spinIcon });
              }}
              onClick={(e) => {
                spinIcon[item.key] = false;
                this.setState({ spinIcon: spinIcon });
                this.handleNavigate({
                  key: item.key,
                  domEvent: e,
                  item: currentFunction[key],
                });
              }}
            >
              <p className="border-card-blk__title js-dot cust-vertical-center default-font-color">
                {label(item.label)}
              </p>
              <p className="border-card-blk__text">
                <Icon
                  type={item.icon}
                  style={{ fontSize: iconDefaultSize }}
                  className="default-font-color"
                  spin={this.state.spinIcon[item.key]}
                />
              </p>
            </Link>
          </div>
        );
      } else {
        return "";
      }
    });

    return (
      <Row gutter={24}>
        {permissions !== currentFunction ? homeItem : ""}
        {cardItems}
      </Row>
    );
  };

  renderNotice = () => {
    return (
      <div style={{ margin: "2em 0 0" }}>
        {/* Static content that can be inserted by users */}
        <div className="static-content" style={{ textAlign: "justify" }}>
          <ul>
            <li style={{ marginBottom: "1rem" }}>
              This portal accepts online payment with
              <b> Alipay, WeChat Pay, FPS, Visa, Master and CUP card </b>
              <a
                href="https://static.95516.com/static/page/176.html"
                className="hyperLink"
                target="_blank"
                rel="noreferrer"
              >
                (China UnionPay card issued by banks in the Chinese Mainland,
                and specified UnionPay credit cards issued in Hong Kong and
                Macau).
              </a>
            </li>
          </ul>
        </div>
        <Button
          className={defaultBtnStyle + " cust-button-height"}
          onClick={(e) => {
            this.navigateEvent(eventKey);
          }}
        >
          {label("LBSN001")}
        </Button>
      </div>
    );
  };

  render() {
    const { currentFunction, currWidthOption, currBannerOption, bannerLabel } =
      this.props;
    const Tag = this.components[currentFunction.component];

    const divider =
      currBannerOption !== undefined && currBannerOption.value === "simple" ? (
        <div className="d-block d-lg-none">
          <Divider orientation="left" plain>
            <Breadcrumb className="cust-vertical-center">
              <Breadcrumb.Item href="">
                <span>{bannerLabel === undefined ? "" : bannerLabel}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Divider>
        </div>
      ) : (
        ""
      );

    return (
      <div className="border-cards component-margin-top--t2 component-margin-bottom--t2">
        <div className={currWidthOption.value}>
          {divider}
          <div
            className="row border-cards__row"
            style={{ justifyContent: "center" }}
          >
            {Array.isArray(currentFunction) ? (
              this.renderNotice()
            ) : (
              <Tag key={"tag_" + currentFunction.component} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.dashboard.currLang,
    permissions: state.session.permissions,
    currentFunction: state.dashboard.currentFunction
      ? state.dashboard.currentFunction
      : state.session.permissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  navigate: (toFunction, language) => {
    dispatch(navigateAction(toFunction, language));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);
