import React from "react";
//import { label } from '../../../../framework';

// The <Banner> component used to define banner on each EWCMS's page...
class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.image = props.image;
    this.name = props.name;
  }

  render() {
    const { image, name } = this.props;
    return (
      <div>
        <div
          className={[
            "na-top",
            "na-top--w-bg",
            "color-set",
            "component-margin-bottom--t3",
            "color-set--red",
          ].join(" ")}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL + image})` }}
        >
          <div className={["na-top__upper", "theme-color-b4-bg"].join(" ")}>
            <div className="container">
              <h4 className="na-top__breadcrumb">
                {/*label('home.app.title')*/}
              </h4>
              <h1 className={["na-top__title", "theme-color-text"].join(" ")}>
                {name}
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
