import * as logger from "loglevel";
import * as prefix from "loglevel-plugin-prefix";

logger.setLevel(process.env.REACT_APP_LOG_LEVEL);

prefix.reg(logger);
prefix.apply(logger, {
  template: "[%t] %l (%n) :",
  levelFormatter(level) {
    return level.toUpperCase();
  },
  nameFormatter(name) {
    return name || "global";
  },
  timestampFormatter(date) {
    return date.toLocaleString("en-US");
  },
});

export default logger;
