/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  state = {
    currentFunction: null,
    loadingCount: 0,
    currPageFunction: null,
    filteredInfo: null,
    currLang: "",
    searchParams: {},
    filterQuery: null,
  },
  action
) => {
  let newState = state;
  switch (action.type) {
    case "NAVIGATE":
      newState = Object.assign({}, newState, {
        currentFunction: action.payload.to,
        currPageFunction: action.payload.curr,
        filteredInfo: null,
        currLang: action.payload.lang,
        routeTo: action.payload.routeTo,
        searchParams: action.payload.searchParams,
      });
      break;
    case "SET_FILTERED_INFO":
      newState = Object.assign({}, newState, {
        filteredInfo: action.payload.filteredInfo,
      });
      break;
    case "DATA-LOADING-BEGIN":
      newState = Object.assign({}, newState, {
        loadingCount: state.loadingCount + 1,
      });
      break;
    case "DATA-LOADING-END":
      newState = Object.assign({}, newState, {
        loadingCount: state.loadingCount - 1,
      });
      break;
    case "LOGOUT-SUCCESS":
      newState = { currentFunction: null, loadingCount: state.loadingCount };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};
