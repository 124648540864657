import { Button, Form, Modal, Input, Popconfirm } from "antd";
import React, { useRef, useState } from "react";
import { label } from "../../../../framework";
import * as ewcms from "../../EWCMS/styleMaster";
import { connect } from "react-redux";
import { Icon } from "../../../shared/Icon";

const defaultBtnStyle = ewcms.defaultBtnStyle;
const cellSpan = 24;

function EmailModal(props) {
  const {
    isMandName,
    isMandEmail,
    title,
    btnEnabled,
    onInputValidate,
    changeInfo,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const passConfirm = props.portalClient?.selectedEvent?.shoppingCart
    ? props.portalClient.selectedEvent.shoppingCart.every(
        (item) => item.amount > 0
      )
    : false;

  const formRef = useRef();
  const getValidationRules = (datatype, colLabel, isMandatory) => {
    const rules = [];
    if (datatype === "email") {
      rules.push({
        type: "email",
        message: label("EC0004").replace("{%1}", colLabel),
      });
    }
    if (isMandatory) {
      rules.push({
        required: true,
        message: label("EC0005").replace("{%1}", colLabel),
      });
    }
    return rules;
  };

  const CheckOutBtn = (
    <Button
      className="rbtn--red"
      size="large"
      style={{ width: "100%", display: "initial" }}
      disabled={!btnEnabled}
      onClick={() => {
        if (passConfirm) setShowModal(true);
      }}
    >
      <div>
        {label("LBSC007")}{" "}
        <Icon
          type="CheckCircleTwoTone"
          style={{ fontSize: "22px" }}
          twoToneColor={btnEnabled ? "#52c41a" : ewcms.lightGrey}
        />
      </div>
    </Button>
  );

  return (
    <>
      {(passConfirm || !btnEnabled) && CheckOutBtn}
      {!passConfirm && btnEnabled && (
        <Popconfirm
          title={
            <span>
              One or more items are of HK$0.00. Are you sure to proceed?
            </span>
          }
          okButtonProps={{ className: defaultBtnStyle }}
          cancelButtonProps={{ className: defaultBtnStyle }}
          onConfirm={() => setShowModal(true)}
          okText="Yes"
          cancelText="No"
        >
          {CheckOutBtn}
        </Popconfirm>
      )}
      <Modal
        title={title}
        open={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <Form
          ref={formRef}
          initialValues={{
            payeremail: props.portalClient.selectedEvent.emailAddress,
            payerName: props.portalClient.selectedEvent.payerName,
          }}
        >
          {isMandName === 1 && (
            <Form.Item
              label={label("LBOT031")}
              key="payerName"
              labelCol={{ span: cellSpan }}
              wrapperCol={{ span: cellSpan }}
              required={false}
              name={"payerName"}
              className="border-cards-reverse"
              rules={getValidationRules("string", label("LBOT031"), isMandName)}
            >
              <Input
                className="form-control"
                onBlur={(event) => {
                  formRef.current?.setFieldsValue({
                    payerName: event.target.value.trim(),
                  });
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label={label("LBOT032")}
            key="payeremail"
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={"payeremail"}
            className="border-cards-reverse"
            validateTrigger="onSubmit" // validate  onSubmit only
            rules={getValidationRules("email", label("LBOT032"), isMandEmail)}
          >
            <Input
              className="form-control"
              onBlur={(event) => {
                formRef.current?.setFieldsValue({
                  payeremail: event.target.value.toLowerCase().trim(),
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label={label("LBOT033")}
            key="payeremailconfirm"
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={"payeremailconfirm"}
            className="border-cards-reverse"
            validateTrigger="onSubmit" // validate onSubmit only
            rules={[
              getValidationRules("email", label("LBOT033"), isMandEmail),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue("payeremail") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two emails that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input
              className="form-control"
              onBlur={(event) => {
                formRef.current?.setFieldsValue({
                  payeremailconfirm: event.target.value.toLowerCase().trim(),
                });
              }}
            />
          </Form.Item>
          <div style={{ margin: "0.75rem 0" }}>
            This email address is required for transaction enquiry after
            payment.
          </div>
          <div style={{ fontSize: "0.75rem", marginBottom: "0.75rem" }}>
            <a
              l
              href="https://www.polyu.edu.hk/fo/docdrive/Suppliers_and_Visitors/Invoice_Payment/FO_pics_payer.pdf"
              target="_blank"
              rel="noreferrer"
              className="hyperLink"
            >
              Personal Information Collection Statement (PICS)
            </a>
          </div>
          <Form.Item>
            <Button
              className={defaultBtnStyle + " cust-button-height"}
              type="primary"
              onClick={() => {
                formRef.current
                  .validateFields()
                  .then((fieldsVal) => {
                    //Call API
                    changeInfo(fieldsVal.payerName, fieldsVal.payeremail);
                    onInputValidate();
                  })
                  .catch((errorInfo) => {
                    console.log("errorInfo: ", errorInfo);
                  });
              }}
            >
              {label("LBOT035")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.session.permissions,
    language: state.dashboard.currLang,
    csrf: state.action.csrf,
    portalClient: state.portalClient,
    configData: state.codetable,
    type: state.action.type,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeInfo: (payerName, emailAddress) => {
    dispatch({
      type: "CHANGE_INFO",
      payload: { payerName: payerName, emailAddress: emailAddress },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailModal);
