/**
 * COLOR
 */
const polyuRed = "#A02337";
const polyuGrey = "#58595B";

const black = "#000000";
const darkGrey = "#333333";
const mediumGrey = "#454545";
const lightGrey = "#B4B4B4";
const lightlightGrey = "#EFEEEE";
const beige = "#F4F4F4";
const white = "#FFFFFF";

const tagOrange = "#D3492E";

/**
 * button style
 */
const defaultBtnStyle = "rbtn rbtn--fix-color rbtn--red";
const greyBtnStyle = "rbtn rbtn--fix-color rtbn--grey";

export {
  polyuRed,
  polyuGrey,
  black,
  darkGrey,
  mediumGrey,
  lightGrey,
  lightlightGrey,
  beige,
  white,
  tagOrange,
  defaultBtnStyle,
  greyBtnStyle,
};
