import React from "react";
import Iframe from "react-iframe";

class ApplicationMonitor extends React.Component {
  render() {
    return (
      <Iframe
        url="../apm"
        width="100%"
        //height="100%"
        height="1100vh"
        id="apm-frame"
        frameBorder="0"
      />
    );
  }
}

export default ApplicationMonitor;
