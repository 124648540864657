import React from "react";
import { label } from "../../../../framework";

class Footer extends React.Component {
  render() {
    const imgPath = process.env.PUBLIC_URL + "/assets/img/";

    return (
      <>
        <footer className="page-foot--fact page-foot">
          <div className="container">
            <div className="ft-row component-margin--no-top">
              <div className="ft-row__blk ft-row__blk--left">
                <ul className="ft-site-link-list">
                  <li className="ft-site-link-list__item">
                    <a
                      href="http://www.polyu.edu.hk"
                      className="ft-site-link-list__link"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      POLYU HOME
                    </a>
                  </li>
                  <li className="ft-site-link-list__item">
                    <a
                      href="http://www.polyu.edu.hk/fo"
                      className="ft-site-link-list__link"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      FO HOME
                    </a>
                  </li>
                </ul>
                <p className="cp">{label("dashboard.maincontent.footer")}</p>
              </div>
              <div className="ft-row__blk ft-row__blk--right">
                <div className="ft-tagline">
                  <img
                    src={imgPath + "tagline-1x.png"}
                    alt=""
                    srcSet={
                      imgPath +
                      "tagline-1x.png 1x," +
                      imgPath +
                      "tagline-2x.png 2x"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
