import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "../../shared/Icon";
import { Menu, Dropdown, Space, Badge, Tooltip } from "antd";
import { label } from "../../../framework";
import {
  logoutAction,
  refreshCsrf,
  navigateAction,
} from "../../../redux/actions";
import * as ewcms from "../../screen/EWCMS/styleMaster";

const { SubMenu } = Menu;
const polyuColor = ewcms.polyuRed;
const dropdownMenuStyle = {
  fontSize: "0.875rem",
  justifyContent: "flex-start",
};
const dropdownIconStyle = { color: polyuColor, fontSize: "12px" };

// const NO_OF_CHECKING      = 30;
const CHECK_DURATION_SECS = 60; //seconds
const NO_OF_CHECKING = 30;

class HeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.myTimer = null;
    this.onSwitchWidth = props.onSwitchWidth;
    this.onSwitchBanner = props.onSwitchBanner;
    this.enableLogout = false;
    this.state = {
      currWidth: {
        key: label("screen.dashboard.headerbar.viewoption.best"),
        value: "container-best",
      },
      currBanner: {
        key: label("screen.dashboard.headerbar.banneroption.simple"),
        value: "simple",
      },
    };
  }

  widthOptions = [
    {
      key: label("screen.dashboard.headerbar.viewoption.default"),
      value: "container",
    },
    {
      key: label("screen.dashboard.headerbar.viewoption.best"),
      value: "container-best",
    },
    {
      key: label("screen.dashboard.headerbar.viewoption.full"),
      value: "container-ig",
    },
  ];

  bannerOptions = [
    {
      key: label("screen.dashboard.headerbar.banneroption.simple"),
      value: "simple",
    },
    {
      key: label("screen.dashboard.headerbar.banneroption.default"),
      value: "default",
    },
  ];

  onLogoutClick = (e) => {
    const { logout, csrf } = this.props;
    if (e.key === "logout") {
      clearTimeout(this.myTimer);
      logout(csrf);
      window.opener = null;
      window.open("", "_self");
      window.close();
    }
  };

  swithWidth = (e) => {
    const currOption = this.widthOptions.filter((obj) => obj.key === e.key)[0];

    this.setState({ currWidth: currOption });
    if (this.onSwitchWidth !== undefined) {
      this.onSwitchWidth(e, currOption);
    }
  };

  switchBanner = (e) => {
    const currOption = this.bannerOptions.filter((obj) => obj.key === e.key)[0];
    this.setState({ currBanner: currOption });
    if (this.onSwitchBanner !== undefined) {
      this.onSwitchBanner(e, currOption);
    }
  };

  timeOutFunction = (index) => {
    const { refreshCsrf } = this.props;
    this.myTimer = setTimeout(() => {
      if (index > 0) {
        this.timeOutFunction(index - 1);
        refreshCsrf();
      }
    }, CHECK_DURATION_SECS * 1000);
  };

  componentDidMount = () => {
    this.timeOutFunction(NO_OF_CHECKING);
  };

  handleNavigateToShoppingCard = () => {
    const { permissions, language, navigate } = this.props;
    const shoppingCartFunc = permissions.filter(
      (perm) => perm.label === "FN1002_shoppingCart"
    );
    const toFunc =
      shoppingCartFunc.length > 0 ? shoppingCartFunc[0] : permissions;
    navigate(toFunc, language);
  };

  render() {
    const { portalClient } = this.props;
    const selectedIcon = (
      <Icon type="CheckCircleTwoTone" twoToneColor="#52c41a" />
    );
    const optionIcon = (
      <Icon type="MinusCircleTwoTone" twoToneColor="#ff4d4f" />
    );
    const dropDownIcon = (
      <Icon
        type="CaretDownOutlined"
        style={{ color: polyuColor, fontSize: "8px" }}
      />
    );
    const renderWidthMenu = this.widthOptions.map((obj) => {
      return (
        <Menu.Item key={obj.key} onClick={this.swithWidth}>
          <span className="cust-vertical-center" style={dropdownMenuStyle}>
            {obj.key === this.state.currWidth.key ? selectedIcon : optionIcon}
            &nbsp; {obj.key}
          </span>
        </Menu.Item>
      );
    });

    const renderBannerMenu = this.bannerOptions.map((obj) => {
      return (
        <Menu.Item key={obj.key} onClick={this.switchBanner}>
          <span className="cust-vertical-center" style={dropdownMenuStyle}>
            {obj.key === this.state.currBanner.key ? selectedIcon : optionIcon}
            &nbsp; {obj.key}
          </span>
        </Menu.Item>
      );
    });

    const swithWidthMenu = (
      <SubMenu
        key="hb_sm_swm"
        title={
          <span style={dropdownMenuStyle}>
            {"View" /*this.state.currWidth.key*/}
          </span>
        }
        style={dropdownMenuStyle}
      >
        {renderWidthMenu}
      </SubMenu>
    );
    const switchBannerMenu = (
      <SubMenu
        key="hb_sm_sbm"
        title={
          <span style={dropdownMenuStyle}>
            {"Banner" /*this.state.currBanner.key*/}
          </span>
        }
        style={dropdownMenuStyle}
      >
        {renderBannerMenu}
      </SubMenu>
    );
    const ewcmsMenu = (
      <Menu selectable={false}>
        {swithWidthMenu}
        {switchBannerMenu}
      </Menu>
    );

    const noOfItem =
      portalClient.selectedEvent !== undefined &&
      portalClient.selectedEvent.shoppingCart !== undefined &&
      portalClient.selectedEvent.shoppingCart.length > 0
        ? portalClient.selectedEvent.shoppingCart
            .map((item) => item.quantity)
            .reduce((acc, item) => item + acc)
        : 0;

    const shoppingCart = (
      <Link
        to={"#"}
        onClick={() => {
          this.handleNavigateToShoppingCard();
        }}
      >
        <Tooltip
          title="Check Out"
          className={noOfItem > 0 ? "gentle-tilt-move-shake" : ""}
        >
          <Badge
            style={{ backgroundColor: polyuColor }}
            count={noOfItem}
            offset={[5, 13]}
          >
            <Icon
              type="ShoppingCartOutlined"
              style={{ color: polyuColor, fontSize: "30px" }}
            ></Icon>
          </Badge>
        </Tooltip>
      </Link>
    );

    return (
      <Space size="large" key="headerBar">
        {shoppingCart}
        <Dropdown overlay={ewcmsMenu} key="hb_dd_swm">
          {
            <span className="cust-vertical-center selectCurosr">
              <Icon type="SettingOutlined" style={dropdownIconStyle} />
              &nbsp;Settings &nbsp;{dropDownIcon}
            </span>
          }
        </Dropdown>
      </Space>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.session.lang,
    permissions: state.session.permissions,
    csrf: state.action.csrf,
    netId: state.session.netId,
    portalClient: state.portalClient,
    currPath: state.dashboard.routeTo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: (csrf) => {
    dispatch(logoutAction(csrf));
  },
  refreshCsrf: () => {
    dispatch(refreshCsrf());
  },
  navigate: (toFunction, language) => {
    dispatch(navigateAction(toFunction, language));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar);
