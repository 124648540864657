import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { md5 /*label, logger*/ } from "../framework";
import { loginAction, authenticateAction } from "../redux/actions";
import { Route } from "react-router-dom";

function InitRoute(props) {
  const {
    login,
    init,
    doAuthenticate,
    doAuthFail,
    isLogin,
    isInit,
    isChecking,
    isAuthenticated,
    component: Component,
    ...rest
  } = props;

  const [guest, setGuest] = useState({
    netId: "gppuser",
    netPwd: "0000",
    version: 0,
    clientErrorMsg: "",
    callSteps: 0,
    submitInProgress: false,
  });

  const [loadState, setloadState] = useState({
    loadDataStep: 0,
    loadInProgress: false,
    ready: false,
  });

  useMemo(() => {
    if (!loadState.loadInProgress && loadState.loadDataStep === 0) {
      try {
        setloadState({ loadDataStep: 1, loadInProgress: true, ready: false });
        login(guest.netId, md5.encrypt(guest.netPwd));
      } catch (err) {
        setGuest((prevG) => {
          return { ...prevG, clientErrorMsg: "Error. " + err };
        });
      }
    }

    if (loadState.loadDataStep === 1 && loadState.loadInProgress && isLogin) {
      try {
        setloadState({ ...loadState, loadDataStep: 2 });
        doAuthenticate();
      } catch (err) {
        console.error(err);
        setGuest((prevG) => {
          return { ...prevG, clientErrorMsg: "Error. " + err };
        });
        setloadState((prevL) => {
          return { ...prevL, loadInProgress: false };
        });
        doAuthFail();
      }
    }

    if (
      loadState.loadDataStep === 2 &&
      loadState.loadInProgress &&
      !isChecking &&
      isAuthenticated
    ) {
      //Ready state set to true
      setloadState({ loadDataStep: 3, ready: true, loadInProgress: false });
    }
  }, [
    loadState,
    isLogin,
    isChecking,
    isAuthenticated,
    doAuthFail,
    doAuthenticate,
    login,
    guest,
  ]);

  return (
    <>
      {loadState.ready && (
        <Route {...rest} render={(props) => <Component {...props} />} />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.session.netId !== "",
    isChecking:
      state.action.type !== "AUTHENTICATE-SUCCESS" &&
      state.action.type !== "AUTHENTICATE-ERROR",
    isAuthenticated:
      state.session.email !== "" && state.session.permissions.length > 0,
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => {
    dispatch(loginAction(email, password));
  },
  doAuthenticate: () => {
    dispatch(authenticateAction());
  },
  doAuthFail: () => {
    dispatch({
      type: "AUTHENTICATE-ERROR",
      payload: { result: "EC0011", error: "user not authorized to login" },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InitRoute);
