import { history, logger, httpGet, httpPost } from "../../framework";

function createAction(result, type, additionalPayload) {
  let returnResult = {};
  if (result instanceof Error) {
    returnResult = {
      type,
      payload: Object.assign(
        { code: "EC9999", error: result.message },
        additionalPayload
      ),
    };
  } else if (result.result === "E99998") {
    history.push("/"); // redirect to login page after session timed out
    const resultPayload = {
      result: result.result,
      error: result.error,
      csrf: result.csrf,
    };
    returnResult = { type, payload: resultPayload };
  } else {
    let resultPayload = { result: result.result, csrf: result.csrf };
    resultPayload =
      result.error !== undefined
        ? Object.assign(resultPayload, { error: result.error })
        : resultPayload;
    resultPayload =
      result.value !== undefined
        ? Object.assign(resultPayload, { value: result.value })
        : resultPayload;
    resultPayload =
      additionalPayload !== undefined
        ? Object.assign(resultPayload, additionalPayload)
        : resultPayload;
    returnResult = { type, payload: resultPayload };
  }
  return returnResult;
}

function createErrorAction(result, type, additionalPayload) {
  const r = typeof result === "string" ? new Error(result) : result;
  return createAction(r, type, additionalPayload);
}

export function loginAction(email, passwd) {
  return async (dispatch) => {
    try {
      dispatch({ type: "DATA-LOADING-BEGIN" });
      const response = await httpPost("/auth/login", { email, passwd });
      if (response.result === "0") {
        dispatch(createAction(response, "LOGIN-SUCCESS"));
        //history.push('/dashboard');
      } else {
        dispatch(createErrorAction(response, "LOGIN-ERROR"));
      }
    } catch (err) {
      dispatch(createErrorAction(err, "LOGIN-ERROR"));
    } finally {
      dispatch({ type: "DATA-LOADING-END" });
    }
  };
}

export function logoutAction(csrf) {
  return async (dispatch) => {
    try {
      dispatch({ type: "DATA-LOADING-BEGIN" });
      const response = await httpPost("/auth/logout", { csrf });
      if (response.result === "0") {
        dispatch(createAction(response, "LOGOUT-SUCCESS"));
        history.push(response.redirectURL);
      } else {
        dispatch(createErrorAction(response, "LOGOUT-ERROR"));
      }
    } catch (err) {
      history.push("/");
    } finally {
      dispatch({ type: "DATA-LOADING-END" });
    }
  };
}

export function authenticateAction() {
  return async (dispatch) => {
    try {
      dispatch({ type: "DATA-LOADING-BEGIN" });
      const response = await httpGet("/auth/authenticate");
      if (response.result === "0") {
        dispatch(createAction(response, "AUTHENTICATE-SUCCESS"));
      } else {
        dispatch(createErrorAction(response, "AUTHENTICATE-ERROR"));
        //history.push('/');
      }
    } catch (err) {
      //history.push('/');
    } finally {
      dispatch({ type: "DATA-LOADING-END" });
    }
  };
}

export function navigateAction(func, lang, searchParams = undefined) {
  return async (dispatch, getState) => {
    const state = getState();
    const currFunc = state.dashboard.currentFunction;
    let searchP = {};
    if (searchParams) {
      for (const key of searchParams.keys()) {
        searchP[key] = searchParams.get(key);
      }
    }
    let routeTo = "";
    const targetPath = "/portal";
    const path =
      lang === undefined || lang === null
        ? targetPath
        : "/" + lang + targetPath;

    if (!Array.isArray(func)) {
      if (func.path_param && Array.isArray(func.path_param)) {
        // Dashboard.js.  Line 113, 114
        let pathParams = "";
        for (var i in func.path_param) {
          // logger.getLogger('param').debug(func.path_param[i]);
          pathParams = pathParams + "/" + func.path_param[i].urlParamVal;
        }
        routeTo =
          path +
          (func.backfuncName !== "" ? "/" + func.backfuncName + "/" : "/") +
          func.name +
          pathParams;
      } else if (func.component_param) {
        // should not be used
        routeTo =
          path +
          (func.backfuncName !== "" ? "/" + func.backfuncName + "/" : "/") +
          func.name +
          (func.is_detail && func.component_param
            ? "/" + func.component_param
            : "");
      }
    } else if (func !== undefined && func.length > 0) {
      routeTo =
        path + (func[0].backfuncName !== "" ? "/" + func[0].backfuncName : "");
    } else {
      routeTo = path;
    }

    try {
      history.push(routeTo);
    } catch (err) {
      routeToPageNotFoundAction();
    }

    const action = {
      type: "NAVIGATE",
      payload: {
        to: func,
        curr: currFunc,
        lang: lang,
        routeTo: routeTo,
        searchParams: searchP,
      },
    };
    dispatch(action);
  };
}

export function routeToPageNotFoundAction() {
  return async () => {
    history.push("/pageNotFound");
  };
}

export function setFilteredInfoAction(filteredInfo) {
  return async (dispatch) => {
    const action = {
      type: "SET_FILTERED_INFO",
      payload: { filteredInfo: filteredInfo },
    };
    dispatch(action);
  };
}

export function entityListAction(entityName, csrf, query = undefined) {
  return async (dispatch) => {
    try {
      dispatch({ type: "DATA-LOADING-BEGIN" });
      let url = "/api/protected/data/list/" + entityName;
      const s_query = new URLSearchParams(query);
      if (s_query) {
        url += "?" + s_query.toString();
      }
      const response = await httpGet(url);
      if (response.result === "0") {
        dispatch(createAction(response, "DATA-LIST-SUCCESS"));
      } else {
        dispatch(createErrorAction(response, "DATA-LIST-ERROR"));
      }
    } catch (err) {
      dispatch(createErrorAction(err, "DATA-LIST-ERROR"));
    } finally {
      dispatch({ type: "DATA-LOADING-END" });
    }
  };
}

// Server side render (pagination)
export function entityFilterAction(entityName, csrf, offset, limit, params) {
  // FilterType: view, export
  return async (dispatch) => {
    const queryParams = new URLSearchParams();
    queryParams.append("offset", offset);
    queryParams.append("limit", limit);

    logger.debug("************************ entityFilterAction [start]");
    logger.debug(params);
    logger.debug("************************ entityFilterAction [end]");

    if (params) {
      queryParams.append("params", JSON.stringify(params));
      logger.debug(params);
    }

    try {
      dispatch({ type: "DATA-LOADING-BEGIN" });
      const response = await httpGet(
        `/api/protected/data/filter/${entityName}?${queryParams.toString()}`
      );
      if (response.result === "0") {
        dispatch(createAction(response, "DATA-FILTER-SUCCESS"));
      } else {
        dispatch(createErrorAction(response, "DATA-LIST-ERROR"));
      }
    } catch (err) {
      dispatch(createErrorAction(err, "DATA-LIST-ERROR"));
    } finally {
      dispatch({ type: "DATA-LOADING-END" });
    }
  };
}

export function entityCreateAction(entityName, data, csrf) {
  return async (dispatch) => {
    try {
      dispatch({ type: "DATA-LOADING-BEGIN" });
      let response = await httpPost(
        "/api/protected/data/create/" + entityName,
        { data, csrf }
      );
      if (response.result === "0") {
        dispatch(createAction(response, "DATA-CREATE-SUCCESS", { entityName }));
        response = await httpGet("/api/protected/data/list/" + entityName);
        if (response.result === "0") {
          dispatch(createAction(response, "DATA-LIST-SUCCESS"));
        } else {
          dispatch(createErrorAction(response, "DATA-LIST-ERROR"));
        }
      } else {
        dispatch(
          createErrorAction(response, "DATA-CREATE-ERROR", { entityName })
        );
      }
    } catch (err) {
      dispatch(createErrorAction(err, "DATA-CREATE-ERROR"));
    } finally {
      dispatch({ type: "DATA-LOADING-END" });
    }
  };
}

export function entityUpdateAction(entityName, recordKey, data, csrf) {
  return async (dispatch) => {
    logger.debug(" ****************************** entityName");
    logger.debug(entityName);
    logger.debug(" ****************************** recordKey");
    logger.debug(recordKey);
    logger.debug(" ****************************** data");
    logger.debug(data);

    try {
      dispatch({ type: "DATA-LOADING-BEGIN" });
      let response = await httpPost(
        "/api/protected/data/update/" + entityName,
        { key: recordKey, data, csrf }
      );
      if (response.result === "0") {
        dispatch(createAction(response, "DATA-UPDATE-SUCCESS", { entityName }));
        response = await httpGet("/api/protected/data/list/" + entityName);
        if (response.result === "0") {
          dispatch(createAction(response, "DATA-LIST-SUCCESS"));
        } else {
          dispatch(createErrorAction(response, "DATA-LIST-ERROR"));
        }
      } else {
        dispatch(
          createErrorAction(response, "DATA-UPDATE-ERROR", { entityName })
        );
      }
    } catch (err) {
      dispatch(createErrorAction(err, "DATA-UPDATE-ERROR"));
    } finally {
      dispatch({ type: "DATA-LOADING-END" });
    }
  };
}

export function entityDeleteAction(entityName, recordKey, csrf) {
  return async (dispatch) => {
    try {
      dispatch({ type: "DATA-LOADING-BEGIN" });
      let response = await httpPost(
        "/api/protected/data/delete/" + entityName,
        { key: recordKey, csrf }
      );
      if (response.result === "0") {
        dispatch(createAction(response, "DATA-DELETE-SUCCESS", { entityName }));
        response = await httpGet("/api/protected/data/list/" + entityName);
        if (response.result === "0") {
          dispatch(createAction(response, "DATA-LIST-SUCCESS"));
        } else {
          dispatch(createErrorAction(response, "DATA-LIST-ERROR"));
        }
      } else {
        dispatch(
          createErrorAction(response, "DATA-DELETE-ERROR", { entityName })
        );
      }
    } catch (err) {
      dispatch(createErrorAction(err, "DATA-DELETE-ERROR"));
    } finally {
      dispatch({ type: "DATA-LOADING-END" });
    }
  };
}

export function refreshCsrf() {
  return async (dispatch) => {
    let response = await httpGet("/refreshCsrf");
    if (response.result === "0") {
      response = await httpGet("/api/protected/func/checkSession");
      if (response.result === "0") {
        dispatch(createAction(response, "REFRESH-CSRF"));
      }
    }
  };
}

export function handleCheckOut() {
  return async (_, getState) => {
    //Check Validity of email address
    try {
      const state = getState();
      const csrf = state.action.csrf;
      const portalClient = state.portalClient;
      const order = {
        key: portalClient.selectedEvent.paymentRefIdPrefix,
        event_id: portalClient.selectedEvent.eventId,
        remarks: portalClient.selectedEvent.remarks,
        total_amount: portalClient.selectedEvent.totalAmount,
        payer_name: portalClient.selectedEvent.payerName,
        payer_email: portalClient.selectedEvent.emailAddress,
        //is_direct_link: portalClient.selectedEvent.is_direct_link ? "1" : "0",
        orderDetails: portalClient.selectedEvent.shoppingCart.map(
          (orderDetail, idx) => {
            return {
              item_id: orderDetail.itemId,
              quantity: orderDetail.quantity,
              amount: orderDetail.amount,
              item_total: orderDetail.itemTotal,
              item_remarks: orderDetail.itemRemarks,
            };
          }
        ),
      };
      const orderRes = await httpPost("/api/protected/order/create", {
        order,
        csrf,
      });
      let request = {
        eventId: portalClient.selectedEvent.eventId,
        d: {
          type: "STATIC",
          referenceid: orderRes.value.key,
          paymentmethod: {
            paymentmethodcode: "polyu.olpp.*",
            amount: Math.round(orderRes.value.total_amount * 100),
            message: orderRes.value.remarks,
          },
          callbackurl: process.env.REACT_APP_ORDER_CALLBACK_URL,
        },
      };
      if (portalClient.selectedEvent?.payerName) {
        request.d.payer_name = portalClient.selectedEvent.payerName;
      }
      if (portalClient.selectedEvent?.emailAddress) {
        request.d.payer_email = portalClient.selectedEvent.emailAddress;
      }

      await fetch("/order/checkout/paymentrequest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      }).then((res) => {
        if (res.redirected) {
          window.location.href = res.url;
        }
      });
    } catch (err) {
      window.location.reload();
    }
  };
}
