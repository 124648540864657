import React from "react";
import { label } from "../../../../framework";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.mobileIcon = props.mobileIcon;
    this.menu = props.menu;
    this.barMenu = props.barMenu;
  }

  render() {
    const { menu, mobileIcon, barMenu } = this.props;
    const imgPath = process.env.PUBLIC_URL + "/assets/img/";

    const menuVal = menu !== undefined ? menu : null;
    const mobileIconVal = mobileIcon !== undefined ? mobileIcon : null;
    const barMenuVal = barMenu !== undefined ? barMenu : null;

    return (
      <>
        <header className="fixed-page-head menu fact-header" id="header">
          <div className="header-wrap">
            <div className="fact-header__top">
              <div className="container header-top__container">
                <div className="header-wrap__left">
                  <h4 className="header-name header-name--dept">
                    {label("home.app.title")}
                  </h4>
                </div>
                <div className="header-wrap__right">
                  <div className="fn-blk-wrap">
                    {/* Logout Button here */}
                    {barMenuVal}
                    <div className="fact-header__poly-logo fact-logo-container theme-color-b4-bg">
                      <img
                        src={imgPath + "fact-logo-1x.png"}
                        alt=""
                        srcSet={
                          imgPath +
                          "fact-logo-1x.png 1x," +
                          imgPath +
                          "fact-logo-2x.png 2x," +
                          imgPath +
                          "fact-logo-3x.png 3x"
                        }
                        className="fact-main-logo"
                      />
                    </div>
                  </div>
                  {/* Mobile Menu Here */}
                  {mobileIconVal}
                </div>
              </div>
              {/* Menu */}
              {menuVal}
            </div>
          </div>
        </header>
        <header className="page-head menu fact-header" id="header">
          <div className="header-wrap">
            <div className="fact-header__top">
              <div className="container header-top__container">
                <div className="header-wrap__left">
                  <h4 className="header-name header-name--dept">
                    {label("home.app.title")}
                  </h4>
                </div>
                <div className="header-wrap__right">
                  <div className="fn-blk-wrap">
                    {/* Logout Button here */}
                    {barMenuVal}
                    <div className="fact-header__poly-logo fact-logo-container theme-color-b4-bg">
                      <img
                        src={imgPath + "fact-logo-1x.png"}
                        alt=""
                        srcSet={
                          imgPath +
                          "fact-logo-1x.png 1x," +
                          imgPath +
                          "fact-logo-2x.png 2x," +
                          imgPath +
                          "fact-logo-3x.png 3x"
                        }
                        className="fact-main-logo"
                      />
                    </div>
                  </div>
                  {/* Mobile Menu Here */}
                  {mobileIconVal}
                </div>
              </div>
              {/* Menu */}
              {menuVal}
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
