import React from "react";
import { connect } from "react-redux";
import { Icon } from "../../../shared/Icon";
import * as ewcms from "../../EWCMS/styleMaster";
import { label } from "../../../../framework";
import { Select, Collapse, Form, AutoComplete, Input } from "antd";
import _ from "lodash";

const { Panel } = Collapse;
const { Option } = Select;

const mediumGrey = ewcms.mediumGrey;
const iconStyle = { fontSize: "15px", color: mediumGrey };
//const colSpan = 24;

class AdvanceSearchComponent extends React.Component {
  extractEvent(events, type) {
    let resultsArr = [];
    let idx = 0;
    if (events) {
      events.map((event) => {
        if (event.items) {
          event.items.forEach((item) => {
            if (type === "dept" && !item.is_hide_menu) {
              resultsArr[idx++] = event.department; //descJson.itemNature
            } else if (type === "itemNature" && !item.is_hide_menu) {
              if (_.isArray(item.item_nature)) {
                resultsArr = resultsArr.concat(item.item_nature);
              } else {
                resultsArr[idx++] = item.item_nature;
              }
            }
          });
        }
        return null;
      });
    }
    //Add ordering logic for itemNature
    resultsArr =
      resultsArr.length === 0
        ? resultsArr
        : resultsArr.filter((x, i, a) => x && a.indexOf(x) === i); //unique
    resultsArr = resultsArr.sort();
    if (resultsArr.includes("Others")) {
      resultsArr = resultsArr.filter((ele) => ele !== "Others");
      resultsArr.push("Others");
    }
    return resultsArr;
  }

  getDepartmentsSelection(events) {
    const depts = this.extractEvent(events, "dept");
    return depts === 0 ? (
      <></>
    ) : (
      depts.map((en) => <Option key={en}>{en}</Option>)
    );
  }

  getEventNatureSelection(events) {
    const itemNatures = this.extractEvent(events, "itemNature");
    return itemNatures === 0 ? (
      <></>
    ) : (
      itemNatures.map((en) => <Option key={en}>{en}</Option>)
    );
  }

  onChangeEventNature = (value) => {
    const { setAdvancedSearchState } = this.props;
    setAdvancedSearchState(value, "itemNature");
  };
  onChangeDept = (value) => {
    const { setAdvancedSearchState } = this.props;
    setAdvancedSearchState(value, "dept");
  };

  render() {
    const { events, advancedSearch, handleSearch } = this.props;
    return (
      <div key="search_container">
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon
              type={isActive ? "MinusSquareOutlined" : "PlusSquareOutlined"}
              style={iconStyle}
            />
          )}
          defaultActiveKey={["0"]}
          ghost
        >
          <Panel
            header={
              <span style={{ verticalAlign: "middle" }}>{label("LB0081")}</span>
            }
            key="0"
          >
            <Form
              labelCol={{ lg: { span: "5" }, md: { span: "7" }, span: "9" }}
            >
              <Form.Item label={label("LB0081")}>
                <AutoComplete
                  dropdownMatchSelectWidth={252}
                  className="storeSearchBox"
                  style={{ margin: "0" }}
                  // options={options}
                  // onSelect={onSelect}
                  onSearch={handleSearch}
                >
                  <Input
                    allowClear
                    className="storeSearchBoxInput"
                    prefix={
                      <Icon
                        type="SearchOutlined"
                        style={{ fontSize: "20px", color: ewcms.lightGrey }}
                      />
                    }
                    placeholder=""
                  />
                </AutoComplete>
              </Form.Item>
              {/* Event Nature */}
              <Form.Item label={label("LB0080")}>
                <Select
                  showSearch
                  allowClear
                  onChange={this.onChangeEventNature}
                  value={advancedSearch.itemNature}
                  placeholder={
                    <span style={{ fontSize: "13px" }}>{label("LB0080")}</span>
                  }
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  size="large"
                  mode="multiple"
                >
                  {this.getEventNatureSelection(events)}
                </Select>
              </Form.Item>
              {/* Department */}
              <Form.Item label={label("LB0079")}>
                <Select
                  value={advancedSearch.dept}
                  onChange={this.onChangeDept}
                  defaultValue="*"
                  size="large"
                >
                  <Option value="*" key="ALL">
                    All Departments
                  </Option>
                  {this.getDepartmentsSelection(events)}
                </Select>
              </Form.Item>
            </Form>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.session.permissions,
    language: state.dashboard.currLang,
    portalClient: state.portalClient,
    type: state.action.type,
    configData: state.codetable,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvanceSearchComponent);
