import "../../screen/EWCMS/Input/InputStyle.less";
import React from "react";
import { connect } from "react-redux";
import {
  Modal,
  Form,
  Button,
  Input,
  DatePicker,
  Select,
  Switch,
  Row,
  Col,
} from "antd";
import { label } from "../../../framework";
import * as ewcms from "../../screen/EWCMS/styleMaster";

const { TextArea } = Input;
const { Option } = Select;
const defaultBtnStyle = ewcms.defaultBtnStyle;

class EntityDataFormImpl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // TODO: to extend to more data type / scenario
  getValidationRules(col) {
    const rules = [];
    if (col.datatype === "email") {
      rules.push({
        type: "email",
        message: label("EC0004").replace("{%1}", label(col.label)),
      });
    }
    if (col.mandatory) {
      rules.push({
        required: true,
        message: label("EC0005").replace("{%1}", label(col.label)),
      });
    }
    if (col.datatype === "json") {
      rules.push({
        validator: (_, value) => {
          if (value === undefined || value === null || value.trim() === "") {
            return Promise.resolve();
          } else {
            try {
              JSON.parse(value);
              return Promise.resolve();
            } catch (e) {
              return Promise.reject(
                new Error(label("EC0012").replace("{%1}", col.datatype))
              );
            }
          }
        },
      });
    }
    return rules;
  }

  getCodeTableOptions(codetableName) {
    const { codetable } = this.props;
    if (codetable[codetableName]) {
      return codetable[codetableName].map((item) => (
        <Option key={item.key}>{item.name}</Option>
      ));
    } else {
      return "";
    }
  }

  renderFormItems() {
    const items = [];
    const { metadata } = this.props;
    //const { getFieldDecorator } = this.props.form;
    const cellSpan = 24;
    const noOfColumn = 2;
    const colSpan = Math.round(24 / noOfColumn);

    if (!metadata) {
      return;
    }

    Object.keys(metadata).forEach((colkey) => {
      const col = metadata[colkey];
      const isRequired = col.mandatory;
      const colLabel = isRequired ? (
        <div className="form-group__label">
          <span className="required-star">*</span>
          {label(col.label)}
        </div>
      ) : (
        <div className="form-group__label">{label(col.label)}</div>
      );

      if (!parseInt(col.editable)) {
        return;
      } else if (col.datatype === "decimal") {
        items.push(
          <Form.Item
            label={colLabel}
            key={col.key}
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={col.key}
            rules={this.getValidationRules(col)}
          >
            <Input className="form-control" />
          </Form.Item>
        );
      } else if (col.datatype === "boolean") {
        items.push(
          <Form.Item
            label={colLabel}
            key={col.key}
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={col.key}
            rules={this.getValidationRules(col)}
            valuePropName="checked"
          >
            <Switch checkedChildren={"Yes"} unCheckedChildren={"No"} />
          </Form.Item>
        );
      } else if (col.datatype === "date") {
        items.push(
          <Form.Item
            label={colLabel}
            key={col.key}
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={col.key}
            rules={this.getValidationRules(col)}
          >
            <DatePicker className="form-control" />
          </Form.Item>
        );
      } else if (col.datatype === "datetime") {
        items.push(
          <Form.Item
            label={colLabel}
            key={col.key}
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={col.key}
            rules={this.getValidationRules(col)}
          >
            <DatePicker
              showTime={{ format: "HH:mm:ss" }}
              format="YYYY-MM-DD HH:mm:ss"
              className="form-control"
            />
          </Form.Item>
        );
      } else if (col.datatype === "password") {
        items.push(
          <Form.Item
            label={colLabel}
            key={col.key}
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={col.key}
            rules={this.getValidationRules(col)}
          >
            <Input.Password visibilityToggle={false} className="form-control" />
          </Form.Item>
        );
      } else if (col.datatype === "codetable" && col.mode === "multiple") {
        items.push(
          <Form.Item
            label={colLabel}
            key={col.key}
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={col.key}
            rules={this.getValidationRules(col)}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
            >
              {this.getCodeTableOptions(col.codetable)}
            </Select>
          </Form.Item>
        );
      } else if (col.datatype === "codetable" && col.mode === "single") {
        items.push(
          <Form.Item
            label={colLabel}
            key={col.key}
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={col.key}
            rules={this.getValidationRules(col)}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              allowClear={true}
            >
              {this.getCodeTableOptions(col.codetable)}
            </Select>
          </Form.Item>
        );
      } else if (col.datatype === "textarea" || col.datatype === "json") {
        items.push(
          <Form.Item
            label={colLabel}
            key={col.key}
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={col.key}
            rules={this.getValidationRules(col)}
          >
            <TextArea
              className="text-area-form-control"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
        );
      } else {
        items.push(
          <Form.Item
            label={colLabel}
            key={col.key}
            labelCol={{ span: cellSpan }}
            wrapperCol={{ span: cellSpan }}
            required={false}
            name={col.key}
            rules={this.getValidationRules(col)}
          >
            <Input className="form-control" />
          </Form.Item>
        );
      }
    });
    items.push(
      <Form.Item key="version" name="version">
        <Input type="hidden" />
      </Form.Item>
    );
    return items.map((item, idx) => (
      <Col span={colSpan} key={idx}>
        {item}
      </Col>
    ));
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };
    //const { getFieldDecorator } = this.props.form;
    const {
      visible,
      mode,
      functionLabel,
      onCancel,
      onCreate,
      onUpdate,
      formRef,
      initialValues,
    } = this.props;

    return (
      <Modal
        title={
          (mode === "create" ? label("LB0009") : label("LB0004")) +
          " " +
          label(functionLabel)
        }
        visible={visible}
        onCancel={onCancel}
        width={1050}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={mode === "create" ? onCreate : onUpdate}
            className={defaultBtnStyle}
          >
            {mode === "create" ? label("LB0009") : label("LB0074")}
          </Button>,
          <Button key="back" onClick={onCancel} className={defaultBtnStyle}>
            {label("LB0006")}
          </Button>,
        ]}
      >
        <Form
          {...formItemLayout}
          labelAlign="left"
          colon={false}
          ref={formRef}
          initialValues={initialValues}
        >
          <Row gutter={24}>
            {/*getFieldDecorator('key', {})(<Input type="hidden" />)*/}
            <Form.Item key="key" name="key">
              <Input type="hidden" />
            </Form.Item>
            {this.renderFormItems()}
          </Row>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const entityName = state.dashboard.currentFunction.component_param;
  return {
    entityName: entityName, // eslint-disable-line object-shorthand
    codetable: state.codetable,
    functionLabel: state.dashboard.currentFunction.label,
    metadata: state.data.metadata[entityName],
  };
};

//const EntityDataForm = Form.create({ name: 'entityUpdate' })(EntityDataFormImpl);
export default connect(mapStateToProps, null)(EntityDataFormImpl);
