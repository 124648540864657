/* eslint-disable react/jsx-no-target-blank */
import "../../screen/EWCMS/Input/InputStyle.less";

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "../../shared/Icon";
import { logger, label } from "../../../framework";
import PurchaseSteps from "./PurchaseSteps";
import * as ewcms from "../EWCMS/styleMaster";
import AdvanceSearchComponent from "./AdvanceSearch";
import {
  Col,
  Row,
  Button,
  InputNumber,
  Input,
  Popconfirm,
  BackTop,
  Tooltip,
  PageHeader,
  Pagination,
  notification,
  List,
  Avatar, //ConfigProvider,
  Typography,
  Divider,
} from "antd";
import {
  entityListAction,
  handleCheckOut,
  navigateAction,
  routeToPageNotFoundAction,
} from "../../../redux/actions";
import _ from "lodash";
import { isSubset } from "../../../utility/isSubset";
import formatAmount from "../../../utility/formatAmount";

const { Text } = Typography;
// const { Search } = Input;

const fixMargin = 0;
// const defaultImgWidth = 100;
const defaultNumEachPage = 9;

const defaultBtnStyle = ewcms.defaultBtnStyle;
const lightGrey = ewcms.lightGrey;
const polyuColor = ewcms.polyuRed;

// const SEARCH_DEPT = 'dept';
// const SEARCH_EVNT_NATURE ='event_nature';

class Store extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /*The un-modified version of both lists*/
      itemList: [],
      storeList: [],
      /*The modified version of store lists*/
      filterStoreList: [],
      minValue: 0,
      maxValue: defaultNumEachPage,
      payerName: "",
      isBackToStepOne: false,
      isCardView: false,
      itemSearch: "",
      advancedSearch: {},
    };
  }

  navigateToShoppingCart = async () => {
    const { navigate, permissions } = this.props;
    navigate(
      permissions.find((func) => func.name === "shoppingCart"),
      "enUS"
    );
  };

  showErrorMsg = (message, description) => {
    const { navigate, permissions, unselectEvent } = this.props;
    notification.error({
      message: message,
      description: description,
    });
    navigate(
      permissions.find((func) => func.name === "event"),
      "enUS"
    );
    unselectEvent();
  };

  handleSearch = (value) => {
    //Only change the filter of a single array
    const target = value !== "" ? value.toLowerCase() : "";
    this.setState({ ...this.state, itemSearch: target });
  };
  /* Master Search [ End ] */
  /* Handle Items [Start] */
  defaultItems = async () => {
    const { configData } = this.props;
    let tempItemList = [];

    configData.geneventdao.map((eventObj) =>
      eventObj.items.map((item) =>
        tempItemList.push({ ...item, parent: eventObj })
      )
    ); //set all items from all events <-- default Items

    tempItemList = this.setItemList(tempItemList);
    const sL = this.setStoreItems(configData.geneventdao);

    this.setState({
      ...this.state,
      itemList: tempItemList,
      storeList: sL,
      advancedSearch: { dept: "*", itemNature: [] },
      filterStoreList: sL,
    });
  };
  //Setting Item List
  setItemList = (items) => {
    let tempItems = [];
    if (items.length > 0) {
      tempItems = [
        ...items.map((item) => {
          if (item.eventId) {
            //already transformed
            return item;
          }
          let itemT = "";
          if (_.isEmpty(item.parent)) {
            itemT = `${item.item_code}|${item.name}|${item.item_description}`;
          } else {
            itemT = `${item.item_code}|${item.name}|${item.item_description}|${item.parent.key}|${item.parent.event_code}|${item.parent.event_desc}`;
          }
          return {
            key: item.key,
            eventId: item.event_id,
            itemNature: item.item_nature,
            multiSelect: item.multi_select,
            itemId: item.key,
            itemCode: item.item_code,
            itemSubCode: item.item_subcode,
            itemName: item.name,
            isAmountEditable: item.is_amount_editable,
            enableRemark: item.enable_remark,
            quantity: item.default_quantity,
            amount: item.default_amount,
            itemRemarks: "",
            isHideMenu: item.is_hide_menu,
            itemTotal: 0,
            itemTag: itemT,
          };
        }),
      ].sort((a, b) => (a.itemCode ?? "").localeCompare(b.itemCode ?? ""));
    }
    return tempItems;
  };

  setStoreItems = (stores) => {
    let tempStore = [];
    if (stores.length > 0) {
      tempStore = stores.filter((store) => !_.isEmpty(store.items));
      tempStore = tempStore.map((store) => {
        let itemS = "";
        switch (true) {
          case store.items.length > 1:
            itemS += `${store.items.length} items|`;
            break;
          case store.items.length === 1:
            itemS += `${store.items.length} item|`;
            break;
          default:
            break;
        }
        itemS +=
          store.key +
          "|" +
          store.name +
          "|" +
          +store.department +
          "|" +
          store.event_nature +
          (store.event_desc ? store.event_desc : "");
        return {
          ...store,
          storeTag: itemS,
        };
      });
    }
    //Sort store
    tempStore = tempStore.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    return tempStore;
  };
  /* Handle Items [ End ] */

  setEvent = async (eventObj) => {
    const { selectEvent } = this.props;
    await selectEvent({
      eventId: eventObj.key,
      name: eventObj.name,
      paymentRefIdPrefix: eventObj.payment_ref_id_prefix,
      is_mand_email: eventObj.is_mand_email,
      is_mand_payer_name: eventObj.is_mand_payer_name,
      duplicate_payment_flag: eventObj.duplicate_payment_flag
        ? eventObj.duplicate_payment_flag
        : 0,
    });
  };

  renderToStepTwo = async (eventObj) => {
    await this.setEvent(eventObj);
    // await this.setItemList(eventObj.items);
  };

  rerenderFilteredList = () => {
    const { storeList, itemList, advancedSearch, itemSearch } = this.state;
    let filter_item = [];
    filter_item = itemList
      .filter((item) => !item.isHideMenu)
      .filter(
        (item) =>
          _.isEmpty(advancedSearch.itemNature) ||
          isSubset(item.itemNature, advancedSearch.itemNature)
      )
      .filter(
        (item) =>
          itemSearch === "" ||
          storeList
            .find((s) => s.key === item.eventId)
            .storeTag.toLowerCase()
            .trim()
            .includes(itemSearch.toLowerCase().trim()) ||
          item.itemTag
            .toLowerCase()
            .trim()
            .includes(itemSearch.toLowerCase().trim())
      );
    //Store stores in item for each stores;
    const filter_item_g = _.groupBy(filter_item, "eventId");
    const stores = storeList
      .filter(
        (store) =>
          advancedSearch.dept === "*" ||
          store.department.toLowerCase().trim() ===
            advancedSearch.dept.toLowerCase().trim()
      )
      .map((store) => {
        store.items = filter_item_g[store.key];
        return store;
      })
      .filter((store) => {
        return store.items;
      });
    this.setState({ ...this.state, filterStoreList: stores });
  };

  // Handle direct url
  componentDidMount = async () => {
    logger.getLogger("store.componentDidMount").debug("start");
    const {
      pathParam,
      searchParams,
      changeInfo,
      addRemarks,
      addToFixedCart,
      listRecord,
      csrf,
    } = this.props;
    //pathParam can be undefined if store is access from menu
    await this.defaultItems();
    if (pathParam !== undefined && Array.isArray(pathParam)) {
      const paramLen = pathParam.length; //len=1 > eventOnly, len=2 > event + item
      if (paramLen >= 1) {
        const eventId = pathParam[0].urlParamVal
          ? pathParam[0].urlParamVal
          : "";
        const eventTarget = this.state.storeList.find(
          (event) => event.key.toUpperCase() === eventId.toUpperCase()
        );
        if (!eventTarget) {
          //Change to invalid event id
          this.showErrorMsg(label("LBST004"), label("LBSC036"));
        } else {
          if (searchParams["name"] || searchParams["email"]) {
            changeInfo(
              searchParams["name"] ? searchParams["name"] : "",
              searchParams["email"] ? searchParams["email"] : ""
            );
          }
          if (searchParams["remarks"]) {
            if (eventTarget.duplicate_payment_flag === 0) {
              //Query all records
              listRecord("ordersdao", csrf, [
                [
                  "remarks",
                  `{"type": "string", "value": "${searchParams["remarks"]}"}`,
                ],
              ]);
            }
            if (new Blob([searchParams["remarks"]]).size <= 100) {
              addRemarks(searchParams["remarks"]);
            } else {
              notification.warn({
                message: label("LBSC037"),
                description: label("LBSC038").replace("{%1}", "100"),
              });
            }
          }
          const event = eventTarget;
          await this.renderToStepTwo(event);
          if (paramLen >= 2) {
            const itemCode = pathParam[1].urlParamVal
              ? pathParam[1].urlParamVal
              : "";
            let itemTarget = this.state.itemList.find((item) => {
              return item.itemCode === itemCode && event.key === item.eventId;
            });
            if (!itemTarget) {
              //Change to invalid item id
              this.showErrorMsg(label("LBST005"), label("LBSC036"));
            } else {
              //this.handleAddItemToCart(itemTarget);
              this.navigateToShoppingCart();
              const qty =
                searchParams["qty"] && itemTarget.multiSelect
                  ? parseInt(searchParams["qty"])
                  : itemTarget.quantity;
              const amt =
                searchParams["amt"] && itemTarget.isAmountEditable
                  ? parseFloat(searchParams["amt"])
                  : itemTarget.amount;
              notification.success({
                message: label("LBST001").replace("{%1}", itemTarget.itemName),
                description: label("LBST002").replace(
                  "{%1}",
                  formatAmount(qty * amt, false)
                ),
              });
              addToFixedCart({
                item: {
                  ...itemTarget,
                  quantity: qty,
                  amount: amt,
                },
                editAmount: searchParams["amt"] ? true : false, //editAmount
                editQty: searchParams["qty"] ? true : false,
              });
              //Disable item_remarks
              // if (searchParams["item_remarks"] && itemTarget.enableRemark) {
              //   addInfoRemarks({
              //     itemId: itemTarget.itemId,
              //     itemRemarks: searchParams["item_remarks"],
              //   });
              // }
            }
          }
        }
      }
    }
    this.handleSearch("");
  };

  componentDidUpdate(prevProps, prevState) {
    const { advancedSearch, itemSearch } = this.state;
    //Check change in both search or advancedSearch
    if (
      itemSearch !== prevState.itemSearch ||
      JSON.stringify(advancedSearch) !==
        JSON.stringify(prevState.advancedSearch)
    ) {
      //Check if event_nature is array, if is array
      this.rerenderFilteredList();
    }
  }

  // handleDirectNavigate=(currFunc, toFunc)=> {
  //   const { navigate, language, } = this.props;
  //   navigate(currFunc, toFunc, language);
  // }

  handlePageChange = (value) => {
    this.setState({
      minValue: (value - 1) * defaultNumEachPage,
      maxValue: value * defaultNumEachPage,
    });
  };

  handleAddItemToCart = (item) => {
    const { addToCart } = this.props;
    const key = `${item.itemId}_${item.amount}`;
    //label('LBST001').replace('{%1}',item.itemName)
    notification.success({
      message: label("LBST001").replace("{%1}", item.itemName),
      description: label("LBST002").replace(
        "{%1}",
        formatAmount(item.amount * item.quantity, false)
      ),
    });
    addToCart({ ...item, itemTotal: item.quantity * item.amount, key: key });
  };

  changeIsBackToStepOne = () => {
    this.setState({ isBackToStepOne: !this.state.isBackToStepOne });
  };
  onBackToStoreConfirm = () => {
    this.setState({ isBackToStepOne: !this.state.isBackToStepOne });
    const { currFunc, language, navigate } = this.props;
    //ONLY CALL NAVIGATE
    if (currFunc !== undefined) {
      const toFunc = currFunc;
      toFunc.path_param = [];
      navigate(toFunc, language);
    }
  };
  onSwitchView = () => {
    const { isCardView } = this.state;
    const resArr = [];
    let icon = (
      <Link
        key="switch_view"
        to={"#"}
        onClick={() => this.setState({ isCardView: !isCardView })}
      >
        {isCardView ? (
          <Tooltip title="Swith to List View">
            <Icon
              type="UnorderedListOutlined"
              style={{ color: polyuColor, fontSize: "25px" }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Swith to Card View">
            <Icon
              type={"AppstoreOutlined"}
              style={{ color: polyuColor, fontSize: "25px" }}
            />
          </Tooltip>
        )}
      </Link>
    );
    resArr.push(icon);
    return resArr;
  };

  getDisabledButton = (item) => {
    const { portalClient } = this.props;
    const cart = portalClient.selectedEvent.shoppingCart;
    if (!item.multiSelect) {
      for (const cart_item of cart) {
        if (cart_item.itemCode === item.itemCode && cart_item.quantity > 0) {
          return true;
        }
      }
    }
    return (
      item.quantity === null ||
      item.quantity === "" ||
      item.amount === null ||
      item.amount === ""
    );
  };

  getProductInputGroup = (item) => {
    const { itemList } = this.state;
    return (
      <Input.Group compact>
        {/* <InputNumber min={0} placeholder="QTY" size='small' value={item.quantity} precision={0}
          className='cust-button-height cust-vertical-center ' //style={{ width: '50%' }}
          onChange={(value) => {
            this.setState({ itemList: itemList.map(x => { if (x.itemId === item.itemId) { x.quantity = value; } return x; }) });
          }} /> */}
        {
          !item.isAmountEditable ? (
            <Text
              precision={2}
              style={{
                width: 80,
                textAlign: "right",
                padding: "5px 5px 0px 0px",
              }}
            >
              ${formatAmount(item.amount, false)}
            </Text>
          ) : (
            // <ConfigProvider direction="rtl">
            <InputNumber
              min={0}
              placeholder="AMT"
              size="small"
              value={item.amount}
              formatter={(value) => `$${value}`}
              precision={2}
              disabled={!item.isAmountEditable}
              className="cust-button-height cust-vertical-center"
              style={{ width: 80 }}
              onChange={(value) => {
                this.setState({
                  itemList: itemList.map((x) => {
                    if (x.itemId === item.itemId) {
                      x.amount = value;
                    }
                    return x;
                  }),
                });
              }}
            />
          )
          // </ConfigProvider>
        }
        <Button
          key={"addcart_" + item.itemCode}
          className={defaultBtnStyle + " cust-button-height"}
          disabled={this.getDisabledButton(item)}
          type={"primary"}
          onClick={() => {
            this.handleAddItemToCart(item);
          }}
        >
          {label("LBST003")}
          <Icon type="ShoppingCartOutlined" style={{ fontSize: "20px" }} />
        </Button>
      </Input.Group>
    );
  };

  getView = () => {
    const { isCardView, filterStoreList } = this.state;
    const { portalClient } = this.props;
    let items = [];
    if (filterStoreList && filterStoreList.length > 0) {
      const s_store = filterStoreList.find(
        (store) => store.key === portalClient.selectedEvent.eventId
      );
      if (s_store && s_store.items.length > 0) {
        items = this.setItemList(s_store.items);
      }
    }
    // itemList.filter(item => item.itemCode.toLowerCase().match(search[SEARCH_TEXT].toLowerCase()) != null || item.itemName.toLowerCase().match(search[SEARCH_TEXT].toLowerCase()) != null || search === "")
    if (items && items.length > 0) {
      return isCardView ? this.getCardView(items) : this.getListView(items);
    } else {
      //after filter
      return "No items found";
    }
  };

  getListView = (items) => {
    return (
      <List
        size="large"
        style={{ width: "95%" }}
        itemLayout="vertical"
        dataSource={items}
        pagination={{
          onChange: (page) => {
            /**/
          },
          pageSize: defaultNumEachPage,
        }}
        footer={
          <div>
            <b>{items.length}</b> item{items.length > 1 ? "s" : ""}
          </div>
        }
        renderItem={(item, index) => (
          <List.Item
            key={`${item.itemCode}_${item.itemName}`}
            actions={[this.getProductInputGroup(item)]}
            // extra={
            //   <a href="http://www.polyu.edu.hk/fo" target='_blank' style={{textAlign:'left'}}>
            //     <img
            //       width={defaultImgWidth}
            //       alt="logo"
            //       src='https://pbs.twimg.com/profile_images/459597748686180352/k84A15kV_400x400.jpeg'
            //     // src="https://www.polyu.edu.hk/ar/web/filemanager/template/common/images/icon-polyu.svg"
            //     /></a>
            // }
          >
            <List.Item.Meta
              // avatar={<Avatar src={item.avatar} />}
              avatar={
                index % 2 === 0 ? (
                  <a
                    href="http://www.polyu.edu.hk/fo"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textAlign: "left" }}
                  >
                    <Avatar
                      src={
                        "https://pbs.twimg.com/profile_images/459597748686180352/k84A15kV_400x400.jpeg"
                      }
                    />
                  </a>
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: ewcms.tagOrange,
                      verticalAlign: "middle",
                    }}
                    size="large"
                  >
                    {item.itemName.substring(0, 2)}
                  </Avatar>
                )
              }
              title={
                <div className="border-card-blk__title js-dot">
                  <a
                    href="http://www.polyu.edu.hk/fo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.itemCode}
                  </a>
                </div>
              }
              description={
                <div className="border-card-blk__title">{item.itemName}</div>
              }
            />
            {/* {'description hard code here'} */}
          </List.Item>
        )}
      />
    );
  };

  getCardView = (items) => {
    const { minValue, maxValue } = this.state;
    return (
      <>
        <Row>
          {items.slice(minValue, maxValue).map((item) => (
            <div
              className={"col-24 col-sm-12 col-md-8 col-lg-6"}
              key={"selectitem_resp_div_" + item.itemCode}
            >
              <div className="border-card-blk color-set--brown cust-card-padding">
                <div className="border-card-blk__title js-dot cust-vertical-center">
                  {item.itemCode}
                </div>
                <div
                  className="border-card-blk__text"
                  style={{ minHeight: "100px" }}
                >
                  {item.itemName}
                </div>
                {this.getProductInputGroup(item)}
              </div>
            </div>
          ))}
        </Row>
        <Row style={{ marginLeft: fixMargin }}>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={defaultNumEachPage}
            onChange={this.handlePageChange}
            total={items.length}
          />
        </Row>
      </>
    );
  };

  getStoreView = () => {
    const { filterStoreList } = this.state;
    if (filterStoreList && filterStoreList.length > 0) {
      return filterStoreList.map((eventObj) =>
        eventObj.items.length === 0 ? (
          ""
        ) : (
          <div
            className="col-24 col-sm-12 col-md-8 col-lg-6"
            key={"di_selectstore_card" + eventObj.key}
          >
            <Link
              to={this.props.currPath + "/" + eventObj.key}
              className="border-card-blk color-set--red"
              onClick={(e) => {
                this.renderToStepTwo(eventObj);
              }}
            >
              <p className="border-card-blk__title js-dot cust-vertical-center">
                <Icon type="ShoppingOutlined" /> &nbsp;{eventObj.name}
              </p>
              <p
                className="border-card-blk__text"
                style={{ margin: "0.75em 0" }}
              >
                {eventObj.key}
              </p>
              <p className="border-card-blk__text">
                {eventObj.items.length} item
                {eventObj.items.length > 1 ? "s" : ""}
              </p>
            </Link>
          </div>
        )
      );
    } else {
      return "No stores found";
    }
  };

  setAdvancedSearchState = (event, target) => {
    this.setState({
      ...this.state,
      advancedSearch: {
        ...this.state.advancedSearch,
        [target]: event,
      },
    });
  };

  render() {
    const { portalClient, unselectEvent, configData, currFunc } = this.props;
    const { /*itemList, mastSearchOpts, */ isBackToStepOne } = this.state;
    // logger.getLogger('render.mastSearchOpts').debug(mastSearchOpts.length);
    // logger.getLogger('render.itemList').debug(itemList.length);
    if (
      portalClient.selectedEvent === undefined ||
      _.isEmpty(portalClient.selectedEvent)
    ) {
      return (
        <>
          <PurchaseSteps current={0} status={"process"} />
          <AdvanceSearchComponent
            events={configData.geneventdao}
            setAdvancedSearchState={this.setAdvancedSearchState}
            advancedSearch={this.state.advancedSearch}
            handleSearch={this.handleSearch}
          />
          {/* Step 1: Choose Event (Store) */}
          <div key="di_selectstore">
            {/* Display to select Event card, show event when No. of items > 0 */}
            <Row style={{ textAlign: "left" }} key="di_selectstore_row">
              {this.getStoreView()}
            </Row>
          </div>
        </>
      );
    } else {
      /* Step 2 */
      /* Display to select Item Card */
      return (
        <>
          <PurchaseSteps
            current={1}
            status={"process"}
            //storeName={portalClient.selectedEvent.name}
          />
          <AdvanceSearchComponent
            events={configData.geneventdao}
            setAdvancedSearchState={this.setAdvancedSearchState}
            advancedSearch={this.state.advancedSearch}
            handleSearch={this.handleSearch}
          />
          {/* Header Page */}
          <Col
            span={24}
            style={{ marginLeft: fixMargin }}
            className="no-margin-padding"
          >
            <Popconfirm
              onConfirm={async () => {
                await unselectEvent();
                this.onBackToStoreConfirm();
                //await this.defaultItems(); // reset default itemList
              }}
              onCancel={this.changeIsBackToStepOne}
              open={isBackToStepOne}
              okButtonProps={{ className: defaultBtnStyle }}
              cancelButtonProps={{ className: defaultBtnStyle }}
              okText="Yes"
              cancelText="No"
              title={
                <span>
                  If you exit the page, shopping cart will be cleared. <br />
                  Are you sure to continue?
                </span>
              }
            >
              <div className="custom-divider-css">
                <Divider orientation="center" plain>
                  <div style={{ whiteSpace: "normal" }}>
                    {portalClient.selectedEvent.name}
                  </div>
                </Divider>
              </div>
              <PageHeader
                key="exit_store"
                onBack={this.changeIsBackToStepOne}
                style={{ alignItems: "center" }}
                backIcon={
                  <Icon
                    type="ArrowLeftOutlined"
                    style={{ fontSize: "25px", color: lightGrey }}
                  />
                }
                title={
                  <h4
                    onClick={this.changeIsBackToStepOne}
                    style={{ alignItems: "center", display: "flex" }}
                    className="selectCurosr"
                    key="regEvnt_selctEvnt"
                  >
                    &nbsp;<strong>Back to select {currFunc.name}</strong>
                  </h4>
                }
                extra={this.onSwitchView()}
              />
            </Popconfirm>
          </Col>

          {/* View */}
          {this.getView()}
          <BackTop className="topStyle">Top</BackTop>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  let pathParam = state.dashboard.currentFunction.path_param;
  return {
    pathParam: pathParam,
    permissions: state.session.permissions,
    configData: state.codetable,
    language: state.dashboard.currLang,
    currFunc: state.dashboard.currentFunction,
    currPath: state.dashboard.routeTo,
    searchParams: state.dashboard.searchParams,
    csrf: state.action.csrf,
    portalClient: state.portalClient,
    type: state.action.type,
    selectEvent: state.portalClient.selectEvent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  navigate: (toFunction, language) => {
    dispatch(navigateAction(toFunction, language));
  },
  selectEvent: (event) => {
    dispatch({ type: "SELECT_EVENT", payload: event });
  },
  unselectEvent: () => {
    dispatch({ type: "UNSELECT_EVENT" });
  },
  addToCart: (item) => {
    dispatch({ type: "ADD_TO_CART", payload: item });
  },
  addToFixedCart: (item) => {
    dispatch({
      type: "ADD_TO_CART_FIXED",
      payload: item,
    });
  },
  pageNotFound: () => {
    dispatch(routeToPageNotFoundAction());
  },
  changeInfo: (payerName, emailAddress) => {
    dispatch({
      type: "CHANGE_INFO",
      payload: { payerName: payerName, emailAddress: emailAddress },
    });
  },
  addRemarks: (remarks) => {
    dispatch({ type: "ADD_REMARKS", payload: remarks });
  },
  // addInfoRemarks: (item) => {
  //   dispatch({ type: "ADD_INFO_REMARKS", payload: item });
  // },
  handleCheckOut: () => {
    dispatch(handleCheckOut());
  },
  listRecord: (entityName, csrf, query) => {
    dispatch(entityListAction(entityName, csrf, query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Store);
