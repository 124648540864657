import React from "react";
import { connect } from "react-redux";
import { Icon } from "../../shared/Icon";
import { label, httpPost } from "../../../framework";
import * as ewcms from "../EWCMS/styleMaster";
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  // notification,
} from "antd";

const cellSpan = 24;
const defaultBtnStyle = ewcms.defaultBtnStyle;
const lightGrey = ewcms.lightGrey;
const polyuRed = ewcms.polyuRed;
const defaultTimerSec = 180;

class Otp extends React.Component {
  constructor(props) {
    super(props);
    this.optRef = React.createRef();
    this.formRef = React.createRef();
    this.state = {
      step: 0,
      otp: "",
      time: {},
      seconds: defaultTimerSec,
      otpVerifyCD: {},
      serverErr: {},
      otpErr: false,
      email: "",
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  pad2 = (n) => {
    return n < 10 ? "0" + n : n;
  };
  // ******************************************* Timer Area
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };
  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };
  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  };
  // ******************************************* Timer Area
  componentDidMount = () => {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({
      ...this.state,
      time: timeLeftVar,
    });
  };

  getValidationRules(datatype, colLabel, isMandatory, isValidateEmail) {
    let rules = [];
    if (datatype === "email") {
      rules.push({
        type: "email",
        message: label("EC0004").replace("{%1}", colLabel),
      });
      if (isValidateEmail) rules = this.addCheckEmailAddress(rules);
    }
    if (isMandatory) {
      rules.push({
        required: true,
        message: label("EC0005").replace("{%1}", colLabel),
      });
    }
    return rules;
  }

  addCheckEmailAddress(rules) {
    const { emailAddress, checkEmail } = this.props;
    if (checkEmail) {
      rules.push(({ _ }) => ({
        validator(_, value) {
          if (!value || emailAddress === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(label("EC0014")));
        },
      }));
    }
    return rules;
  }

  getCurrentIcon = (isCurr) => {
    return (
      <Icon
        type="ArrowRightOutlined"
        style={{ fontSize: 20, color: isCurr ? polyuRed : lightGrey }}
      />
    );
  };

  //Send Email Through This Callback
  sendEmail = async () => {
    const { email } = this.state;
    const response = await httpPost("/SendOTP", { email: email });
    this.setState({ otpVerifyCD: response, email: email });
  };

  verifyOTP = async () => {
    const { otpVerifyCD, otp, email } = this.state;
    const { setOtpInfo, setshowModal } = this.props;
    const response = await httpPost("/VerifyOTP", {
      email: email,
      hash: otpVerifyCD.hash,
      otp: otp,
    });
    if (response?.result === "EC9998") {
      this.setState({
        serverErr: response,
        otpErr: response?.result === "EC9998",
      });
    } else {
      this.setState({ step: 2 });
      setshowModal(false);
      //Call Prop function from parent
      setOtpInfo({ created_by: "gppuser", payer_email: email });
    }
  };

  handleInputOtp = async () => {
    //const {onOtpValidate} = this.props;
    //SET email
    this.setState({ email: this.formRef.current.getFieldValue("payeremail") });
    await this.sendEmail();
    this.setState({ step: 1 });
    this.startTimer();
  };
  handleResendOtp = async () => {
    this.timer = 0;
    this.setState({
      seconds: defaultTimerSec,
      time: this.secondsToTime(defaultTimerSec),
    });
    this.startTimer();
    await this.sendEmail();
  };
  render() {
    const { requiredName, requiredEmail } = this.props;
    const { step, otp, seconds, time } = this.state;

    let payerNameField = !requiredName ? (
      ""
    ) : (
      <Form.Item
        label={label("LBOT031")}
        key="payerName"
        labelCol={{ span: cellSpan }}
        wrapperCol={{ span: cellSpan }}
        required={false}
        name={"payerName"}
        className="border-cards-reverse"
        rules={this.getValidationRules(
          "string",
          label("LBOT031"),
          requiredName,
          false
        )}
      >
        <Input
          className="form-control"
          onBlur={(event) => {
            this.formRef.current?.setFieldsValue({
              payerName: event.target.value.trim(),
            });
          }}
        />
      </Form.Item>
    );

    let form;
    switch (step) {
      default:
        form = (
          <Form ref={this.formRef}>
            {payerNameField}
            <Form.Item
              label={label("LBOT032")}
              key="payeremail"
              labelCol={{ span: cellSpan }}
              wrapperCol={{ span: cellSpan }}
              required={false}
              name={"payeremail"}
              className="border-cards-reverse"
              validateTrigger="onSubmit" // validate  onSubmit only
              rules={this.getValidationRules(
                "email",
                label("LBOT032"),
                requiredEmail,
                true
              )}
            >
              <Input
                className="form-control"
                onBlur={(event) => {
                  this.formRef.current?.setFieldsValue({
                    payeremail: event.target.value.toLowerCase().trim(),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={label("LBOT033")}
              key="payeremailconfirm"
              labelCol={{ span: cellSpan }}
              wrapperCol={{ span: cellSpan }}
              required={false}
              name={"payeremailconfirm"}
              className="border-cards-reverse"
              validateTrigger="onSubmit" // validate onSubmit only
              rules={[
                ...this.getValidationRules(
                  "email",
                  label("LBOT033"),
                  requiredEmail,
                  false
                ),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && getFieldValue("payeremail") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two emails that you entered do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input
                className="form-control"
                onBlur={(event) => {
                  this.formRef.current?.setFieldsValue({
                    payeremailconfirm: event.target.value.toLowerCase().trim(),
                  });
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className={defaultBtnStyle + " cust-button-height"}
                type="primary"
                onClick={() => {
                  this.formRef.current
                    .validateFields()
                    .then((fieldsVal) => {
                      this.handleInputOtp();
                      // if (portalClient.selectedEvent !== undefined && portalClient.selectedEvent.shoppingCart !== undefined && portalClient.selectedEvent.shoppingCart !== null && portalClient.selectedEvent.shoppingCart.length > 0) {
                      //   this.handleInputOtp();
                      // } else {
                      //   notification.warning({ message: label('LBOT033'), description: label('LBOT034') });
                      // }
                    })
                    .catch((errorInfo) => {
                      console.log("errorInfo: ", errorInfo);
                    });
                }}
              >
                {label("LBOT035")}
              </Button>
            </Form.Item>
          </Form>
        );
        break;
      case 1:
        form = (
          <Form ref={this.optRef}>
            <div style={{ textAlign: "left", paddingLeft: 10 }}>
              {label("LBOT044")}
              <strong>{this.state.email}</strong>
            </div>
            <Form.Item
              label={label("LBOT039")}
              key="otp"
              labelCol={{ span: cellSpan }}
              wrapperCol={{ span: cellSpan }}
              required={true}
              name={"otp"}
              className="border-cards-reverse"
              rules={[
                ...this.getValidationRules(
                  "string",
                  label("LBOT039"),
                  true,
                  false
                ),
              ]}
            >
              <Input
                className="form-control"
                value={otp}
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({ otp: e.target.value });
                }}
              />
            </Form.Item>
            {this.state.otpErr && (
              <div
                className="cust-spacebetween"
                style={{
                  color: "red",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 10,
                }}
              >
                {this.state.serverErr.error}
              </div>
            )}
            <Form.Item>
              <div
                className="cust-spacebetween"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                {seconds > 0 ? (
                  <div>
                    {label("LBOT045")}
                    <strong style={{ color: polyuRed }}>
                      {this.pad2(time.m)}:{this.pad2(time.s)}
                    </strong>
                  </div>
                ) : (
                  <Button
                    className={defaultBtnStyle + " cust-button-height"}
                    type="primary"
                    onClick={() => this.handleResendOtp()}
                  >
                    {label("LBOT036")}
                  </Button>
                )}
                <Button
                  className={defaultBtnStyle + " cust-button-height"}
                  type="primary"
                  onClick={() =>
                    this.optRef.current.validateFields().then(this.verifyOTP())
                  }
                >
                  {label("LBOT037")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        );
        break;
    }
    return (
      <div className="border-card-blk color-set--blue cust-card-padding">
        <div
          className="border-card-blk__title js-dot cust-vertical-center cust-spacebetween"
          style={{ justifyContent: "start", gap: "2em", marginBottom: "10px" }}
        >
          {
            <Button
              icon={
                <Icon
                  type="ArrowLeftOutlined"
                  style={{
                    color: step > 0 ? polyuRed : lightGrey,
                    fontSize: "20px",
                  }}
                />
              }
              type="text"
              disabled={!(step > 0)}
              shape="circle"
              onClick={() => {
                this.setState({ step: this.state.step - 1 });
              }}
            />
          }
          <div>{label("LBOT001")}</div>
        </div>
        <div style={{ textAlign: "left", paddingBottom: 10 }}>
          <Row>
            <Col span={4}>{this.getCurrentIcon(step === 0)}</Col>
            <Col
              span={20}
              style={{ fontWeight: step === 0 ? "bold" : "normal" }}
            >
              {label("LBOT041")}
            </Col>
          </Row>
          <Row>
            <Col span={4}>{this.getCurrentIcon(step === 1)}</Col>
            <Col
              span={20}
              style={{ fontWeight: step === 1 ? "bold" : "normal" }}
            >
              {label("LBOT042")}{" "}
              {label("LBOT043")
                .replace("{%1}", label("LBOT039"))
                .replace("{%2}", label("LBOT037"))}
            </Col>
          </Row>
        </div>
        {form}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.session.permissions,
    language: state.dashboard.currLang,
    csrf: state.action.csrf,
    portalClient: state.portalClient,
    configData: state.codetable,
    type: state.action.type,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
