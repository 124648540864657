/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = { regEvent: [] }, action) => {
  let newState = state;
  switch (action.type) {
    case "LOGIN-SUCCESS": {
      newState = Object.assign({}, newState, { regEvent: [] });
      break;
    }
    //case 'AUTHENTICATE-SUCCESS': {
    case "LOGIN-ERROR":
    case "AUTHENTICATE-ERROR":
      newState = { regEvent: [] };
      break;
    case "LOGOUT-SUCCESS":
      newState = { regEvent: [] };
      break;
    //shopping cart
    //select Event
    case "SELECT_EVENT":
      newState = Object.assign({}, newState, {
        selectedEvent: {
          eventId: action.payload.eventId,
          name: action.payload.name,
          totalAmount: 0,
          remarks: newState?.selectedEvent?.remarks
            ? newState.selectedEvent.remarks
            : "",
          shoppingCart: [],
          payerName: newState?.selectedEvent?.payerName
            ? newState.selectedEvent.payerName
            : "",
          emailAddress: newState?.selectedEvent?.emailAddress
            ? newState.selectedEvent.emailAddress
            : "",
          paymentRefIdPrefix: action.payload.paymentRefIdPrefix,
          is_mand_email: action.payload.is_mand_email,
          is_mand_payer_name: action.payload.is_mand_payer_name,
          duplicate_payment_flag: action.payload.duplicate_payment_flag,
          ...newState.selectedEvent,
        },
      });
      break;

    case "UNSELECT_EVENT":
      newState = { ...newState, selectedEvent: undefined };
      break;
    case "NAVIGATE":
      // newState = { ...newState, selectedEvent: undefined }
      break;

    case "ADD_TO_CART":
      {
        const index = newState.selectedEvent.shoppingCart.findIndex(
          (x) =>
            x.itemId === action.payload.itemId &&
            x.amount === action.payload.amount
        );
        if (index === -1) {
          newState.selectedEvent.shoppingCart.push(action.payload);
          newState = {
            ...newState,
            selectedEvent: {
              ...newState.selectedEvent,
              totalAmount:
                newState.selectedEvent.totalAmount +
                action.payload.quantity * action.payload.amount,
            },
          };
        } else {
          const result = [...newState.selectedEvent.shoppingCart];
          result[index] = {
            ...result[index],
            quantity: result[index].quantity + action.payload.quantity,
            itemTotal:
              result[index].itemTotal +
              action.payload.quantity * action.payload.amount,
          };
          newState = {
            ...newState,
            selectedEvent: {
              ...newState.selectedEvent,
              totalAmount:
                newState.selectedEvent.totalAmount +
                action.payload.quantity * action.payload.amount,
              shoppingCart: result,
            },
          };
        }
      }
      break;
    case "ADD_TO_CART_FIXED":
      const { item, editAmount, editQty } = action.payload;
      const index = newState.selectedEvent.shoppingCart.findIndex(
        (x) => x.itemId === item.itemId
      );

      if (index !== -1) {
        const result = [...newState.selectedEvent.shoppingCart];
        result[index] = {
          ...result[index],
          quantity: item.quantity,
          amount: item.amount,
          itemTotal: item.quantity * item.amount,
          editAmount: editAmount,
          editQty: editQty,
        };
        newState = {
          ...newState,
          selectedEvent: {
            ...newState.selectedEvent,
            totalAmount: item.quantity * item.amount,
            shoppingCart: result,
          },
        };
      } else {
        newState.selectedEvent.shoppingCart.push({
          ...item,
          quantity: item.quantity,
          amount: item.amount,
          itemTotal: item.quantity * item.amount,
          editAmount: editAmount,
          editQty: editQty,
        });
        newState = {
          ...newState,
          selectedEvent: {
            ...newState.selectedEvent,
            totalAmount:
              newState.selectedEvent.totalAmount + item.quantity * item.amount,
          },
        };
      }
      break;
    case "UPDATE_CART_AMOUNT":
      const upIdx = newState.selectedEvent.shoppingCart.findIndex(
        (x) => x.key === action.payload.key
      );
      const result = [...newState.selectedEvent.shoppingCart];
      result[upIdx] = {
        ...result[upIdx],
        amount: action.payload.amount,
        itemTotal: action.payload.quantity * action.payload.amount,
      };
      newState = {
        ...newState,
        selectedEvent: {
          ...newState.selectedEvent,
          totalAmount: result.reduce((accu, curr) => accu + curr.itemTotal, 0),
          shoppingCart: result,
        },
      };
      break;

    case "DELETE_FROM_CART":
      newState = {
        ...newState,
        selectedEvent: {
          ...newState.selectedEvent,
          totalAmount:
            newState.selectedEvent.totalAmount -
            action.payload.quantity * action.payload.amount,
          shoppingCart: newState.selectedEvent.shoppingCart.filter(
            (x) =>
              !(
                x.itemId === action.payload.itemId &&
                x.amount === action.payload.amount
              )
          ),
        },
      };
      break;

    case "ADD_REMARKS":
      newState = {
        ...newState,
        selectedEvent: {
          ...newState.selectedEvent,
          remarks: action.payload,
        },
      };
      break;
    case "ADD_INFO_REMARKS":
      {
        const index = newState.selectedEvent.shoppingCart.findIndex(
          (x) => x.itemId === action.payload.itemId
        );
        if (index !== -1) {
          const result = [...newState.selectedEvent.shoppingCart];
          result[index] = {
            ...result[index],
            itemRemarks: action.payload.itemRemarks,
          };
          newState = {
            ...newState,
            selectedEvent: {
              ...newState.selectedEvent,
              shoppingCart: result,
            },
          };
        }
      }
      break;
    case "CHANGE_INFO":
      newState = {
        ...newState,
        selectedEvent: {
          ...newState.selectedEvent,
          payerName: action.payload.payerName,
          emailAddress: action.payload.emailAddress,
        },
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};
