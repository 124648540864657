/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = { metadata: undefined, data: {} }, action) => {
  let newState = state;
  switch (action.type) {
    case "AUTHENTICATE-SUCCESS": {
      const { entities } = action.payload.value;
      newState = Object.assign({}, newState, { metadata: entities });
      break;
    }
    case "NAVIGATE":
      newState = Object.assign({}, newState, { data: {} });
      break;
    case "DATA-LIST-SUCCESS":
      newState = Object.assign({}, newState, { data: action.payload.value });
      break;
    case "DATA-FILTER-SUCCESS":
      newState = Object.assign({}, newState, { data: action.payload.value });
      break;
    case "DATA-CREATE-SUCCESS":
    case "DATA-UPDATE-SUCCESS":
    case "DATA-DELETE-SUCCESS":
    case "OTP_LOGOUT":
      newState = Object.assign({}, newState, { data: {} });
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};
