import React from "react";
import { connect } from "react-redux";
import { label } from "../../../framework";
import { Icon } from "../../shared/Icon";
import * as ewcms from "../EWCMS/styleMaster";

import { Steps, Popover, Collapse } from "antd";

const { Step } = Steps;
const { Panel } = Collapse;

const mediumGrey = ewcms.mediumGrey;
const iconStyle = { fontSize: "15px", color: mediumGrey };

class PurchaseSteps extends React.Component {
  render() {
    const { current, status, portalClient } = this.props;

    const customDot = (dot, { status, index, title }) => (
      <Popover
        content={
          <span>
            Step {index + 1}: <strong>{title}</strong> - {status}
          </span>
        }
      >
        {dot}
      </Popover>
    );

    const isEmptyEvent =
      portalClient.selectedEvent !== undefined &&
      portalClient.selectedEvent.shoppingCart !== undefined
        ? false
        : true;
    const isEmptyCart =
      portalClient.selectedEvent !== undefined &&
      portalClient.selectedEvent.totalAmount !== undefined &&
      portalClient.selectedEvent.totalAmount !== null &&
      portalClient.selectedEvent.totalAmount > 0
        ? false
        : true;
    const currentVal = isEmptyCart ? 0 : current;
    return (
      <div key="step_container" className="d-none d-lg-block">
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) =>
            isActive ? (
              <Icon type="MinusSquareOutlined" style={iconStyle} />
            ) : (
              <Icon type="PlusSquareOutlined" style={iconStyle} />
            )
          }
          defaultActiveKey={["1"]}
          ghost
        >
          <Panel
            header={<span style={{ verticalAlign: "middle" }}>Steps:</span>}
            key="1"
          >
            <div className="absoluteCenter">
              <Steps
                current={currentVal}
                status={status}
                progressDot={customDot}
                size="default"
              >
                <Step
                  title={label("LBPS001").replace(
                    "{%1}",
                    current > 0 && !isEmptyEvent ? "ed" : ""
                  )}
                />
                <Step
                  title={label("LBPS003").replace(
                    "{%1}",
                    current > 1 && !isEmptyCart ? "ed" : ""
                  )}
                  // description=""
                />
                <Step
                  title={label("LBPS005").replace(
                    "{%1}",
                    current > 1 && !isEmptyCart ? "ed" : ""
                  )}
                  // description={label("LBPS006")}
                />
                <Step
                  title={label("LBPS007")}
                  // description={label("LBPS008")}
                />
                {/* <Step title={label("LBPS009")} description="" /> */}
              </Steps>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    portalClient: state.portalClient,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseSteps);
