import { store } from "./_ReduxHelper";
import logger from "./_Logger";
import * as langs from "../i18n";

const label = (id) => {
  const state = store.getState();
  const lang = state.session.lang ? state.session.lang : "en_US";
  if (langs[lang] && langs[lang][id]) {
    return langs[lang][id];
  } else {
    logger
      .getLogger("i18n-Util")
      .error("UNKNOWN LABEL ID: " + id + "@" + state.session.lang);
    return "*UNKNOWN LABEL*";
  }
};

export default label;
