import React from "react";
import { connect } from "react-redux";
import { label } from "../../../framework";
import Otp from "./Otp";
import { Modal } from "antd";

class OtpModal extends React.Component {
  render() {
    const {
      requiredName,
      requiredEmail,
      title,
      showModal,
      setshowModal,
      setOtpInfo,
      closable,
      checkEmail,
      emailAddress,
    } = this.props;

    return (
      <Modal
        title={title}
        open={showModal}
        onOk={() => setshowModal(false)}
        onCancel={() => setshowModal(false)}
        footer={null}
        closable={closable}
        maskClosable={closable}
      >
        <Otp
          requiredEmail={requiredEmail}
          requiredName={requiredName}
          title={label("LBSC006")}
          setshowModal={setshowModal}
          setOtpInfo={setOtpInfo}
          checkEmail={checkEmail}
          emailAddress={emailAddress}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.session.permissions,
    language: state.dashboard.currLang,
    csrf: state.action.csrf,
    portalClient: state.portalClient,
    configData: state.codetable,
    type: state.action.type,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OtpModal);
