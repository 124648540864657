import React from "react";
import { connect } from "react-redux";
import {
  /*history, logger, httpGet,*/ httpPost,
  label,
} from "../../../framework";
import { navigateAction, refreshCsrf } from "../../../redux/actions";
import * as ewcms from "../../screen/EWCMS/styleMaster";
import { Icon } from "../../shared/Icon";
import {
  //Descriptions,
  Table,
  //Row,
  Typography,
  Button,
  PageHeader,
  notification,
  Row,
  Col,
} from "antd";
import formatAmount from "../../../utility/formatAmount";
import OtpModal from "../OTP/OtpModal";

const { Text } = Typography;
//const { Title } = Typography;

const defaultBtnStyle = ewcms.defaultBtnStyle;
//const lightGrey = ewcms.lightGrey;

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        // title: 'code',
        title: "Item ID",
        dataIndex: "itemCode",
        key: "itemCode",
        width: "15%",
      },
      {
        // title: 'Item',
        title: "Item Name",
        dataIndex: "itemDesc",
        key: "itemDesc",
        width: "40%",
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        key: "quantity",
        width: "10%",
        align: "right",
      },
      {
        title: "Unit Price HK$",
        dataIndex: "unit_price",
        key: "unit_price",
        render: (value) => formatAmount(value, false),
        width: "15%",
        align: "right",
        responsive: ["sm"],
      },
      {
        // title: 'Amount',
        title: "HK$",
        dataIndex: "sub_total",
        key: "sub_total",
        render: (value) => formatAmount(value, false),
        width: "20%",
        align: "right",
      },
    ];
    this.state = {
      orderSummary: null,
      emailAddress: "",
      showModal: false,
      isCallback: true,
    };
  }

  setshowModal = (showModal) => {
    this.setState({ showModal: showModal });
  };

  renderTable = async () => {
    const { isSendEmail, currentFunction, currentOrderId, navigate } =
      this.props;
    await this.retrieveData();
    if (isSendEmail) {
      await this.handleSendEmail();
      //Remove path_param[1]
      const newFunc = {
        ...currentFunction,
        path_param: currentFunction.path_param.filter(
          (x) => x.urlParamName !== "isSendEmail"
        ),
        component_param: currentOrderId,
      };
      navigate(newFunc);
    }
  };

  componentDidMount = async () => {
    if (this.props.otpInfo.otpLogin || this.props.isCallback) {
      await this.renderTable();
    } else {
      await this.reteieveEmailAddress();
      this.state.isCallback = false;
      this.setshowModal(true);
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { showModal } = this.state;
    if (showModal !== prevState.showModal && !showModal) {
      await this.renderTable();
    }
  };

  convertDateString = (dateString) => {
    const pad2 = (n) => {
      return n < 10 ? "0" + n : n;
    };
    if (!dateString) {
      return "";
    }
    return (
      pad2(dateString.getDate()) +
      "-" +
      pad2(dateString.getMonth() + 1) +
      "-" +
      dateString.getFullYear().toString() +
      " " +
      pad2(dateString.getHours()) +
      ":" +
      pad2(dateString.getMinutes()) +
      ":" +
      pad2(dateString.getSeconds())
    );
  };

  retrieveOrderSummaryData = async () => {
    const { currentOrderId, refreshCsrf, csrf } = this.props;
    const orderId = currentOrderId;
    const response = await httpPost("/api/protected/order/getOrderSummary", {
      orderId,
      csrf,
    });
    const responseValue = response.value;
    if (!responseValue || !currentOrderId) {
      //Call fallback
      this.navigateToHome();
      return;
    }
    const paymentEnqRes = await httpPost("/api/protected/order/getTrans", {
      orderId: responseValue.order_id,
      eventId: responseValue.event_id,
      csrf: response.csrf,
    });
    await refreshCsrf();

    return {
      ...responseValue,
      transaction_date: paymentEnqRes.value.transactiontime
        ? this.convertDateString(
            new Date(Date.parse(paymentEnqRes.value.transactiontime))
          )
        : "",
      status:
        paymentEnqRes.value.status === ""
          ? responseValue.status
          : paymentEnqRes.value.status,
      transaction_id:
        paymentEnqRes.value.transactionid === ""
          ? responseValue.transaction_id
          : paymentEnqRes.value.transactionid,
      received_amount: responseValue.received_amount
        ? responseValue.received_amount
        : 0,
      paid_by:
        paymentEnqRes.value.paymentmethodname === ""
          ? responseValue.paid_by
          : paymentEnqRes.value.paymentmethodname,
    };
  };

  reteieveEmailAddress = async () => {
    const { currentOrderId, refreshCsrf, csrf } = this.props;
    const orderId = currentOrderId;
    const order = await httpPost("/api/protected/order/getOrder", {
      orderId,
      csrf,
    });
    await refreshCsrf();
    this.setState({ emailAddress: order.value.emailAddress });
  };

  navigateToEvent = async () => {
    const { navigate, permissions } = this.props;
    navigate(permissions.find((func) => func.name === "event"));
  };

  navigateToHome = async () => {
    const { navigate, permissions } = this.props;
    navigate(permissions);
  };

  retrieveData = async () => {
    const orderSummary = await this.retrieveOrderSummaryData();
    if (orderSummary === null) {
      this.navigateToHome();
    }
    this.setState({ orderSummary: orderSummary });
  };

  handleSendEmail = async () => {
    const response = await httpPost("/SendEmail", {
      ...this.state.orderSummary,
    });
    return response.message.status;
  };

  genExtra = () => {
    //const { isCallback } = this.state;
    const buttonArray = [];
    if (
      ["PAID", "DUPLICATED", "OVER", "UNDER"].includes(
        this.state.orderSummary.status
      )
    ) {
      buttonArray.push(
        <Button
          key="ordDet_print"
          className={defaultBtnStyle}
          size={"large"}
          onClick={() => window.print()}
        >
          Print
        </Button>
      );
      buttonArray.push(
        <Button
          key="ordDet_email"
          className={defaultBtnStyle}
          size={"large"}
          onClick={async () => {
            const response = await this.handleSendEmail();
            if (response === "success") {
              notification.success({
                message: "SUCCESS",
                description: "Email Sent",
              });
            }
          }}
        >
          E-Mail
        </Button>
      );
    } else {
      buttonArray.push(
        <Button
          key="ordDet_proPayment"
          className={defaultBtnStyle}
          size={"large"}
          onClick={this.handleProcessPayment}
        >
          Pay Again
        </Button>
      );
      //buttonArray.push(<Button onClick={async () => await httpPost("/order/checkout/callback")}>Testing</Button>)
    }
    // if (!isCallback) {
    //   buttonArray.push(
    //     <Button
    //       key="ordDet_refresh"
    //       className={defaultBtnStyle}
    //       size={"large"}
    //       onClick={this.navigateToEvent}
    //     >
    //       Return to Event
    //     </Button>
    //   );
    // }
    return buttonArray;
  };

  handleProcessPayment = async () => {
    const { orderSummary } = this.state;
    const request = {
      eventId: orderSummary.event_id,
      d: {
        type: "STATIC",
        referenceid: orderSummary.order_id,
        paymentmethod: {
          method: "polyu.olpp.*",
          amount: Math.round(orderSummary.total * 100),
          message: orderSummary.remarks,
        },
        callbackurl: process.env.REACT_APP_ORDER_CALLBACK_URL,
      },
    };
    if (orderSummary?.payerName) {
      request.d.payer_name = orderSummary.payerName;
    }
    if (orderSummary?.emailAddress) {
      request.d.payer_email = orderSummary.emailAddress;
    }

    await fetch("/order/checkout/paymentrequest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    }).then((res) => {
      // console.log(res)
      if (res.redirected) {
        window.location.href = res.url;
      }
    });
  };

  handleBack = () => {
    const { permissions, navigate } = this.props;
    navigate(permissions.find((func) => func.name === "enquiry"));
  };

  getValidationRules(datatype, colLabel, isMandatory) {
    const rules = [];
    if (datatype === "email") {
      rules.push({
        type: "email",
        message: label("EC0004").replace("{%1}", colLabel),
      });
    }
    if (isMandatory) {
      rules.push({
        required: true,
        message: label("EC0005").replace("{%1}", colLabel),
      });
    }
    return rules;
  }

  getCardRow = (caption, val) => {
    return (
      <div style={{ textAlign: "left" }} className="row">
        <div style={{ fontWeight: "bold" }} className="col-sm-8">
          {caption}
        </div>
        <div className="col-sm-16">{val}</div>
      </div>
    );
  };

  getStatusIcon = () => {
    const { orderSummary } = this.state;
    const iconSize = "50px";
    const paddingBottomSize = "20px";

    if (["PAID", "DUPLICATED", "OVER", "UNDER"].includes(orderSummary.status)) {
      return (
        <Icon
          type="CheckCircleTwoTone"
          twoToneColor="#52c41a"
          style={{ fontSize: iconSize, paddingBottom: paddingBottomSize }}
        />
      );
    } else if (["PENDING"].includes(orderSummary.status)) {
      return (
        <Icon
          type="WarningTwoTone"
          twoToneColor="#faad14"
          style={{ fontSize: iconSize, paddingBottom: paddingBottomSize }}
        />
      );
    } else {
      return (
        <Icon
          type="CloseCircleTwoTone"
          twoToneColor="#ff4d4f"
          style={{ fontSize: iconSize, paddingBottom: paddingBottomSize }}
        />
      );
    }
  };

  render() {
    const { functionIcon, functionLabel, setOtpInfo } = this.props;
    const { orderSummary } = this.state;

    //const cellSpan = 24;
    //const noOfColumn = 1;
    //const colSpan = Math.round(24 / noOfColumn);

    const title = (
      <h4
        onClick={this.handleBack}
        style={{ alignItems: "center", display: "flex" }}
        level={4}
        className="selectCurosr"
        key="phh4_ordDets"
      >
        <Icon type={functionIcon} />
        &nbsp;<strong>{label(functionLabel)}</strong>
      </h4>
    );

    const totalAmountVal =
      orderSummary !== undefined &&
      orderSummary !== null &&
      orderSummary.total !== undefined &&
      orderSummary.total !== null
        ? "HK" + formatAmount(orderSummary.total)
        : "0.00";

    return (
      <div
        className="row border-cards__row"
        // key={"od_" + orderSummary.order_id}
      >
        {orderSummary && (
          <>
            <PageHeader
              onBack={this.handleBack}
              style={{ alignItems: "center" }}
              //   backIcon={<Icon type='ArrowLeftOutlined' style={{ fontSize: '25px', color:lightGrey }} />}
              backIcon={false}
              title={title}
              extra={this.genExtra()}
            />

            {/* Input Field */}
            <div className="col-24 col-sm-24 col-lg-12">
              <div
                className="border-card-blk color-set--brown"
                //style={{ margin: "0.1em 0 !important" }}
              >
                <h4 style={{ marginBottom: "2.5rem" }}>{label("LBSC006")}</h4>
                {this.getCardRow("Payer Name", orderSummary.payerName)}
                {this.getCardRow("Email Address", orderSummary.emailAddress)}
                <div style={{ textAlign: "justify", marginTop: "1rem" }}>
                  <b>Note:</b>
                </div>
                <div style={{ textAlign: "justify", marginTop: "0.5rem" }}>
                  With the above email address, payer can receive informations
                  of the payment by clicking the “E-Mail” button above and
                  retrieve the transaction at the Enquiry function.
                </div>
              </div>
            </div>
            {/* Display Order Information*/}
            <div className="col-24 col-sm-24 col-lg-12">
              <div className="border-card-blk color-set--red">
                {this.getStatusIcon()}
                {orderSummary.event_title &&
                  this.getCardRow("Event", orderSummary.event_title)}
                {orderSummary.ccpInvoiceNo &&
                  this.getCardRow("Invoice No", orderSummary.ccpInvoiceNo)}

                {this.getCardRow("Payment Reference", orderSummary.order_id)}
                {this.getCardRow("Payment Method", orderSummary.paid_by)}
                {this.getCardRow("Status", orderSummary.status)}
                {this.getCardRow("Transaction ID", orderSummary.transaction_id)}
                {this.getCardRow(
                  "Transaction Date",
                  orderSummary.transaction_date
                )}
                {this.getCardRow(
                  "Payment Amount",
                  "HK" + formatAmount(orderSummary.received_amount ?? 0)
                )}
                {orderSummary.remarks &&
                  this.getCardRow("Department Reference", orderSummary.remarks)}
              </div>
            </div>

            {/* Table */}
            <div className="col-24 col-sm-24 col-lg-24">
              <Table
                pagination={false}
                style={{ paddingBottom: 10 }}
                columns={this.columns}
                dataSource={orderSummary.details}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <Row>
                        <Col span={4}>
                          <Text strong>{`Item Remarks`}</Text>
                        </Col>
                        <Col span={20}>
                          <Text>{`${record.item_remarks}`}</Text>
                        </Col>
                      </Row>
                    );
                  },
                  rowExpandable: (record) =>
                    record.item_remarks && record.item_remarks !== "",
                }}
                summary={(pageData) => {
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={3}>
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={3} className="rAlign">
                        <Text strong className="underline--double">
                          {totalAmountVal}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </div>
          </>
        )}
        {/* OTP Modal */}
        <OtpModal
          requiredName={false}
          requiredEmail={true}
          title={"Order Enquiry Verification"}
          showModal={this.state.showModal}
          setshowModal={this.setshowModal}
          setOtpInfo={setOtpInfo}
          closable={false}
          checkEmail={true}
          emailAddress={this.state.emailAddress}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentOrderId:
      state.dashboard.currentFunction?.path_param?.[0]?.urlParamVal,
    isSendEmail:
      state.dashboard.currentFunction?.path_param?.length > 1 &&
      state.dashboard.currentFunction?.path_param[1]?.urlParamVal === "Y",
    csrf: state.action.csrf,
    permissions: state.session.permissions,
    functionIcon: state.dashboard.currentFunction.icon,
    currentFunction: state.dashboard.currentFunction,
    functionLabel: state.dashboard.currentFunction.label,
    type: state.action.type,
    otpInfo: state.session.otpInfo,
    isCallback: state.dashboard.searchParams["callback"] === "Y",
  };
};

const mapDispatchToProps = (dispatch) => ({
  navigate: (toFunction) => {
    dispatch(navigateAction(toFunction, "enUS"));
  },
  refreshCsrf: () => {
    dispatch(refreshCsrf());
  },
  setOtpInfo: (otpInfo) => {
    dispatch({ type: "OTP_LOGIN", payload: { otpInfo: otpInfo } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
