import "./Dashboard.less";
import React from "react";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import { Icon } from "../../shared/Icon";
import {
  label,
  // logger
} from "../../../framework";
import {
  navigateAction,
  routeToPageNotFoundAction,
} from "../../../redux/actions";
import MainContent from "./MainContent";
import { Header, Footer, Banner } from "../EWCMS/Layout";
import MainMenu from "./MainMenu";
import HeaderBar from "./HeaderBar";

class Dashboard extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (props.result !== "0" && !props.loading) {
      notification.error({
        message: "Error",
        description: label(props.result),
        onClose: () => props.reset(),
      });
    } else if (props.action === "DATA-CREATE-SUCCESS") {
      notification.success({
        message: "SUCCESS",
        description: label("LB0013"),
      });
    } else if (props.action === "DATA-UPDATE-SUCCESS") {
      notification.success({
        message: "SUCCESS",
        description: label("LB0014"),
      });
    } else if (props.action === "DATA-DELETE-SUCCESS") {
      notification.success({
        message: "SUCCESS",
        description: label("LB0015"),
      });
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.match.params.lang,
      functName: "",
      currWidthOption: {
        key: label("screen.dashboard.headerbar.viewoption.best"),
        value: "container-best",
      },
      currBannerOption: {
        key: label("screen.dashboard.headerbar.banneroption.simple"),
        value: "simple",
      },
    };
  }

  isJson = (str) => {
    try {
      const result = JSON.parse(str);
      return result;
    } catch (e) {
      return false;
    }
  };

  componentDidMount() {
    const { navigate, location, permissions, /*configData*/ pageNotFound } =
      this.props;

    // const queryStrPageId = this.props.match.params.pageId;
    // const queryStrEventId = this.props.match.params.eventId;
    // const queryStrItemId = this.props.match.params.itemId;
    // // function code = FN1001_store
    // logger.getLogger('Dashboard.componentDidMount').debug(`pageId=${queryStrPageId}, eventId=${queryStrEventId}, itemId=${queryStrItemId}, lang=${this.state.lang}`);

    // if (queryStrPageId) { // find PageId page

    // }

    var func = undefined;
    // determine function
    const requestFunction = location.pathname
      .split("/")
      .filter((item) => item && item.length > 0);
    // requestFunction[0] = lang
    // requestFunction[1] = dashboard
    // requestFunction[x] = function/{subfunctions||queryString}...

    let i = 0;
    const lang = requestFunction[i++];
    const funcLen = requestFunction.length - 1;
    const langs = ["enus", "zhhk", "zhcn"];

    // logger.getLogger('requestFunction').debug(requestFunction);
    // logger.getLogger('funcLen').debug(funcLen);

    if (!langs.includes(lang.toLowerCase())) {
      pageNotFound();
    } else {
      if (requestFunction[i] === "portal") {
        // requestFunction[1] = dashboard
        if (funcLen >= 2) {
          for (let j = 2; j <= funcLen; j++) {
            // eslint-disable-next-line no-loop-func
            const funcInstance = permissions.filter(
              (item) => item.name === requestFunction[j]
            )[0];
            /**
             * if requestFunction[j]=queryString value, funcInstance=undefined.
             * func = last requestFunction's param
             */
            if (funcInstance) {
              func = funcInstance;
            }
            if (
              func !== undefined &&
              func.function_search_dao_field !== undefined &&
              func.function_search_dao_field !== null &&
              this.isJson(func.function_search_dao_field) &&
              JSON.parse(func.function_search_dao_field).type === "urlparams"
            ) {
              /**
               * i.e. /{lang}/dashboard/func/subfunc/sub-subfunc/{urlPararms01}/{urlParams02}...
               * funcLen       = (7-1) = 6
               * func.name     = sub-subfunc
               * j             = 4
               * noOfPathParams= min(2, 2)
               */
              try {
                const jsonObj = JSON.parse(func.function_search_dao_field);
                const noOfPathParams = Math.min(funcLen - j, jsonObj.size);

                if (noOfPathParams > 0) {
                  // Query String Action
                  let inArray = [];
                  const parmArr = jsonObj.params;
                  for (var pos = 0; pos < noOfPathParams; pos++) {
                    inArray.push({
                      urlParamName: parmArr[pos].paramName,
                      urlParamVal: requestFunction[j + pos + 1],
                    });
                  }
                  func = { ...func, path_param: inArray };
                }
              } catch (err) {
              } finally {
                break;
              }
            }
          }
        }
      }
      const searchParams = new URLSearchParams(location.search);
      if (!func || func.length === 0) {
        navigate(permissions, lang, searchParams);
      } else {
        func.subfunc.length > 0
          ? navigate(func.subfunc, lang, searchParams)
          : navigate(func, lang, searchParams);
      }

      if (func !== undefined && func.label !== undefined) {
        this.setState({ functName: func.label });
      } else {
        this.setState({ functName: "Dashboard" });
      }
    }
  }

  onSwitchWidth = (e, opt) => {
    this.setState({ currWidthOption: opt });
  };
  onSwitchBanner = (e, opt) => {
    this.setState({ currBannerOption: opt });
  };

  render() {
    // logger.getLogger('dashbaord.render.lang').debug(this.state.lang);
    const defaultPage = label("FN0000");
    const { loading, currentFunction } = this.props;
    const bannerLabel =
      currentFunction === undefined || currentFunction === null
        ? defaultPage
        : currentFunction.label === undefined
        ? defaultPage
        : label(currentFunction.label);
    const antIcon = (
      <Icon type="LoadingOutlined" style={{ fontSize: 24 }} spin />
    );

    const mobileIcon = (
      <div className="mTrigger mobile-menu-wrap  d-block d-lg-none">
        <MainMenu
          mode="mobile"
          className="mTrigger mobile-menu-wrap d-block d-lg-none"
        />
      </div>
    );
    const banner =
      this.state.currBannerOption.value === "default" ? (
        <Banner image="/assets/img/temp/title_banner.png" name={bannerLabel} />
      ) : (
        ""
      );
    return (
      <Spin
        spinning={loading}
        size="large"
        tip={label("LB0001")}
        indicator={antIcon}
      >
        <Header
          mobileIcon={mobileIcon}
          barMenu={
            <HeaderBar
              onSwitchWidth={this.onSwitchWidth}
              onSwitchBanner={this.onSwitchBanner}
            />
          }
        />
        <MainMenu mode="horizontal" />
        {banner}
        <main className="page-content" tabIndex="-1">
          <div className={this.state.currWidthOption.key}>
            <div className="dashboard-ig">
              <MainContent
                currWidthOption={this.state.currWidthOption}
                currBannerOption={this.state.currBannerOption}
                bannerLabel={bannerLabel}
              />
            </div>
          </div>
        </main>

        <Footer />
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.dashboard.loadingCount !== 0,
    configData: state.codetable,
    permissions: state.session.permissions,
    action: state.action.type,
    result: state.action.result,
    currentFunction: state.dashboard.currentFunction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reset: () => {
    dispatch({ type: "RESET-RESULT" });
  },
  navigate: (toFunctions, lang, searchParams) => {
    dispatch(navigateAction(toFunctions, lang, searchParams));
  },
  pageNotFound: () => {
    dispatch(routeToPageNotFoundAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
