// UI Label (en_US)
const label = {
  "home.app.title": "PolyU Online Payment Portal",
  "home.app.desc": "v2021.09.25",
  "home.btn.admin": "Admin Portal",
  "home.btn.counter": "Counter Portal",
  "screen.dashboard.topmenu.title": "PolyU Online Payment Portal",
  "screen.dashboard.headerbar.viewoption.default": "Default view",
  "screen.dashboard.headerbar.viewoption.full": "Full view",
  "screen.dashboard.headerbar.viewoption.best": "Best view",
  "screen.dashboard.headerbar.banneroption.simple": "Simple banner",
  "screen.dashboard.headerbar.banneroption.default": "Default banner",
  "dashboard.maincontent.footer":
    "Copyright © 2021 The Hong Kong Polytechnic University. All Rights Reserved.  Powered by ITS",
  "eta.list.col.netid": "User Net ID",
  "eta.list.col.eventid": "Event ID",
  "eta.list.col.name": "User Name",
  "eta.list.col.email": "Email Address",
  "eta.list.col.dept": "User Department",
  "eta.list.col.efffm": "Valid Start Date",
  "eta.list.col.effto": "Valid End Date",
  "eta.list.col.role": "User Roles",
  "eta.list.col.event": "Registered Events",
  "eta.list.col.rolecd": "Role Code",
  "eta.list.col.rolename": "Role Name",
  "eta.list.col.payername": "Name of Payer",
  "eta.list.col.payeremail": "Payer Email",
  "eta.list.col.createdtime": "Creation Date",
  "eta.list.col.modifiedtime": "Last Modification Date",
  "eta.list.col.createdby": "Created By",
  "eta.list.col.modifiedby": "Last Modified By",
  "eta.list.col.unitprice": "Unit Price",
  "eta.list.col.seq": "Sequence",
  "eta.list.col.ftnname": "Function Name",
  "eta.list.col.ftncd": "Function Code",
  "eta.list.col.ftnicon": "Function Icon",
  "eta.list.col.ftncomp": "Function Comp.",
  "eta.list.col.ftncompparm": "Function Comp. Param.",
  "eta.list.col.ftngrp": "Function Group",
  "eta.list.col.permission": "Permission",
  "eta.list.col.entityid": "Entity",
  "eta.list.col.label": "Label",
  "eta.list.col.typeid": "Type",
  "eta.list.col.typeparam": "Parameter",
  "eta.list.col.sortable": "Sortable",
  "eta.list.col.searchable": "Searchable",
  "eta.list.col.editable": "Editable",
  "eta.list.col.mandatory": "Mandatory",
  "eta.list.col.orderId": "Order ID",
  "eta.list.col.orderDetailId": "Order Detail ID",
  "eta.list.col.itemId": "Item ID",
  "eta.list.col.itemName": "Item Name",
  "eta.list.col.itemCode": "Item Code",
  "eta.list.col.itemSubcode": "Item Subcode",
  "eta.list.col.itemDesc": "Item Description",
  "eta.list.col.itemtotal": "Item Total",
  "eta.list.col.itemacccode": "Item Account Code",
  "eta.list.col.defaultAmount": "Default Amount",
  "eta.list.col.defaultQuantity": "Default Quantity",
  "eta.list.col.isAmountEditable": "Is Amount Editable?",
  "eta.list.col.effperfm": "Effective Period From",
  "eta.list.col.effperto": "Effective Period To",
  "eta.list.col.orderStatus": "Order Status",
  "eta.list.col.remarks": "Remarks",
  "eta.list.col.depRef": "Department Reference",
  "eta.list.col.totalAmount": "Total Amount",
  "eta.list.col.amount": "Amount",
  "eta.list.col.quantity": "Quantity",
  "eta.list.col.version": "Version",
  "eta.list.col.functionAction": "Function Action",
  "eta.list.col.mtnPG_acct.acctId": "Account",
  "eta.list.col.isDetail": "Is Detail?",
  "eta.list.col.txnTime": "Transaction Date & Time",
  "eta.list.col.mtnPG_acct.codeName": "Journal Posing ID",
  "eta.list.col.mtnPG_acct.codeDesc": "Journal Posting Description",
  "eta.list.col.mtnPG_acct.type": "Journal Posting Type",
  "eta.list.col.mtnPG_acct.codeDefin": "Posting Account Codes",
  "eta.list.col.mtnPG_sp.spId": "Service Provider",
  "eta.list.col.mtnPG_sp.spAbbrv": "Service Provider Abbreviation",
  "eta.list.col.mtnPG_sp.spName": "Service Provider",
  "eta.list.col.mtnPG_sp.spContractStartDate": "Valid Start Date",
  "eta.list.col.mtnPG_sp.spContractEndDate": "Valid End Date",
  "eta.list.col.mtnPG_pm.pmId": "Payment Method ID",
  "eta.list.col.mtnPG_pm.pmCode": "Payment Method ID",
  "eta.list.col.mtnPG_pm.pmSPId": "Service Provider",
  "eta.list.col.mtnPG_pm.pmName": "Payment Method",
  "eta.list.col.mtnPG_pm.pmEffectFrom": "Valid Start Date",
  "eta.list.col.mtnPG_pm.pmEffectTo": "Valid End Date",
  "eta.list.col.mtnPG_pm.pmIsActive": "Is Active",
  "eta.list.col.mtnPG_pm.pmMinAmt": "Min. Payment Amount (HKD)",
  "eta.list.col.mtnPG_pm.pmMaxAmt": "Max. Payment Amount (HKD)",
  "eta.list.col.mtnPG_pm.pmPostType": "Posting Type",
  "eta.list.col.mtnPG_pm.pmCostType": "Bank Charge Type (FIX/FLEX)",
  "eta.list.col.mtnPG_pm.pmCostRate": "Charging Rate",
  "eta.list.col.mtnPG_pm.pmCostAmt": "Fixed Txn Fee (HKD)",
  "eta.list.col.mtnPG_pm.pmRfCostType": "Refund Cost Type (FIX/FLEX)",
  "eta.list.col.mtnPG_pm.pmRfCostRate": "Refund Cost Rate",
  "eta.list.col.mtnPG_pm.pmRfCostAmt": "Fixed Refund Fee (HKD)",
  "eta.list.col.mtnPG_mtnce.mtnid": "Maintenance",
  "eta.list.col.mtnPG_mtnce.pmId": "Payment Method Code",
  "eta.list.col.mtnPG_mtnce.periodFrom": "Suspension Period From",
  "eta.list.col.mtnPG_mtnce.periodTo": "Suspension Period To",
  "eta.list.col.mtnPG_mtnce.msg": "Message",
  "eta.list.col.mtnPG_unit.untId": "Unit",
  "eta.list.col.mtnPG_unit.untTitle": "Source System Abbreviation",
  "eta.list.col.mtnPG_unit.untDesc": "Source System Name",
  "eta.list.col.mtnPG_evt.evtId": "Event ID",
  "eta.list.col.mtnPG_evt.untId": "Source System Abbreviation",
  "eta.list.col.mtnPG_evt.evtCode": "Event Code",
  "eta.list.col.mtnPG_evt.evtTitle": "Event Title",
  "eta.list.col.mtnPG_evt.evtDesc": "Event Misc. Data",
  "eta.list.col.mtnPG_evt.pmtRefIdPrefix": "Payment Ref ID Prefix",
  "eta.list.col.mtnPG_evt.duplicatePaymentFlag": "Duplicate Payment Allowed",
  "eta.list.col.mtnPG_evt.Location": "Location",
  "eta.list.col.mtnPG_evt.evtEffectFrom": "Event Start Date",
  "eta.list.col.mtnPG_evt.evtEffectTo": "Event End Date",
  "eta.list.col.mtnPG_evt.isActive": "Active Event Status",
  "eta.list.col.mtnPG_evt.isMandatoryPayerName": "Payer Name Required",
  "eta.list.col.mtnPG_evt.isMandatoryPayerEmail": "Payer Email Required",
  "eta.list.col.mtnPG_evt.evtApprovers": "Approved By",
  "eta.list.col.mtnPG_evt.evtApprovDate": "Approval Date",
  "eta.list.col.mtnPG_evt.department": "Department",
  "eta.list.col.mtnPG_evt.contactPerson": "Contact Person",
  "eta.list.col.mtnPG_evt.contactEmail": "Contact Email",
  "eta.list.col.mtnPG_evt.contactTelephone": "Contact Telephone",
  "eta.list.col.mtnPG_evt.eventNature": "Nature of Payment",
  "eta.list.col.mtnPG_evt.eventType": "Event Type",
  "eta.list.col.mtnPG_evt.createdby": "Event Created By",
  "eta.list.col.mtnPG_evt.createdtime": "Event Creation Date",
  "eta.list.col.mtnPG_evt.modifiedby": "Last Modified By",
  "eta.list.col.mtnPG_evt.modifiedtime": "Last Modification Date",
  "eta.list.col.mtnPG_evtPM.evtPMId": "Event Payment Method",
  "eta.list.col.mtnPG_evtPM.pmId": "Payment Method Name",
  "eta.list.col.mtnPG_evtPM.evtId": "Event ID",
  "eta.list.col.mtnPG_evtPM.isReqPosting": "Journal Posting Required",
  "eta.list.col.mtnPG_evtPM.costOwner": "Bank Charges Borne By",
  "eta.list.col.mtnPG_evtPM.costPostingAcctId":
    "Journal Posting ID (Bank Charges)",
  "eta.list.col.mtnPG_evtPM.postingAcctId": "Journal Posting ID (Income)",
  "eta.list.col.mtnPG_evtPM.duplicatedPostingAcctId":
    "Journal Posting ID (Duplicated)",
  "eta.list.col.mtnPG_evtPM.rfdDuplicatedPostAcctId":
    "Journal Posting ID (Refund Duplicated)",
  "eta.list.col.mtnPG_evtPM.rfdPostAccId": "Journal Posting ID (Refund)",
  "eta.list.col.mtnPG_evtPM.minAmt": "Min. Payment Amount (HKD)",
  "eta.list.col.mtnPG_evtPM.maxAmt": "Max. Payment Amount (HKD)",
  "eta.list.col.mtnPG_evtPM.effectFrom": "Payment Method Start Date",
  "eta.list.col.mtnPG_evtPM.effectTo": "Payment Method End Date",
  "eta.list.col.mtnPG_evtPM.isActive": "Active Payment Method Status",
  "eta.list.col.mtnPG_itm.itmId": "Item",
  "eta.list.col.mtnPG_itm.evtTitle": "Event Title",
  "eta.list.col.mtnPG_itm.evtId": "Event ID",
  "eta.list.col.mtnPG_itm.itmName": "Item Name",
  "eta.list.col.mtnPG_itm.itmCode": "Item ID",
  "eta.list.col.mtnPG_itm.itmSubCode": "Item Subcode",
  "eta.list.col.mtnPG_itm.itm_desc": "Item Description",
  "eta.list.col.mtnPG_itm.defaultAmt": "Default Amount (HKD)",
  "eta.list.col.mtnPG_itm.defaultQty": "Default Quantity",
  "eta.list.col.mtnPG_itm.isAmtEditable": "Edit Amount Enabled",
  "eta.list.col.mtnPG_itm.effectFrom": "Payment Item Start Date",
  "eta.list.col.mtnPG_itm.effectTo": "Payment Item End Date",
  "eta.list.col.mtnPG_itm.postAcctId": "Journal Posting ID (Item Income)",
  "eta.list.col.mtnTran_pr.tranId": "Transaction ID",
  "eta.list.col.mtnTran_pr.evtId": "Event ID",
  "eta.list.col.mtnTran_pr.evtTitle": "Event Title",
  "eta.list.col.mtnTran_pr.pmId": "Payment Method",
  "eta.list.col.mtnTran_pr.evtPmId": "Event Payment Method",
  "eta.list.col.mtnTran_pr.pmMsg": "Remarks",
  "eta.list.col.mtnTran_pr.pmReqData": "Payment Request Data",
  "eta.list.col.mtnTran_pr.pmRefId": "Payment Reference",
  "eta.list.col.mtnTran_pr.status": "Current Status",
  "eta.list.col.mtnTran_pr.callbackURL": "Callback URL",
  "eta.list.col.mtnTran_pr.prAmt": "Requested Amount (HK$)",
  "eta.list.col.mtnTran_pr.txnTime": "Transaction Date & Time",
  "eta.list.col.prDetail.txnId": "Transaction ID",
  "eta.list.col.prDetail.evtId": "Event ID",
  "eta.list.col.prDetail.evtTitle": "Event Title",
  "eta.list.col.prDetail.prId": "Payment Reference",
  "eta.list.col.prDetail.prAmount": "Payment Request Amount (HK$)",
  "eta.list.col.prDetail.txnAmount": "Total Payment Amount (HK$)",
  "eta.list.col.prDetail.detPayMeth": "Payment Method",
  "eta.list.col.prDetail.txnDetType": "Transaction Detail Type",
  "eta.list.col.prDetail.txnDetAmount": "Transaction Detail Amount",
  "eta.list.col.prDetail.txnTime": "Transaction Date & Time",
  "eta.list.col.prDetail.txnStatus": "Transaction Status",
  "eta.list.col.prDetail.prevStatus": "Previous Status",
  "eta.list.col.prDetail.currStatus": "Current Status",
  "eta.list.col.posting.jpId": "Journal Posting ID",
  "eta.list.col.posting.jpGroupid": "Journal Posting Group ID",
  "eta.list.col.posting.evtId": "Event ID",
  "eta.list.col.posting.payRefId": "Payment Reference",
  "eta.list.col.posting.postTime": "Posting Time",
  "eta.list.col.posting.status": "Current Status",
  "eta.list.col.posting.fileName": "File Name",
  "eta.list.col.posting.postDtlId": "Journal Posting Details ID",
  "eta.list.col.posting.btn_refund": "Refund",
  "eta.list.col.posting.refund_confirmTittle": "Confirm to Refund",
  "eta.list.col.posting.file_gen_date": "File Generation Date",
  "eta.list.col.posting.txn_id": "Transaction ID",
  "eta.list.col.posting.txn_time": "Transaction Time",
  "eta.list.col.posting.gl_account_code": "GL Account Code",
  "eta.list.col.posting.gl_entered_dr": "GL Amount (DR)",
  "eta.list.col.posting.gl_entered_cr": "GL Amount (CR)",
  "eta.list.col.function_search_dao_field": "Function Search Dao Field",
  "eta.list.col.view_detail": "View Detail",
  "eta.list.col.mtnTran_pr_ex.tran_set_id": "Transaction Settlement ID",
  "eta.list.col.mtnTran_pr_ex.misc_data": "Misc Data",
  "eta.list.col.mtnTran_pr_ex.given_unique_id": "Given Unique ID",
  "eta.list.cot.ord.txnBy": "Transaction Processed By",
  "eta.list.cot.ord.dept": "Dept.",
  "eta.list.col.ord.itemAcc": "Item Income A/C",
  "eta.list.col.ord.qty": "Qty",
  "eta.list.col.ord.unitPrice": "Unit Price (HK$)",
  "eta.list.col.ord.itemTotal": "Payment Item Amount (HK$)",
  "eta.list.col.decode.key": "Key",
  "eta.list.col.decode.code": "Code",
  "eta.list.col.decode.value": "Value",
  "eta.list.col.decode.description": "Description",
  "eta.list.col.decode.parentDecodeId": "Parent Decode Id",
  "eta.list.col.decode.isActive": "Is Active",
  "eta.list.col.ccp_invoice_no": "Invoice No",

  LB0001: "Loading",
  LB0002: "Search",
  LB0102: "Filter",
  LB0202: "Select",
  LB0003: "Reset",
  LB0004: "Edit",
  LC0004: "Detail",
  LB0005: "Delete",
  LB0006: "Cancel",
  LB0007: "Confirm to delete",
  LB0008: "Confirm",
  LB0009: "Add",
  LB0010: "Logout",
  LB0011: "English",
  LB0012: "中文(繁體)",
  LB0013: "Create Success",
  LB0014: "Update Success",
  LB0015: "Delete Success",
  LB0016: "Input {%1}",
  LR0116: "from",
  LR0216: "to",
  LR0126: "From DD-MM-YYYY",
  LR0226: "To DD-MM-YYYY",
  LB0017: "Name",
  LB0018: "Email",
  LB0019: "Password",
  LB0020: "Birthday",
  LB0021: "Roles",
  LB0022: "Create Time",
  LB0023: "Update Time",
  LB0024: "Label ID",
  LB0025: "Icon",
  LB0026: "Component",
  LB0027: "Parameter",
  LB0028: "Function Group",
  LB0029: "Permission",
  LB0030: "Action",
  LB0031: "Sequence",
  LB0032: "Entity",
  LB0033: "Type",
  LB0034: "Parameter",
  LB0035: "Sortable",
  LB0036: "Searchable",
  LB0037: "Editable",
  LB0038: "Mandatory",
  LB0039: "Email Login",
  LB0040: "Login using NetID",
  LB0041: "Forget Password",
  LB0042: "Register",
  LB0043: "ReactJS Reference Implementations",
  LB0044: "Web Application Powered By Ant Design React",
  LB0045: "or Login with Email",
  LB0046: "Confirm Password",
  LB0047: "What do you want others to call you?",
  LB0048: "Back",
  LB0049: "I have read the ",
  LB0050: "agreement",
  LB0051: "User Registration",
  LB0052: "Upload",
  LB0053: "Register Success",
  LB0054: "中文(简体)",
  LB0055: "Decode ID",
  LB0056: "D Type",
  LB0057: "D Key",
  LB0058: "D Code",
  LB0059: "D Value",
  LB0060: "Description",
  LB0061: "Sequence",
  LB0062: "Parent Decode ID",
  LB0063: "Effective From",
  LB0064: "Effective To",
  LB0065: "Is Active",
  LB0066: "Created By",
  LB0067: "Updated By",
  LB0068: "Refresh",
  LB0069: "Donation ID",
  LB0070: "Donation Amount",
  LB0071: "Purpose of Donation",
  LB0072: "Project/ Programme/ Activity",
  LB0073: "Type Of Donation",
  LB0074: "Save",
  LB0075:
    "For enquiries or assistance, please contact Vivien Yu (Cashier Office of FO) at 3400 3728.",
  LB0076: "Remarks",
  LB0077: "Your Cart is Empty",
  LB0079: "Filter by Department",
  LB0080: "Filter by Nature",
  LB0081: "Search",
  LB0082:
    "To retrieve the transaction, please enter the email address that was used for the payment.",
  LBTrue: "Y",
  LBFalse: "N",

  //Label - Notice
  LBSN001: "Go To Event",

  // Label - Store
  LBST001: "{%1} added to cart",
  LBST002: "Total Amount (HK$):{%1}",
  LBST003: "Add to Cart",
  LBST004: "Event Not Found",
  LBST005: "Item Not Found",

  // Label - Shopping Cart
  LBSC001: "Your Cart is Empty",
  LBSC002: "Order Confirmation",
  LBSC003: "Check Out",
  LBSC004: "Edit",
  LBSC005: "Total",
  LBSC006: "Contact Information",
  LBSC007: "Proceed to payment",
  LBSC011: "Item ID",
  LBSC012: "Item",
  LBSC013: "Qty",
  LBSC014: "Price\n(HK$)",
  LBSC015: "Subtotal\n(HK$)",
  LBSC016: " ",
  LBSC021: "Minus",
  LBSC022: "Add",
  LBSC023: "Remove {%1}",
  LBSC024: "Remove {%1}?",
  LBSC025: "Update amount",
  LBSC033: "Tips",
  LBSC034: "Please add at least one item to the shopping cart.",
  LBSC035: "Error: invalid department reference",
  LBSC036: "Please contact administrator",
  LBSC037: "Remarks not added",
  LBSC038: "Maximum numbers words of {%1} exceeded",

  // Label - OTP,
  LBOT001: "One Time Password",
  LBOT031: "Name of Payer (Full Name for Official Receipt)",
  LBOT032: "Email Address",
  LBOT033: "Confirm Email Address",
  LBOT034: "The two emails that you entered do not match!",
  LBOT035: "Continue",
  LBOT036: "Resend OTP",
  LBOT037: "VERIFY",
  LBOT038:
    "A OTP (One Time Passcode) has been sent to {%1}.\nPlease enter the OTP in the textbox below to verify.",
  LBOT039: "One Time Password",
  LBOT040: "Reset Email",
  LBOT041:
    "Please enter the email address used for the payment to receive OTP (one-time passwords).",
  LBOT042:
    "An email with a confirmation code will be sent to that email address.",
  LBOT043:
    "Please enter that confirmation code on the '{%1}' textbox and Press '{%2}'.",
  LBOT044: "Please check ",
  LBOT045: "Resend OTP in ",

  // Purchase Step
  LBPS001: "Select{%1} an event",
  LBPS002: " ",
  LBPS003: "Add{%1} item(s)",
  LBPS004: " ",
  LBPS005: "Check{%1} out",
  LBPS006: "Input and verify email address",
  LBPS007: "Payment",
  LBPS008: "Online payment",
  LBPS009: "Finish",
  LBPS010: " ",

  // Function Label
  FN0000: "Notice",
  FN0001: "Access Administration",
  FN0002: "User Maintenance",
  FN0003: "User Role",
  FN0004: "Function",
  FN0005: "APM",
  FN0006: "Model Definition",
  FN0007: "Model",
  FN0008: "Model Attribute",
  FN0009: "AADO Decode",
  FN0010: "Manage Donation",
  FN0011: "View Donation",
  FN0012: "AADO Donation",
  FN0013: "FPS Income Collection",
  FN0014: "Registered Event(s)",
  FN0015: "Order Enquiry",
  FN0016: "Order Details Enquiry",
  FN0017: "Order Enquiry",
  FN0018: "View Event Details",
  FN0019: "View Payment Item Details",
  FN0020: "Order Summary",
  FN0021: "System Configuration",
  FN0040_mtnPG: "Gateway Administration",
  FN0041_mtnPG_acct: "Account Code",
  FN0042_mtnPG_sp: "Service Provider",
  FN0043_mtnPG_pm: "Payment Method",
  FN0044_mtnPG_mtnce: "Notification of Maintenance",
  FN0045_mtnPG_unit: "Source System",
  FN0046_mtnPG_evt: "Event",
  FN0047_mtnPG_evtPM: "Event Payment Method",
  FN0048_mtnPG_itm: "Payment Item",
  FN0050_transaction: "Transaction Enquiry",
  FN0051_trans_enquiry: "Transaction Summary",
  FN0052_trans_dtlEnq: "Transaction Detail",
  FN0053_postEnq: "Journal Enquiry",
  FN0054_mtnTran: "Maintain Transaction",
  FN0070_sys: "System Administrator",
  FN0071_sys_wsLog: "Call Log",
  FN0054_trans_except: "Exception",
  FN0101_decodes: "Decodes",
  FN1001_store: "Event",
  FN1002_shoppingCart: "Cart",
  FN1003_enquiry: "Enquiry",

  //Function Action
  FN0054_ACT01_refund: "Refund",
  FN0054_ACT02_refresh: "Refresh",

  // Error (Client Side)
  "Cookies.Error": "Cookies Error",
  EC9999: "Internal system error. Please contact administrator",
  EC9998: "Network connection error. Please contact administrator",
  EC0001: "Email is required",
  EC0002: "Invalid email format",
  EC0003: "Password is required",
  EC0004: "Invalid format: {%1}",
  EC0005: "{%1} is required",
  EC0006: "Name is requiredyy",
  EC0007: "Confirm password is required",
  EC0008: "Please input birthday",
  EC0009: "Two passwords that you enter is inconsistent",
  EC0010: "Please accept agreement",
  EC0011: "You are not authorized to login, Please contact administrator",
  EC0012: "Invalid {%1} format",
  EC0013: "Service unavailable",
  EC0014:
    "Please confirm that the email address was the one used for the payment.",
  // Error (Server Side)
  E00001: "NetID and NetPassword is required",
  E00002: "NetID and NetPassword does not match",
  E00003: "System error (function not available)",
  E00004: "Create record error",
  E00005: "Delete record error",
  E00006: "Update record error",
  E00007: "List record error",
  E00008: "Record does not exist",
  E00009: "Record updated by others",
  E00010: "You are not authorized to login, Please contact administrator",
  E99997: "Session is invalid (csrf error), Please login again",
  E99998: "Session is invalid or timed out, Please login again",
  E99999: "Internal system error. Please contact administrator",
};

export default label;
