import "./index.less";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Switch, Redirect, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// components
import { PageNotFound, Dashboard } from "./components";

// framework
import {
  history,
  logger,
  redux,
  PublicRoute,
  InitRoute,
  // ProtectedRoute
} from "./framework";

// application
const App = () => (
  <Provider store={redux.getStore()}>
    <Router history={history}>
      <Switch>
        {/* language: enUS, zhHK, zhCN */}
        {/* Default language: en-us */}

        <Route path="/" exact>
          <Redirect to="/enUS/portal" />
        </Route>
        <InitRoute
          path="/:lang(enUS|zhHK|zhCN)?/portal/"
          component={Dashboard}
        />
        <PublicRoute component={PageNotFound} />
      </Switch>
    </Router>
  </Provider>
);

if (
  process.env.NODE_ENV !== "development" &&
  typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object"
) {
  for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    if (prop === "renderers") {
      // this line will remove that one console error
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
    } else {
      // Replace all of its properties with a no-op function or a null value
      // depending on their types
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
        typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === "function"
          ? () => {}
          : null;
    }
  }
}

logger.getLogger("init").info(process.env.NODE_ENV + "");
ReactDOM.render(App(), document.getElementById("root"));
