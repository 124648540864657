import "./MainMenu.less";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "../../shared/Icon";
import * as ewcms from "../../screen/EWCMS/styleMaster";
import { Menu, Dropdown } from "antd";

import {
  label,
  // ,logger'
} from "../../../framework";
import { navigateAction } from "../../../redux/actions";
import _ from "lodash";

const { SubMenu } = Menu;

const lightGrey = ewcms.lightGrey;
const menuAlign = "flex-start";
const mobileMenuAlign = "flex-end";

class MainMenu extends React.Component {
  /**
   * mode: horizontal, vertical, inline
   * @param {m} props
   */
  constructor(props) {
    super(props);
    this.mode = props.mode;
  }

  onClick = (e) => {
    const { permissions, navigate, language } = this.props;
    let toFunction = permissions;
    if (e.key !== "home") {
      toFunction = permissions.filter(
        (item) => item.key.toString() === e.key
      )[0];
    }
    navigate(toFunction, language);
  };

  render() {
    const { permissions, mode, currentFunction } = this.props;
    const modeVal =
      mode !== undefined &&
      (mode === "vertical" || mode === "inline" || mode === "mobile")
        ? mode
        : "horizontal";

    const functionKey =
      !currentFunction || _.isArray(currentFunction)
        ? "home"
        : currentFunction.key.toString();

    const menuItems = permissions.map((func) => {
      const _title = (
        <div
          className="mn__link--1 theme-color-text-hover cust-vertical-center"
          style={{ justifyContent: menuAlign }}
        >
          {/*<Icon type={func.icon} />*/}
          {label(func.label)}
        </div>
      );
      if (func.is_detail) {
        return null;
        // return '';    // not necessary to return empty which generate incorrect menu
      } else if (!func.parent_uid && func.subfunc.length > 0) {
        return (
          <SubMenu
            key={func.key}
            title={_title}
            className="mn__item--1 has-sub"
            style={{ justifyContent: menuAlign }}
            expandIcon=" "
          >
            {func.subfunc.map((subfunc) => {
              return (
                <Menu.Item
                  key={subfunc.key}
                  className="modified-ant-menu-item"
                  id={`menu_item_subfunc_${subfunc.key}`}
                >
                  <div className="mn-sm__link--2 theme-color-text-hover cust-vertical-center">
                    {/*<Icon type={subfunc.icon} />*/}
                    {label(subfunc.label)}
                  </div>
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      } else if (!func.parent_uid) {
        return (
          <Menu.Item
            key={func.key}
            className="modified-ant-menu-item"
            id={`menu_item_${func.key}`}
          >
            {/*_title*/}
            <span
              className="mn__link--1 theme-color-text-hover cust-vertical-center"
              style={{ marginLeft: "15px", justifyContent: menuAlign }}
            >
              {/*<Icon type={func.icon} />*/}
              {label(func.label)}
            </span>
          </Menu.Item>
        );
      } else {
        return null;
        // return '';    // not necessary to return empty which generate incorrect menu
      }
    });

    const mobileMenuItems = permissions.map((func) => {
      const _title = (
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "250px",
            alignItems: "center",
          }}
        >
          <Icon
            type="LeftOutlined"
            style={{ fontSize: "10px", color: lightGrey }}
          />
          <div
            className="mn__link--1 theme-color-text-hover cust-vertical-center"
            style={{ justifyContent: mobileMenuAlign }}
          >
            {/*<Icon type={func.icon} />*/}
            {label(func.label)}
          </div>
        </div>
      );
      if (func.is_detail) {
        return null;
      } else if (!func.parent_uid && func.subfunc.length > 0) {
        return (
          <SubMenu
            key={func.key}
            title={_title}
            className="mn__item--1 has-sub"
            style={{ justifyContent: mobileMenuAlign }}
            expandIcon=" "
          >
            {func.subfunc.map((subfunc) => {
              return (
                <Menu.Item
                  key={subfunc.key}
                  className="modified-ant-menu-item"
                  id={`menu_item_mobile_subfunc_${subfunc.key}`}
                >
                  <div className="mn-sm__link--2 theme-color-text-hover cust-vertical-center rAlign">
                    {/*<Icon type={subfunc.icon} />*/}
                    {label(subfunc.label)}
                  </div>
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      } else if (!func.parent_uid) {
        return (
          <Menu.Item
            key={func.key}
            className="modified-ant-menu-item"
            id={`menu_item_mobile_${func.key}`}
          >
            <span
              className="mn__link--1 theme-color-text-hover cust-vertical-center"
              style={{ paddingRight: "28px", justifyContent: mobileMenuAlign }}
            >
              {/*<Icon type={func.icon} />*/}
              {label(func.label)}{" "}
            </span>
          </Menu.Item>
        );
      } else {
        return null;
      }
    });

    const menu = (
      <Menu
        mode={modeVal}
        onClick={this.onClick}
        selectable={false}
        selectedKeys={[functionKey]}
      >
        <Menu.Item
          key="home"
          className="modified-ant-menu-item"
          id="menu_item_FN0000"
        >
          <span className="mn__link--1 theme-color-text-hover cust-vertical-center">
            {/*<HomeOutlined />*/}
            {label("FN0000")}{" "}
          </span>
        </Menu.Item>
        {menuItems}
      </Menu>
    );
    if (modeVal === "horizontal") {
      return (
        <div className="header-bottom d-none d-lg-block">
          {/* <div className="header-bottom d-none d-lg-block"> */}{" "}
          {/* <div className="d-lg-block"> */}
          <div className="container header-bottom__container">
            <div className="mn--fact color-set--red">
              {/* <div className="mn mn--fact color-set--red" */}{" "}
              {/* <div className="mn--fact color-set--red"> */}
              {menu}
            </div>
          </div>
        </div>
      );
    } else if (modeVal === "mobile") {
      const mobileMenu = (
        <Menu
          onClick={this.onClick}
          selectable={false}
          selectedKeys={[functionKey]}
        >
          <Menu.Item
            key="home"
            className="modified-ant-menu-item"
            id="menu_item_mobile_FN0000"
          >
            <span
              className="mn__link--1 theme-color-text-hover cust-vertical-center"
              style={{ paddingRight: "28px", justifyContent: mobileMenuAlign }}
            >
              {/*<HomeOutlined />*/}
              {label("FN0000")}{" "}
            </span>
          </Menu.Item>
          {mobileMenuItems}
        </Menu>
      );

      return (
        <Dropdown overlay={mobileMenu} trigger={["click"]}>
          <Link
            to={"#"}
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <Icon type="MenuOutlined" />
          </Link>
        </Dropdown>
      );
    } else {
      return menu;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.dashboard.currLang,
    permissions: state.session.permissions,
    currentFunction: state.dashboard.currentFunction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  navigate: (toFunction, language) => {
    dispatch(navigateAction(toFunction, language));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
