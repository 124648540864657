import React from "react";
import { connect } from "react-redux";
import { Icon } from "../../shared/Icon";
import { label } from "../../../framework";
import * as ewcms from "../EWCMS/styleMaster";
import PurchaseSteps from "./PurchaseSteps";
import {
  Col,
  Row,
  Button,
  Popconfirm,
  Tooltip,
  Typography,
  Empty,
  List,
  Avatar,
  Divider,
  InputNumber,
  Alert,
  Input,
} from "antd";
import EmailModal from "./Modal/EmailModal";
import { handleCheckOut } from "../../../redux/actions";
import formatAmount from "../../../utility/formatAmount";
import { navigateAction } from "../../../redux/actions";

const { Text } = Typography;
const numEachPage = 8;
// const fixMargin = 0;
// const CollapseSize = 5;
// const cellSpan = 24;

const defaultBtnStyle = ewcms.defaultBtnStyle;
const lightGrey = ewcms.lightGrey;
//const polyuColor = ewcms.polyuRed;

const defaultNumEachPage = 9;
const { TextArea } = Input;

class ShoppingCart extends React.Component {
  rowClasses = (record, index) =>
    (index + 1) % 2 ? "sc-single-row" : "sc-double-row";

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isEditable: true,
      search: "",
      minValue: 0,
      maxValue: numEachPage,
      isBackToEvent: false,
      cardSideWidth: 11,
      shoppingCartWidth: 12,
      isCollapseCardSide: true,
    };
  }

  componentDidMount = async () => {
    this.setState({ isEditable: true });
  };

  navigateToEvent = () => {
    const { navigate, permissions } = this.props;
    navigate(
      permissions.find((func) => func.name === "event"),
      "enUS"
    );
  };

  // handleNavigate = (e) => {
  //   const { navigate, permissions, language } = this.props;
  //   let toFunction = permissions;
  //   if (e.key !== 'home') { toFunction = (e.item.subfunc && e.item.subfunc.length > 0) ? e.item.subfunc : e.item; }
  //   navigate(toFunction, e.item, language);
  // }
  // handleNavigateToStore = () => {
  //   const { permissions, language, navigate } = this.props;
  //   const storeFunc = permissions.filter(perm => perm.label === 'FN1001_store');
  //   const toFunc = (storeFunc.length > 0) ? storeFunc[0] : permissions;
  //   navigate(toFunc, toFunc, language);
  // }

  handleDelete = (record) => {
    const { deleteFromCart } = this.props;
    deleteFromCart(record);
  };

  getValidationRules(datatype, colLabel, isMandatory) {
    const rules = [];
    if (datatype === "email") {
      rules.push({
        type: "email",
        message: label("EC0004").replace("{%1}", colLabel),
      });
    }
    if (isMandatory) {
      rules.push({
        required: true,
        message: label("EC0005").replace("{%1}", colLabel),
      });
    }
    return rules;
  }

  handleEditShoppingCard = () => {
    this.setState({ isEditable: !this.state.isEditable });
  };

  getQuantity = (record) => {
    const { portalClient, addToCart } = this.props;
    const text = record.quantity;

    function enableEditQuantity(record) {
      if (!record.multiSelect || record.editQty) {
        return false;
      }
      return true;
    }
    if (this.state.isEditable) {
      const minusIcon =
        record.quantity <= 1 ? (
          <Icon
            type="MinusCircleOutlined"
            style={{ fontSize: 20, color: lightGrey }}
          />
        ) : (
          <Tooltip title={label("LBSC021")}>
            <Icon
              type="MinusCircleOutlined"
              style={{ fontSize: 20 }}
              className="polyu-color"
              onClick={() => addToCart({ ...record, quantity: -1 })}
            />
          </Tooltip>
        );

      const removeIcon = (
        <Popconfirm
          title={label("LBSC024").replace("{%1}", record.itemName)}
          okButtonProps={{ className: defaultBtnStyle + " cust-button-height" }}
          cancelButtonProps={{
            className: defaultBtnStyle + " cust-button-height",
          }}
          onConfirm={() => {
            this.handleDelete(record);
            if (
              portalClient.selectedEvent.shoppingCart !== undefined &&
              portalClient.selectedEvent.shoppingCart.length <= 1
            ) {
              // Change back to Edit Button if empty shopping cart
              this.setState({ isEditable: false });
            }
          }}
        >
          <Tooltip title={label("LBSC023").replace("{%1}", record.itemName)}>
            <Icon
              type="DeleteTwoTone"
              twoToneColor="#ff4d4f"
              style={{ fontSize: 20 }}
            />
          </Tooltip>
        </Popconfirm>
      );

      return (
        <div className="cust-vertical-top-rightAlign">
          {removeIcon}
          <div style={{ marginRight: 20 }}>&nbsp;</div>
          {enableEditQuantity(record) && minusIcon}
          <p style={{ width: "35px", textAlign: "center" }}>{text}</p>
          {enableEditQuantity(record) && (
            <Tooltip title={label("LBSC022")}>
              <Icon
                type="PlusCircleOutlined"
                style={{ fontSize: 20 }}
                className="polyu-color"
                onClick={() => addToCart({ ...record, quantity: 1 })}
              />
            </Tooltip>
          )}
        </div>
      );
    } else {
      return text;
    }
  };

  getAmount = (record) => {
    const text = formatAmount(record.amount);
    if (this.state.isEditable) {
      return !record.isAmountEditable || record.editAmount ? (
        <Text style={{ width: 80, textAlign: "right" }}>{text}</Text>
      ) : (
        <InputNumber
          min={0}
          placeholder="AMT"
          size="small"
          value={record.amount}
          controls={false}
          formatter={(value) => `${value}`}
          precision={2}
          disabled={!record.isAmountEditable}
          style={{ width: 80, minWidth: 70, fontSize: 13 }}
          onChange={(value) =>
            this.props.updateCartAmount({ ...record, amount: value })
          }
        />
      );
    } else {
      return text;
    }
  };

  getCell = (_span, _order, _value) => {
    return (
      <Col
        span={_span}
        xs={{ order: _order }}
        sm={{ order: _order }}
        md={{ order: _order }}
        lg={{ order: _order }}
        style={{ textAlign: "right", fontWeight: 500, fontSize: 15 }}
        className="cust-vertical-top-rightAlign"
      >
        {_value}
      </Col>
    );
  };

  getShoppingCartList = () => {
    const { portalClient, handleCheckOut, addInfoRemarks } = this.props;
    const { isEditable } = this.state;

    const isMandName =
      portalClient.selectedEvent !== undefined &&
      portalClient.selectedEvent.is_mand_payer_name !== undefined
        ? portalClient.selectedEvent.is_mand_payer_name
        : false;
    const isMandEmail =
      portalClient.selectedEvent !== undefined &&
      portalClient.selectedEvent.is_mand_email !== undefined
        ? portalClient.selectedEvent.is_mand_email
        : false;
    const totalAmountVal =
      portalClient.selectedEvent !== undefined &&
      portalClient.selectedEvent.totalAmount !== undefined
        ? `HK${formatAmount(portalClient.selectedEvent.totalAmount)}`
        : "HK$0.00";
    const remarkDefaultVal =
      portalClient.selectedEvent !== undefined &&
      portalClient.selectedEvent.remarks !== undefined
        ? portalClient.selectedEvent.remarks
        : "";
    const datasource =
      portalClient.selectedEvent !== undefined &&
      portalClient.selectedEvent.shoppingCart !== undefined
        ? portalClient.selectedEvent.shoppingCart.map((item) => {
            return item;
          })
        : null;

    if (
      datasource === null ||
      (datasource !== null && datasource.length === 0)
    ) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={label("LBSC001")}
        />
      );
    }

    return (
      <List
        //itemLayout="vertical" size="small"
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        dataSource={datasource}
        pagination={{
          onChange: (page) => {
            /**/
          },
          pageSize: defaultNumEachPage,
        }}
        footer={
          <div>
            {remarkDefaultVal && (
              <div style={{ marginLeft: "15px" }}>
                <div>
                  <Text strong>Remarks:</Text>
                </div>
                <div>
                  <Text>{remarkDefaultVal}</Text>
                </div>
              </div>
            )}
            <Row>
              <Col span={24} style={{ textAlign: "right" }}>
                <Text strong style={{ fontSize: "20px" }}>
                  {label("LBSC005")}
                </Text>
                &nbsp;
                <Text
                  strong
                  className="underline--double"
                  style={{ fontSize: 20 }}
                >
                  {totalAmountVal}
                </Text>
              </Col>
            </Row>
            {portalClient?.selectedEvent?.totalAmount <= 0 && (
              <Row>
                <Col span={24}>
                  <Alert
                    type="error"
                    showIcon
                    message={
                      "Total amount cannot be less than or equal to 0, please edit the price before proceeding to payment."
                    }
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24} style={{ textAlign: "right" }}>
                <EmailModal
                  isMandEmail={isMandEmail}
                  isMandName={isMandName}
                  title={label("LBSC006")}
                  btnEnabled={
                    !isEditable && portalClient.selectedEvent.totalAmount > 0
                  }
                  onInputValidate={handleCheckOut}
                />
              </Col>
            </Row>
          </div>
        }
        renderItem={(item) => (
          <List.Item
            key={`${item.itemCode}_${item.itemName}`}
            actions={
              [
                //this.getProductInputGroup(item)
              ]
            }
            // extra={}
          >
            <List.Item.Meta
              //title={<div className="border-card-blk__title"><strong>{item.itemCode}</strong></div>}
              avatar={
                <Avatar src="https://pbs.twimg.com/profile_images/459597748686180352/k84A15kV_400x400.jpeg" />
              }
              // description = {<div className="border-card-blk__title" >{item.itemName}</div>}
              title={
                <>
                  <Row type="flex" justify="space-between">
                    <div className={"col-24 col-sm-24 col-lg-8"}>
                      {/* LEFT */}
                      <div
                        className="border-card-blk__title"
                        style={{ marginLeft: "-10px", marginTop: "-10px" }}
                      >
                        <strong>{item.itemCode}</strong>
                      </div>
                      <div style={{ marginLeft: "-10px", marginTop: "10px" }}>
                        <strong>{item.itemName}</strong>
                      </div>
                    </div>
                    <div
                      className={"col-24 col-sm-24 col-lg-16"}
                      style={{ marginTop: "-10px" }}
                    >
                      {" "}
                      {/* RIGHT */}
                      <Row style={{ maxWidth: "550px" }}>
                        {this.getCell(8, 1, label("LBSC013"))}
                        {this.getCell(8, 2, label("LBSC014"))}
                        {this.getCell(8, 3, label("LBSC015"))}
                      </Row>
                      <Row style={{ maxWidth: "550px" }}>
                        {this.getCell(8, 1, this.getQuantity(item))}
                        {this.getCell(8, 2, this.getAmount(item))}
                        {this.getCell(8, 3, formatAmount(item.itemTotal))}
                      </Row>
                    </div>
                  </Row>
                  {item.enableRemark && (
                    <Row>
                      <TextArea
                        rows={4}
                        placeholder="Please input your Item Remarks here......"
                        value={item.itemRemarks}
                        disabled={!isEditable}
                        maxLength={200}
                        onChange={(event) => {
                          const newItem = {
                            ...item,
                            itemRemarks: event.target.value,
                          };
                          addInfoRemarks(newItem);
                        }}
                      />
                    </Row>
                  )}
                </>
              }
            />
            {/* {<br/>}
            {'description hard code here'}
            {<br/>} */}
            <Divider />
          </List.Item>
        )}
      />
    );
  };

  render() {
    const { portalClient } = this.props;
    const { isEditable } = this.state;

    const storeName =
      portalClient.selectedEvent !== undefined &&
      portalClient.selectedEvent.name !== undefined
        ? portalClient.selectedEvent.name
        : undefined;
    const shoppingCartBtnStyle =
      portalClient.selectedEvent !== undefined &&
      portalClient.selectedEvent.shoppingCart !== undefined &&
      portalClient.selectedEvent.shoppingCart !== null &&
      portalClient.selectedEvent.shoppingCart.length !== null &&
      portalClient.selectedEvent.shoppingCart.length > 0
        ? { float: "right" }
        : { float: "right", visibility: "hidden" };
    const isEmpty =
      portalClient.selectedEvent === undefined ||
      portalClient.selectedEvent.shoppingCart === undefined ||
      portalClient.selectedEvent.shoppingCart.length <= 0;

    return (
      <>
        <PurchaseSteps
          current={storeName ? 2 : 0}
          status={"process"}
          storeName={storeName}
        />
        <Row type="flex" justify="space-between" style={{ marginTop: 20 }}>
          <div className={"col-24 col-sm-24 col-lg-24"}>
            <div
              className="border-card-blk color-set--red cust-card-padding"
              style={{ textAlign: "left" }}
            >
              {isEmpty ? (
                <></>
              ) : isEditable ? (
                <Button
                  key="shopcartconfirm"
                  style={{ float: "right" }}
                  className={defaultBtnStyle + " cust-button-height"}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleEditShoppingCard();
                  }}
                >
                  {label("LBSC003")}
                  <Icon type="CheckOutlined" style={{ fontSize: 20 }} />
                </Button>
              ) : (
                <Button
                  key="shopcartedit"
                  style={shoppingCartBtnStyle}
                  className={defaultBtnStyle + " cust-button-height"}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleEditShoppingCard();
                  }}
                >
                  {label("LBSC004")}
                  <Icon type="EditOutlined" style={{ fontSize: 20 }} />
                </Button>
              )}
              <div className="border-card-blk__title js-dot cust-vertical-center cust-spacebetween">
                <div>{label("LBSC002")}</div>
              </div>
              <div className="border-card-blk__text">
                {" "}
                {/* ShoppingCart */}
                {/* {this.getShoppingCart()} */}
                {this.getShoppingCartList()}
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let entityName = state.dashboard.currentFunction.component_param;
  return {
    entityName: entityName,
    permissions: state.session.permissions,
    language: state.dashboard.currLang,
    csrf: state.action.csrf,
    portalClient: state.portalClient,
    configData: state.codetable,
    type: state.action.type,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => {
    dispatch({ type: "ADD_TO_CART", payload: item });
  },
  updateCartAmount: (item) => {
    dispatch({ type: "UPDATE_CART_AMOUNT", payload: item });
  },
  deleteFromCart: (deleteRecord) => {
    dispatch({ type: "DELETE_FROM_CART", payload: deleteRecord });
  },
  // addRemarks: (remarks) => {
  //   dispatch({ type: "ADD_REMARKS", payload: remarks });
  // },
  handleCheckOut: () => {
    dispatch(handleCheckOut());
  },
  addInfoRemarks: (item) => {
    dispatch({ type: "ADD_INFO_REMARKS", payload: item });
  },
  unselectEvent: () => {
    dispatch({ type: "UNSELECT_EVENT" });
  },
  navigate: (toFunction, language) => {
    dispatch(navigateAction(toFunction, language));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
