// UI Label (zh_HK)
const label = {
  LB0001: "載入中",
  LB0002: "搜尋",
  LB0003: "重設",
  LB0004: "編輯",
  LB0005: "刪除",
  LB0006: "取消",
  LB0007: "確認要刪除",
  LB0008: "確認",
  LB0009: "新增",
  LB0010: "登出",
  LB0011: "English",
  LB0012: "中文(繁體)",
  LB0013: "新增成功",
  LB0014: "編輯成功",
  LB0015: "刪除成功",
  LB0016: "輸入關鍵字",
  LB0017: "名稱",
  LB0018: "電郵地址",
  LB0019: "密碼",
  LB0020: "生日日期",
  LB0021: "身份",
  LB0022: "新增時間",
  LB0023: "更新時間",
  LB0024: "標籤ID",
  LB0025: "圖標名稱",
  LB0026: "構件名稱",
  LB0027: "構件參數",
  LB0028: "功能組",
  LB0029: "櫂限",
  LB0030: "行動",
  LB0031: "排序",
  LB0032: "數據",
  LB0033: "類型",
  LB0034: "類型參數",
  LB0035: "可排序",
  LB0036: "可搜尋",
  LB0037: "可編輯",
  LB0038: "必填項",
  LB0039: "電郵登入",
  LB0040: "NetID 登入",
  LB0041: "忘記密碼",
  LB0042: "注冊",
  LB0043: "ReactJS 參考實例",
  LB0044: "此網頁使用Ant Design React",
  LB0045: "或使用電郵登入",
  LB0046: "確認密碼",
  LB0047: "你希望別人怎麼稱呼你?",
  LB0048: "返回",
  LB0049: "我看過了",
  LB0050: "協議",
  LB0051: "用戶注冊",
  LB0052: "上傳",
  LB0053: "注冊成功",
  LB0054: "中文(简体)",
  LB0055: "解碼 ID",
  LB0056: "解碼 Type",
  LB0057: "解碼 Key",
  LB0058: "解碼 Code",
  LB0059: "解碼 Value",
  LB0060: "描述",
  LB0061: "順序",
  LB0062: "母解碼 ID",
  LB0063: "有效日期(由)",
  LB0064: "有效日期(至)",
  LB0065: "活躍",
  LB0066: "新增人員",
  LB0067: "更新人員",
  LB0068: "刷新",
  LB0069: "捐款 ID",
  LB0070: "捐款金額",
  LB0071: "捐款目的",
  LB0072: "項目/計劃/活動",
  LB0073: "捐款類型",
  // Function Label
  FN0000: "主頁",
  FN0001: "管理",
  FN0002: "用戶",
  FN0003: "身份",
  FN0004: "功能",
  FN0005: "系統數據",
  FN0006: "數據定義",
  FN0007: "數據",
  FN0008: "數據屬性",
  FN0009: "AADO 解碼",
  FN0010: "管理捐款",
  FN0011: "檢視捐款",
  FN0012: "AADO 捐款",
  FN0040: "Maintain Payment Gateway",
  FN0041: "Maintain Account Code",
  FN0042: "Maintain Service Provider",
  FN0043: "Maintain Payment Method",
  FN0044: "Maintain Gateway maintenance",
  FN0045: "Maintain Unit",
  FN0046: "Maintain Event",
  FN0047: "Maintain Event Payment Method",
  FN0048: "Maintain Item",

  // Error (Client Side)
  EC9999: "內部系統錯誤。 請聯繫管理員",
  EC9998: "網絡連接錯誤。 請聯繫管理員",
  EC0001: "請輸入電郵地址",
  EC0002: "電郵地址格式不正確",
  EC0003: "請輸入密碼",
  EC0004: "{%1}格式不正確",
  EC0005: "請輸入{%1}",
  EC0006: "請輸入名稱",
  EC0007: "請輸入確認密碼",
  EC0008: "請輸入生日日期",
  EC0009: "您輸入的兩個密碼不一致",
  EC0010: "請接受協議",
  EC0011: "您沒有權限登入,請聯繫管理員",
  // Error (Server Side)
  E00001: "請輸入電郵地址和密碼",
  E00002: "電郵地址和密碼不一樣",
  E00003: "系統錯誤（功能不存在)",
  E00004: "新增記錄錯誤",
  E00005: "刪除記錄錯誤",
  E00006: "更新記錄錯誤",
  E00007: "讀取記錄錯誤",
  E00008: "記錄不存在",
  E00009: "記錄已被更改",
  E00010: "您沒有權限登入,請聯繫管理員",
  E99997: "登錄無效(csrf錯誤)，請重新登錄",
  E99998: "登錄無效或超時，請重新登錄",
  E99999: "內部系統錯誤。 請聯繫管理員",
};

export default label;
