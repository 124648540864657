/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  state = {
    name: "",
    email: "",
    netId: "",
    permissions: [],
    lang: "enUS",
    otpInfo: { otpLogin: false },
  },
  action
) => {
  let newState = state;
  switch (action.type) {
    case "LOGIN-SUCCESS": {
      const { name, email, netId } = action.payload.value;
      newState = Object.assign({}, newState, {
        name,
        email,
        netId,
        permissions: [],
        lang: "enUS",
      });
      break;
    }
    case "AUTHENTICATE-SUCCESS": {
      const { name, email, netId, permissions } = action.payload.value;
      permissions.forEach((element) => {
        if (!element.parent_uid) {
          element.backfuncName = "";
          element.subfunc = permissions
            .map((func) => (func.parent_uid === element.key ? func : undefined))
            .filter((item) => item !== undefined);
        } else {
          element.backfuncName = permissions.filter(
            (item) => item.key === element.parent_uid
          )[0].name;
          element.subfunc = [];
        }
      });
      newState = Object.assign({}, newState, {
        name,
        email,
        netId,
        permissions,
      });
      break;
    }
    case "LOGIN-ERROR":
    case "AUTHENTICATE-ERROR":
      newState = {
        name: "",
        email: "",
        netId: "",
        permissions: [],
        lang: "enUS",
      };
      break;
    case "LOGOUT-SUCCESS":
      newState = {
        name: "",
        email: "",
        netId: "",
        permissions: [],
        lang: "enUS",
      };
      break;
    case "SWITCH-LANG":
      newState = Object.assign({}, newState, { lang: action.payload });
      break;
    case "OTP_LOGIN":
      newState = Object.assign({}, newState, {
        otpInfo: { ...action.payload.otpInfo, otpLogin: true },
      });
      break;
    case "OTP_LOGOUT":
      newState = Object.assign({}, newState, { otpInfo: { otpLogin: false } });
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};
