function formatAmount(amount, dollarSign = true) {
  if (!amount) {
    amount = "0";
  }
  const newAmount = (amount + ".00")
    // Keep only digits and decimal points:
    .replace(/[^\d.]/g, "")
    // Remove duplicated decimal point, if one exists:
    .replace(/^(\d*\.)(.*)\.(.*)$/, "$1$2$3")
    // Keep only two digits past the decimal point:
    .replace(/\.(\d{2})\d+/, ".$1");

  return `${amount >= 0 ? "" : "-"}${
    dollarSign ? "$" : ""
  }${AddThousandSeparator(newAmount)}`;
}

function AddThousandSeparator(str, thousandSeparator, decimalSeparator) {
  var sRegExp = new RegExp("(-?[0-9]+)([0-9]{3})"),
    sValue = str + "", // to be sure we are dealing with a string
    arrNum = [];

  if (thousandSeparator === undefined) {
    thousandSeparator = ",";
  }

  if (decimalSeparator === undefined) {
    decimalSeparator = ".";
  }

  arrNum = sValue.split(decimalSeparator);
  // let’s be focused first only on the integer part
  sValue = arrNum[0];

  while (sRegExp.test(sValue)) {
    sValue = sValue.replace(sRegExp, "$1" + thousandSeparator + "$2");
  }

  // time to add back the decimal part
  if (arrNum.length > 1) {
    sValue = sValue + decimalSeparator + arrNum[1];
  }

  return sValue;
}

export default formatAmount;
